<!-- The core Firebase JS SDK is always required and must be listed first -->
<!-- <script src="https://www.gstatic.com/firebasejs/6.0.2/firebase-app.js"></script>   -->
<script src="https://www.gstatic.com/firebasejs/7.2.1/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#config-web-app -->

<!--
<script>
	// Your web app's Firebase configuration
	var firebaseConfig = {
		apiKey: "AIzaSyDMpFujbx7FQptNGYBmFicln6L_ZwBGC2g",
		authDomain: "titanic-182119.firebaseapp.com",
		databaseURL: "https://titanic-182119.firebaseio.com",
		projectId: "titanic-182119",
		storageBucket: "titanic-182119.appspot.com",
		messagingSenderId: "754730795687",
		appId: "1:754730795687:web:7b693ee34d9ed331"
	};
	// Initialize Firebase
	firebase.initializeApp(firebaseConfig);
</script>
-->
<script>
const firebaseConfig = {
  apiKey: "AIzaSyDKVPxEHS79yhkuDdAKlDt8OFaM0Wkou4g",
  authDomain: "nebuladev-edb6b.firebaseapp.com",
  projectId: "nebuladev-edb6b",
  storageBucket: "nebuladev-edb6b.appspot.com",
  messagingSenderId: "835009819471",
  appId: "1:835009819471:web:6743e6c172d1ac8e84cb3a",
  measurementId: "G-EZVMMFBEX2"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
</script>

<div class=content_css id="content">

	<router-outlet></router-outlet>

	</div>

<app-footer></app-footer>
