import { Component, OnInit  }							from '@angular/core';
import { Observable }									from 'rxjs';
import { DataService }									from '../../_services';
import { Location, Meter, Customer }				from '../../_models';
import { AuthService }									from '../../_services';
import { ActivatedRoute	}								from '@angular/router';

@Component({
  selector: 	'x-meter-form',
  templateUrl: './meter.component.html'
})

export class MeterComponent implements OnInit {

	_location:			Location;
	_meter:				Meter;
	_customer:			Customer;

	constructor(
		private dataSrv: DataService,
		private route: ActivatedRoute,
		public authService: AuthService,
		)
		{

		this.route.params.subscribe(
			params =>
				{
				if ( typeof params.m_gid  != 'undefined' )
					{
		   		this.dataSrv.getCustomerByMeterGid( params.m_gid ).subscribe(
						(data) => {
						this._location = data['locn'];
						this._customer = data['customer'];
						this._meter = data['meter'];
//						console.log( this._meter.read_date )
		   			});
		   		}
		   	});
		}

	ngOnInit(){}


}
