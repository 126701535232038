import { Component, OnInit } 							from '@angular/core';
import { DataService }									from '..//../_services';
import { Observable }									from 'rxjs/Rx';
import { AuthService } 									from '../../_services';
import { Router }											from "@angular/router";

@Component({
	selector: 'app-monitor-panel',
	templateUrl: './monitor-panel.component.html',
	styleUrls: ['./monitor-panel.component.scss']
})

export class MonitorPanelComponent implements OnInit {

	public Issues;

	constructor(
		public authService:	AuthService,
		private dataSrv:		DataService,
		private router: 		Router
		) {
		if(!this.authService.isRoleOK("mp" )) {
			window.alert("You are not allowed to access this URL!");
			this.router.navigate(['home'])
			}

		}

	settings = {
		pager:	{
			display: true,
			perPage: 25,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			DTG:				{title: 'DTG', 			width: '180px'},
			st_num:        {title: 'St.Nbr',			width:  '90px'},
			st_name:       {title: 'Street Name',	width: '500px'},
			city:				{title: 'City',	 		width: '150px' },
			meter_id:      {title: 'Meter', 			width: '100px'},
			Why:         	{title: 'Type', 			type: 'html',
				valuePrepareFunction:
				function(value) {
					if ( value == 'ERR' ) {return '<div class="back_red_text_white">ERR</div>';}
					if ( value == 'MSG' ) {return '<div class="back_tan">MSG</div>';}
					if ( value == 'CMD' ) {return '<div class="back_powderblue">CMD</div>';}
					if ( value == 'CLR' ) {return '<div class="back_green_text_white">CLR</div>';}
					return '<div class="centerAlign"> ' + value  + '</div>';
				}},
			message:   		{title: 'message', 		width: '210px'},
			},
		attr: {
      	class: 'table table-bordered tr'
      	},
		};


	onUserRowSelect(event: any) {
		console.log('Select: '  + event.data.gid + ' isSelected: ' + event.isSelected );
		console.log( event );
		console.log( event.data );
//		this.specifiedLocn  = this.location[ event.data.irt ];
//		this.specifiedMeter = this.meters[ event.data.irt ];
//		this.ManyLoc = false;
//		this.searchMeter = false;
		this.router.navigate(['/meter/', event.data.meter_gid]);
		}

	ngOnInit()
		{
//		let timer = Observable.timer(20,5000);
//		timer.subscribe(this.MyTimer);
		this.dataSrv.getIssueDataCurrent (true, true, true ).subscribe(
					( dbIssues ) => {
					console.log( dbIssues );
					this.Issues = dbIssues
					});
		}

	MyTimer() {
		console.log( 'Tick' );
//		this.dataSrv.getIssueDataCurrent (true, true, true ).subscribe(
//					( dbIssues ) => {
//					console.log( dbIssues );
//					this.Issues = dbIssues
//					});
		}

}