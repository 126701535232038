<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<a  [routerLink]="['/pick-utility']" >
		<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />
		</a>
			
	<a [routerLink]="['/home']" 	 >
		<i class="material-icons">home</i>
		Home
		</a>

	<a [routerLink]="['/admin']" style="position:absolute;left:45%" *ngIf="authService.isRoleOK( 'admin' )">
		<i class="material-icons">perm_data_settings</i>
		Admin
		</a>

	<a (click)="authService.SignOut()" style="position:absolute;left:95%">
		<i class="material-icons">logout</i>
		</a>

	</nav>
<div class="container">
	<h1>Monitor Panel</h1>
	<div class=list_info >
		<ng2-smart-table
			[settings]		=	"settings"
			[source]			=	"Issues"
			(userRowSelect)=	"onUserRowSelect($event)"
			>
			</ng2-smart-table>
	</div>
</div>