
export class User
	{
	gid:				number;
	utilityGid:		number;
//	userName:		string;
	password:		string;
	firstName:		string;
	lastName:		string;
	role:				string;
	dateIn:			string;
	dateOut:			string;
	emailAddr:		string;
   uid: 				string;
   email:			string;
   displayName:	string;
   photoURL: 		string;
   emailVerified: boolean;
	}


