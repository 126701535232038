import { Component, OnInit } 								from '@angular/core';
import { DataService, AuthService }						from '../../_services';
import { Router, NavigationEnd }							from '@angular/router';
import { UiModule } 											from '../ui/ui.module';

@Component({
	selector:		'app-reports',
	templateUrl:	'./reports.component.html',
	styleUrls:		['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

	public reportListTable: any;

	selectedRow =
		{
		gid:				"",
		menu_key:		"",
		prog_name:		"",
		report_name:	"",
		comment:			""
		}      ;

	reportData:		any;
//	currentUGid = -1;

	flgListBox = false;
	flgReport = false;
	flgQuestion = false;  
	flgBillList = false;
	flgNewBill = false;
	flagMU = false;
	flagBR = false;
	billSubmitted = false;
	headerReport = '';
	EN_DT = '';
	ST_DT = '';
	rpt_key = 0;
	billCtrlRec: any; 
//		DTG: 					string, // "2021-01-01 00:00:00",
//		as_of_date: 		string, // "2021-01-01",
//		billing_infocol: 	string, // "1",
//		c_user: 				string, // "Stan Banes",
//		number_gallons: 	string, // "1231231",
//		rpt_key: 			number, // "1",
//		s_user: 				string, // "Stan Banes"
//		}

	allMonths = [
		{mon_id: 1,		name: 'Jan', tm: '01'},
		{mon_id: 2,		name: 'Feb', tm: '02'},
		{mon_id: 3,		name: 'Mar', tm: '03'},
		{mon_id: 4,		name: 'Apr', tm: '04'},
		{mon_id: 5,		name: 'May', tm: '05'},
		{mon_id: 6,		name: 'Jun', tm: '06'},
		{mon_id: 7,		name: 'Jul', tm: '07'},
		{mon_id: 8,		name: 'Aug', tm: '08'},
		{mon_id: 9,		name: 'Sep', tm: '09'},
		{mon_id: 10,	name: 'Oct', tm: '10'},
		{mon_id: 11,	name: 'Nov', tm: '11'},
		{mon_id: 12,	name: 'Dec', tm: '12'},
		];
	allYears = [
		{year: '2019'},
		{year: '2020'},
		{year: '2021'},
		{year: '2022'},
		{year: '2023'},
		{year: '2024'},
		{year: '2025'},
		{year: '2026'},
		{year: '2027'},
		{year: '2028'},
		{year: '2029'},
		{year: '2030'},
		];
	model: any = {};

	settingReports = {
		hideSubHeader: true,
		pager:	{
			perPage:	12,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			report_name:	{title:	'Name', width:		'200px'},
			comment:			{title:	'Comment', width:	'300px'},

// 			parm_1_name:	{title:	"Parm 1", width:	'100px'},
// 			parm_2_name:	{title:	"Parm 2", width:	'100px'},
// 			parm_3_name:	{title:	"Parm 3", width:	'100px'},
			}
		};

	reportConfig: any;
	configReport1 = {
		hideSubHeader: true,
		pager:	{
			perPage:	18,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			cust_acct:		{title:	'Cust Acct' }, // , width:		'200px'},
			cname:			{title:	'Customer'}, // width:	'300px'},
			locn_acct:		{title:	'Location'},
			meter_id:		{title:	'Meter ID'},
			useage:			{title:	'Useage', type: 'html', valuePrepareFunction: function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }},
			}
		};

	configReport2 = {
		hideSubHeader: true,
		pager:	{
			perPage:	18,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			DTG:				{title:	'DTG'},
			value:			{title:	'Issue'},
			meter_id:		{title:	'Meter ID'},
			st_num:			{title:	'StNum'},
			st_name:			{title:	'Street'},
			city:				{title:	'City'},
			}
		};

	configBilling = {
		hideSubHeader: false,
		pager:	{
			perPage:	18,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			rpt_key:				{title:	'Rpt Key'},
			DTG:					{title:	'Rpt Date'},
			as_of_date:			{title:	'As Of Date'},
			number_gallons: 	
				{                                                                                                                                           
				title:	'Volume',   
				type:		'html',                                                                                                                                 
//				valuePrepareFunction: (x) => 
//					{                    
//					var db10 = x/10;
//					return '<div class="text_right"> ' + 
//						db10.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
//						+ ' </div>';
//					}
				},                                                                                                                                                			
			billing_infocol:	{
				title:	'Status',
				type:		'html',                                                                                                                                 
				valuePrepareFunction: (x) => 
					{return '<div class="text_center"> ' + x + ' </div>';}
				},
			c_user:				{title:	'Created By'},
			s_user:				{title:	'Finalized By'},
			}
		};

	constructor(
		private  dataSrv: DataService,
		private	authService: AuthService
		) {}

	ngOnInit() {

		this.flgListBox	= false;
		this.flgReport	= false;
		this.flgQuestion	= false;
		this.flagMU = false;
		this.flagBR = false;
		this.flgBillList = false; 
//?		this.
		const D = new Date();
		this.model.mon_id =  D.getMonth() + 1;
		this.model.year =  D.getFullYear() ;

//		this.dataSrv.currentUGid.subscribe(
//			currentUGid => {
//				this.currentUGid = currentUGid;
//				});

		this.dataSrv.getReports( ).subscribe(
			( reports ) => {
			this.reportData = reports;
			this.flgListBox = true;
			console.log( reports );
			});
		}

onBillingRowSelect($event) {
	console.log( $event );
	this.selectedRow = $event.data;
	this.billCtrlRec = $event.data;
	this.headerReport = "Review bill";
	this.flgBillList = false;      
	this.flgNewBill = true;
	}

onReportRowSelect($event) 
	{
	this.billSubmitted = false;
	console.log( $event );
	this.flgListBox = false;
	this.selectedRow = $event.data;
	this.doReports( $event );
	}

onSubmitBill() 
	{
	console.log( this.billCtrlRec );
	this.dataSrv.updateBillCtrlRec( this.rpt_key, this.billCtrlRec.as_of_date, this.billCtrlRec.billing_infocol ).subscribe(
		( data ) => {
		console.log('Sucessful Update');
//		console.log( data );
		this.billSubmitted = true;
		this.dataSrv.getBillingReportList( ).subscribe(
			( reports ) => {
			this.reportData = reports;
			this.flgBillList = true;
			console.log( reports );
			});				
		});
	}

restart() {
	this.flgListBox = true;
	this.flgBillList = false;
	this.flgQuestion = false;
	}

newBillRecord()
	{       
	const d = new Date();
	this.billCtrlRec = new BillCtrlRec (d.toLocaleString(), "2021-01-01", "1", 0, 0, '', "" )  ;
	console.log("Create new bill")    ;
	console.log( this.billCtrlRec );
	this.headerReport = "Create new bill";
	this.flgBillList = false;      
	this.flgNewBill = true;
	}

getStartEnd()
	{
	this.ST_DT = this.model.year + '-' + this.model.mon_id  + '-01';
	var yd = new Date(this.model.year, this.model.mon_id  , 1);	      // Take advantage of mon_id being 1 based
	yd.setDate(yd.getDate() - 1);		
	this.EN_DT = this.model.year + '-' + this.model.mon_id  + '-' + yd.getDate();
	}

doReports( $event )
	{
	console.log( this.model );
	this.flgQuestion = false;
	
console.log( this.selectedRow );

	switch ( this.selectedRow.menu_key )
		{
		case ( 'BILLING' ):
			{
			this.headerReport = 'Billing Files for ' + this.authService.utilityObject.utility_name ;
			this.reportConfig = this.configBilling;
			this.dataSrv.getBillingReportList( ).subscribe(
				( reports ) => {
				this.reportData = reports;
				this.flgBillList = true;
				console.log( reports );
				});				
			}
			break;
		case ( 'ADMIN' ):
			{
			this.flgQuestion = true;
			this.getStartEnd();
			switch ( this.selectedRow.prog_name )
			{
			case  'monthly_u' :
				{
				this.headerReport = 'Customer Useage Report for ' + this.ST_DT + ' to ' + this.EN_DT;
				this.reportConfig = this.configReport1;
				this.flgQuestion = false;
				this.flgReport = false;
				this.flagMU = true;
				this.flagBR = false;
				break;
				}
			case  'rpt_by_date' :
				{
				this.dataSrv.rpt_by_date( this.selectedRow.prog_name, 1, this.ST_DT, this.EN_DT, this.selectedRow.report_name, this.selectedRow.comment ).subscribe(
					( rptData ) => {
					this.headerReport = 'Customer Useage Report for ' + this.ST_DT + ' to ' + this.EN_DT;
					this.reportConfig = this.configReport1;
					this.reportData = rptData;
					this.flgQuestion = false;
					this.flgReport = true;
					this.flagMU = false;
					this.flagBR = false;
					});
					break;
				}
			case  'rpt_issues':
				{
				this.dataSrv.getIssueData( this.ST_DT, this.EN_DT ).subscribe(
					( rptData ) => {
					this.headerReport = 'Meter Issue Report for ' + this.ST_DT + ' to ' + this.EN_DT;
					this.reportConfig = this.configReport2;
					this.reportData = rptData;
					this.flgQuestion = false;
					this.flgReport = true;
					this.flagMU = false;
					this.flagBR = false;
					});
					break;
				}
			}
			break;
			}
		case  'rpt_by_date' :
			{
			const subject = this.selectedRow.report_name + " from " + this.ST_DT + " to " + this.EN_DT  ;
			this.dataSrv.rpt_by_date( this.selectedRow.prog_name, 1, this.ST_DT, this.EN_DT, subject, this.selectedRow.comment ).subscribe(
				( rptData ) => {
				this.headerReport = 'Customer Useage Report for ' + this.ST_DT + ' to ' + this.EN_DT;
				this.reportConfig = this.configReport1;
				this.reportData = rptData;
				this.flgQuestion = false;
				this.flgReport = true;
				this.flagMU = false;
				this.flagBR = false;
				});
				break;
			}
		case ( 'JSREPORT' ):
			{
			this.dataSrv.useJsReportByGid( this.selectedRow.prog_name, 1, this.selectedRow.report_name, this.selectedRow.comment  ).subscribe(
				( rptData ) =>
					{
					console.log("Back from rpt_Nebula");
					this.headerReport = 'Nebula Report';
					this.reportConfig = this.configReport2;
					this.reportData = rptData;
					this.flgQuestion = false;
					this.flgReport = true;
					this.flagMU = false;
					this.flagBR = false;
					});
			break;
			}
		}

//	this.flgListBox = true;
	}

	returnToMenu()
		{
		console.log( "returnToMenu" );
		}


   }      
   
export class BillCtrlRec {              
	constructor                            
		(                                   
		public DTG: 				string,
		public as_of_date: 		string,
		public billing_infocol:	string,
		public number_gallons: 	number,
		public rpt_key: 			number,
		public c_user: 			string,
		public s_user: 			string
		) {}                                
	}                                      
