<div class=mc_box *ngIf="_meter" >
	<ng-template  *ngIf="flg_valve_change_status;then showVC; else showValve"></ng-template>
		<div class=mc_title_ln>Meter Control</div>
		<ng-template #showVC>
			<img  *ngIf="valve_change_status_pink"		class=vo_Image src="https://stoshb.info/assets/img/ServiceManPink.jpg"  >
			<img  *ngIf="valve_change_status_red"		class=vo_Image src="https://stoshb.info/assets/img/ServiceManRed.jpg"  >
			<img  *ngIf="valve_change_status_lime"		class=vo_Image src="https://stoshb.info/assets/img/ServiceManLime.jpg"  >
			<img  *ngIf="valve_change_status_green"	class=vo_Image src="https://stoshb.info/assets/img/ServiceManGreen.jpg"  >
			</ng-template>

		<ng-template #showValve>
			<img  *ngIf="ValveOn"  					class=vo_Image 	src="https://stoshb.info/assets/img/LightSwitchOn.png"   (click)="valveClicked(0)">
			<img  *ngIf="ValveOff" 					class=vo_Image		src="https://stoshb.info/assets/img/LightSwitchOff.png"  (click)="valveClicked(1)" >
			</ng-template>

	<button class=btnLLY	*ngIf="this._meter.valve_lifeline == 1" type="submit" (click)="setLifeLine('Off')">Life Line On</button>
	<button class=btnLLN	*ngIf="this._meter.valve_lifeline == 0" type="submit" (click)="setLifeLine('On')" >Life Line Off</button>


	<div class=btnPeriods	>
		<button class=btnFastT	*ngIf="fastFlag" type="submit" (click)="buttonSpeed('F')">Fast</button>
		<button class=btnFastF	*ngIf="!fastFlag" type="submit" (click)="buttonSpeed('F')">Fast</button>

		<button *ngIf="!flg24"	class=btn24Hw type="submit" (click)="start24()">24 Hrs</button>
		<div *ngIf="flg24" class=bbx24>
			<button class=btn24Hg	*ngIf="flg24" type="submit" (click)="buttonSpeed('N')">{{ _meter.cnt_24 }}</button>
		 </div>

		<button class=btnNormT	*ngIf="normFlag" type="submit" (click)="buttonSpeed('N')">Normal</button>
		<button class=btnNormF	*ngIf="!normFlag" type="submit" (click)="buttonSpeed('N')">Normal</button>
		<button class=btnTest	type="submit" (click)="testMeter()">Exercise</button>
		<img	class=brm_RST     SRC="https://stoshb.info/assets/img/ResetButton.png " title="Reset Meter"  (click)="resetMeter(0)" >
		<img	class=btn_RptB		SRC="https://stoshb.info/assets/img/icons8-business-report-48.png " title="Print Useage Report"  (click)="printReport(1)" >
		</div>

	<div class=bx_info>
		<div class=vo_err_box  *ngIf="valveInError">
			<div class="vo_Text">Valve</div>
			</div>

		<div class=vo_test_cnt  >{{ _meter.valve_test_count }}&nbsp;reports till meter test</div>
		<div class=vo_rpt_time  >Report&nbsp;every&nbsp;{{ _meter.RptFreq / 60 }}&nbsp;Min.</div>
		<div class=vo_parm_flag	>Parameters flag:&nbsp;{{ _meter.parm_flag }}</div>
		<div class=vo_next_rpt  >Next report at:&nbsp;{{ nextReport }}</div>

		<button class=vo_status	*ngIf="_meter.ErrStatus > 0" type="submit" (click)="abnormalMeter()">Abnormal Status:&nbsp;{{ _meter.ErrStatus}}</button>
		</div>
		
	</div>