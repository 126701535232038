import { Injectable, ViewChild }		from '@angular/core';
import { DataService  }		from '../_services';

// ================================================================================
import { MapsAPILoader, AgmMap } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
declare var google: any;

interface Marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

interface Location {
	lat: number;
	lng: number;
	viewport?: Object;
	zoom: number;
	marker?: Marker;
}
// ================================================================================

@Injectable({
	providedIn: 'root'
})

export class MapService {

	imgRedDot		= 'https://www.google.com/intl/en_us/mapfiles/ms/micons/red-dot.png';
	imgGreenDot		= 'https://www.google.com/intl/en_us/mapfiles/ms/micons/green-dot.png';
	imgBlueDot		= 'https://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png';

	imgLocation		= 'assets/img/iLocn.png';
	imgWhiteL		= 'assets/img/WhiteL.png';
	imgMauveL		= 'assets/img/iMauveL.png';
	imgRedL			= 'assets/img/iRedL.png';
	imgWhiteM		= 'assets/img/WhiteM.png';
	imgGreenM		= 'assets/img/GreenM.png';
	imgRedM			= 'assets/img/RedM.png';
	imgYellowMDot	= 'assets/img/YellowMDot.png';

	iGreenMeter		= 'assets/img/iGreenMeter.png';
	iRedMeter		= 'assets/img/iRedMeter.png';
	iYellowMeter	= 'assets/img/iYellowMeter.png';
	iWhiteMeter		= 'assets/img/iWhiteMeter.png';

	markerArray: any;

	jtStartIndex = 0;
	jtPageSize = 50;
	markerCount = 0;
	TS:	'';
	strBounds:	'';
	Togs: any;

	@ViewChild(AgmMap) map: AgmMap;

	constructor(private dataSrv: DataService) { }

		public clearMarkers( markerArray ) {
			markerArray.length = 0;
			this.markerCount = 0;
			}

		public plotPoints( ma, boundString, toggles  ) {
//			console.log( toggles );
//			console.log( toggles.A + toggles.R + toggles.I + toggles.K + toggles.P  );
			this.strBounds = boundString ;
			this.TS = '';
			(toggles.A) && (this.TS += 'A');
			(toggles.R) && (this.TS += 'R');
			(toggles.I) && (this.TS += 'I');
			(toggles.K) && (this.TS += 'K');
			(toggles.P) && (this.TS += 'P');
			(toggles.L) && (this.TS += 'L');
			this.Togs = toggles;

			ma.length = 0;
			this.markerArray	= ma;
			this.jtStartIndex	= 0;

			this.dataSrv.getOrderedMapPoints( this.jtStartIndex, this.TS  ,this.strBounds,  this.jtPageSize )
					.subscribe(( dataArray ) => this.getDataPoints( dataArray ));

//			for (let i = 0; i < 10; i++) {
//				this.dataSrv.getOrderedMapPoints( this.jtStartIndex, this.TS  , boundString,  this.jtPageSize ).subscribe(
//					( dataArray ) => {
// 						console.log( this.jtStartIndex + "   " + dataArray.length );
//						for (let irt = 0; irt < dataArray.length;  irt++)
//							{
//							const D = dataArray[irt];
//							this.ShowMarkers( D, toggles ) ;
//							this.jtStartIndex = D.gid ;
//							}
//						});
//				this.jtStartIndex += this.jtPageSize ;
//				}
			}

		public getDataPoints( dataArray )
			{
// 			console.log( this.jtStartIndex + "   " + dataArray.length );
			for (let irt = 0; irt < dataArray.length;  irt++)
				{
				const D = dataArray[irt];
				this.ShowMarkers( D, this.Togs ) ;
				this.jtStartIndex = D.gid ;
				}
			if ( dataArray.length > 0 )
				{
				this.dataSrv.getOrderedMapPoints( this.jtStartIndex, this.TS  ,this.strBounds,  this.jtPageSize )
					.subscribe(( dataArray ) => this.getDataPoints( dataArray ));
				}
			}

		public ShowMarkers( D, toggles ) {
// 			console.log( D )
         if ( (D.lat == 0 ) || ( D.lon == 0 ) ) { return; }
			let icon = this.imgBlueDot;
			if 		( D.meter_id == 'No Meter' )
				{
				if ( toggles.I )		{ icon = this.imgMauveL ; }	else						{ return; }
				}
			else if	( D.meter_id == 'On Order' )
				{
				if ( toggles.R )		{ icon = this.iWhiteMeter ; } else						{ return; }
				}
			else
				{
				if ( (D.ErrStatus == 0 ) && (D.read_delay < 86400))	// Is meter in good shape?
					{																	// Yes					
					if ( D.valve_lifeline > 0 )  
						{										// Valve is in Life Line and good												
						if 	  ( toggles.L )			{icon = this.iYellowMeter; }
						else if ( toggles.K )			{icon = this.iGreenMeter;  }		
						else if ( toggles.A )			{icon = this.imgLocation;	}								
						}
					else	
						{										// Valve not in Life Line and good												
						if 	  ( toggles.K )			{icon = this.iGreenMeter;  }		
						else if ( toggles.A )			{icon = this.imgLocation;	}								
						}
					}
				else
					{
					if ( D.valve_lifeline > 0 )  
						{										// Valve is in Life Line and good												
						if 	  ( toggles.L )			{icon = this.iYellowMeter; }
						else if ( toggles.P )			{icon = this.iGreenMeter;  }		
						else if ( toggles.A )			{icon = this.imgLocation;	}								
						}
					else
						{
						icon = this.iRedMeter ;	
						}
					}
					
	//			else																	// No - Meter has a problem
	//				{
	//				if ( toggles.P )
	//					{
	//					icon = this.iRedMeter ;
	//					}
	//				else
	//					{
	//					if ( toggles.A )		{ icon = this.imgLocation ; } else						{ return; }
	//					}
	//				}
				}

//			console.log(D.meter_id + "  " + D.ErrStatus + "  " + D.valve_lifeline + "  " +  toggles.A + "  " + toggles.I + "  " + toggles.R
//				+ "  " +   toggles.K + "  " + toggles.P + "  " + toggles.L + "  " + icon)

			const Marker =  new google.maps.Marker({
				lat : D.lat / 1.0,
				lng : D.lon / 1.0,
				gid:	D.gid,
				title:  'Location gid: ' + D.gid,
				icon: icon,
				});

// 			console.log( Marker );
			this.markerArray.push( Marker );

	  }
	}
