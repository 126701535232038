import { Component, OnInit } from '@angular/core';

@Component({
	template: `
	<app-header></app-header>`
})

export class PageNotFoundPart implements OnInit {

	constructor() {
		console.log('Page Not Found Constructor');
		}

	ngOnInit() {
		console.log('Init Page Not Found');
		}

}
