import { Component, OnInit, Input  }					from '@angular/core';
import { Location }											from '../_models';

@Component({
	selector:		'part-locn',
	template:
	`<style>
		div.locn_info		{width: 489px; height:140px; border:1px solid  black;}
		div.l_title_ln		{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}
		div.lh_ID			{position:absolute; top:   30px; left:  10px;text-align: left}
		div.l_id				{position:absolute; top:   30px; left: 120px;text-align: left}
		div.lh_bt			{position:absolute; top:   30px; left: 280px;text-align: left}
		div.l_bt				{position:absolute; top:   30px; left: 400px;text-align: left}
		div.l_adrh 	      {position:absolute; top:   55px; left:  10px;text-align: left}
		div.l_adr 	      {position:absolute; top:   55px; left: 110px;text-align: left}
		div.l_bldh 	      {position:absolute; top:   80px; left:  10px;text-align: left}
		div.l_bld 	      {position:absolute; top:   80px; left: 110px;text-align: left}
		div.l_unih 	      {position:absolute; top:   80px; left: 200px;text-align: left}
		div.l_uni 	      {position:absolute; top:   80px; left: 250px;text-align: left}
		div.l_cszh			{position:absolute; top:  105px; left:  10px;text-align: left}
		div.l_city			{position:absolute; top:  105px; left: 110px;text-align: left; }
		div.l_st				{position:absolute; top:  105px; left: 345px;text-align: left; }
		div.l_zip	      {position:absolute; top:  105px; left: 380px;text-align: left; }
		</style>
	<div class=locn_info *ngIf="_location" >
		<div class=l_title_ln >Service Location</div>

		<div class=lh_ID >Acct&nbsp;ID</div>
		<div class=l_id  >{{ _location.acct_nbr }}</div>
		<div class=lh_bt >Billing Code</div>
		<div class=l_bt  >{{ _location.billing_code }}</div>

		<div class=l_adrh 	>Street&nbsp;Adr:</div>
		<div class=l_adr 	   >{{ _location.st_num }}&nbsp;{{ _location.st_name }} 		</div>
		<div class=l_bldh    >Bld:</div>
		<div class=l_bld 	   >{{ _location.building 	}} </div>
		<div class=l_unih    >Unit: </div>
		<div class=l_uni 	   >{{ _location.unit 	}} </div>
		<div class=l_cszh    >City/St/Zip</div>
		<div class=l_city 	>{{ _location.city }}		</div>
		<div class=l_st		>{{ _location.state }}		</div>
		<div class=l_zip	 	>{{ _location.zip }}		</div>

		</div>`

	})

export class LocnPart implements OnInit
	{
	_location:	Location;

	@Input()
		set location(location:Location)
			{
			this._location = location;
			}

	constructor()
		{}

	ngOnInit()
		{}

	}

