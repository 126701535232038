
<div class="displayTable">
	<div class="displayTableCell">

		<div class="px-logo">
<!--			<img src="https://titanic-182119.firebaseapp.com/assets/176_Nebula_logo_V2.1.png"> -->
			<img src="https://stoshb.info/assets/176_Nebula_logo_V2.1.png">
			</div>

		<div class="authBlock">
			<h3>Sign In Stan</h3>
			<div class="formGroup">
				<input type="text" class="formControl" placeholder="Username" #userName required>
				</div>

			<div class="formGroup">
				<input type="password" class="formControl" placeholder="Password" #userPassword required>
				</div>

			<div class="formGroup">
				<input type="button" class="btn btnPrimary" value="Log in" (click)="authService.SignIn(userName.value, userPassword.value)">
				</div>

			<div class="forgotPassword">
				<span routerLink="/forgot-password">Forgot Password?</span>
				</div>

			<div class="redirectToLogin">
					<span routerLink="/sign-up"> Sign Up
						</span>
				</div>

    		</div>

  		</div>

	</div>
