
import { AgmCoreModule }				from '@agm/core';
import { AlertService	}				from './_services';
import { AngularFireAuthModule } 	from '@angular/fire/auth';
import { AngularFireModule } 			from '@angular/fire';
import { AngularFirestoreModule } 	from '@angular/fire/firestore';
import { AppComponent }					from './app.component';
import { AppRoutingModule }			from './app-routing.module';

import { AuthGuard }						from './_guards';

import { AuthService } 					from './_services';
import { BillingReportComponent }	from './components/reports';

import { DataService }					from './_services';
import { MeterService }					from './_services';

import { AdminComponent }				from './components/admin';
import { CsrComponent }					from './components/csr';
import { CustomerComponent }			from './components/customer';

import { CusPart } 						from './parts';
import { ForgotPasswordPart }			from './parts';

import { HomeAverageUse }				from './parts';
import { HomeButtonsPart }				from './parts';
import { HomeStatusPart }				from './parts';
import { HomeMapPart }					from './parts';
import { HomeGraphPart }				from './parts';

import { LocnPart }						from './parts';
import { LocnMeterHistoryPart }		from './parts';
import { LocnMapPart }					from './parts';
import { MeterPart }						from './parts';
import { NoMeterPart }					from './parts';
import { MeterButtonsPart }			from './parts';

import { MeterDetailPart }				from './parts';
import { MeterEventPart }				from './parts';
import { MeterGraphPart }				from './parts';
import { MeterGraph24Part}				from './parts';
import { MeterLocnHistoryPart }		from './parts';
import { MeterReadingPart }			from './parts';
import { UserInfoPart }					from './parts';

import { EmployeeComponent }			from './components/employee'
import { CreateUtilityComponent }	from './components/create-utility'
import { HomeComponent } 				from './components/home';
import { InstallComponent }			from './components/install';

import { LocComponent }					from './components/loc-comp';
import { LoginComponent }           from './components/login';

import { MeterComponent }				from './components/meter';
import { MeterCtrlComponent }			from './components/meter-ctrl';



import { MonitorPanelComponent } 	from './components/monitor-panel';
import { MonthlyUseageComponent }	from './components/reports';

import { PickUtilityComponent } 		from './components/pick-utility';
import { ReportsComponent }			from './components/reports';
import { SignUpComponent }				from './components/sign-up';
import { UiModule } 						from './components/ui/ui.module';
import { UserComponent }				from './components/user';
import { UtilityFormComponent } 		from './components/utility-form';

import { BrowserModule }				from '@angular/platform-browser';
import { ComposeMessageComponent }	from './compose-message/compose-message.component';

import { NgSelectModule } 				from '@ng-select/ng-select';

import { FormsModule }					from '@angular/forms';
import { GoogleMapsAPIWrapper } 		from '@agm/core';
import { HttpClientModule }			from '@angular/common/http';

import { GoogleChartsModule } 		from 'angular-google-charts';

import { Ng2SmartTableModule }		from 'ng2-smart-table';
import { NgModule }						from '@angular/core';
import { NgbModule } 					from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } 		from '@swimlane/ngx-datatable';
import { PageNotFoundPart }			from './parts';

import { RouterModule } 				from '@angular/router';

import { UserService } 					from './_services';
import { environment } 					from '../environments/environment';

import { ModalComponent }				from './_directives';
import { ModalService }					from './_services';
import { VerifyEmailComponent }		from './verify-email/verify-email.component';

import { BrowserAnimationsModule }	from '@angular/platform-browser/animations';
//import { AngularMaterialModule }		from './angular-material.module';

import { platformBrowserDynamic } 	from "@angular/platform-browser-dynamic";


@NgModule({

	declarations: [
		AdminComponent,
		AppComponent,
		BillingReportComponent,
		ComposeMessageComponent,

		CsrComponent,
		CustomerComponent,
		CusPart,
		EmployeeComponent,
		CreateUtilityComponent,

		HomeAverageUse,
		HomeComponent,
		HomeButtonsPart,
		HomeGraphPart,
		HomeMapPart,
		HomeStatusPart,

		InstallComponent,

		LocComponent,
		LocnPart,
		LocnMapPart,
		LocnMeterHistoryPart,
		MeterPart,
		NoMeterPart,
		MeterButtonsPart,

		MeterDetailPart,
		MeterEventPart,
		MeterGraphPart,
		MeterGraph24Part,
		MeterLocnHistoryPart,
		MeterReadingPart,
		LoginComponent,
		MeterComponent,
		MeterCtrlComponent,


		ModalComponent,
		MonthlyUseageComponent,
		PageNotFoundPart,
		ForgotPasswordPart,

		ReportsComponent,

		UserComponent,
		UtilityFormComponent,
		MonitorPanelComponent,
		SignUpComponent,
		VerifyEmailComponent,

		PickUtilityComponent,
		UserInfoPart,
	],

	imports: [
		AgmCoreModule.forRoot({apiKey: 'AIzaSyDn02xyFJhdWxLM4m8ZmRuakM__PCCzRus'}),
		AngularFireAuthModule, // imports firebase/auth, only needed for auth features
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule, // imports firebase/firestore, only needed for database features
		AppRoutingModule,
		BrowserModule,
		FormsModule,
//		GoogleMapsModule,
		NgSelectModule,
		HttpClientModule,
//		Ng2CompleterModule,

// 		Ng2GoogleChartsModule,
		GoogleChartsModule.forRoot(),

// 		NgSelectModule,
		Ng2SmartTableModule,
//		NgbModule.forRoot(),
		NgxDatatableModule,
//RF		ReactiveFormsModule,
		RouterModule,
// 		RouterModule.forRoot(rootRouterConfig, { useHash: false }),
		UiModule,
BrowserAnimationsModule,

		]	,

	providers: [
		AlertService,
		AuthGuard,
		AuthService,
		DataService,
		MeterService,
		GoogleMapsAPIWrapper,
		UserService,
		ModalService,
//		PubSub,
		],

	bootstrap: [AppComponent, ]

})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
