import { Component, OnInit			 					}	from '@angular/core';
import { MapsAPILoader, AgmMap 						}	from '@agm/core';

import { Location, Meter, Customer 					}	from '../_models';
import { ViewChild, NgZone  							}	from '@angular/core';
import { ActivatedRoute, Router, RouterModule	}	from '@angular/router';
import { DataService, MapService 					}	from '../_services';
import { AuthService 									}	from '../_services';

interface Marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

@Component({
	selector:		'part-home-map',
	template: `
		<style>

			div.LegendBox		{position:absolute;  top: 120px; left:800px; width:180px; height:205px; border:1px solid black;}
			table.LegendTbl	{left:1%; width:98%; font-size: 14px;}

			div.cm_map			{border:1px solid black; top:120px; width:780px; height:700px}
			agm-map 				{height: 700px;}         
			
			.enA { background-color: lime; width: 150px;
				//   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
				}

			.disA { background-color: LightPink ; opacity: 0.6;width: 150px;}

						
			</style>
                                                                                                                                                                                               
		<div class=LegendBox>                                                                                                                                                                        
			<table class=LegendTbl>                                                                                                                                                                   			
				<thead><tr><th colspan=2>Map Legend</th></tr></thead>                                                                                                                                     		
				<tbody>                                                                                                                                                                                   
					<tr>
						<td><img src="https://stoshb.info/assets/img/iLocn.png"			/></td>
						<td><button [ngClass]="{'enA' : toggles.A, 'disA': !toggles.A}" (click)="showMapType('A')">Active Location</button></td>
						</tr>   			
					<tr>
						<td><img src="https://stoshb.info/assets/img/iMauveL.png"			/></td>
						<td><button [ngClass]="{'enA' : toggles.I, 'disA': !toggles.I}" (click)="showMapType('I')">Inactive Location</button></td>
						</tr> 	
					<tr><td><img src="https://stoshb.info/assets/img/iWhiteMeter.png"	/></td>
						<td><button [ngClass]="{'enA' : toggles.R, 'disA': !toggles.R}" (click)="showMapType('R')">Meter On Order</button></td></tr>    	
					<tr><td><img src="https://stoshb.info/assets/img/iGreenMeter.png"	/></td>
						<td><button [ngClass]="{'enA' : toggles.K, 'disA': !toggles.K}" (click)="showMapType('K')">Meter OK</button></td></tr>          	
					<tr><td><img src="https://stoshb.info/assets/img/iRedMeter.png"		/></td>
						<td><button [ngClass]="{'enA' : toggles.P, 'disA': !toggles.P}" (click)="showMapType('P')">Meter Alarm</button></td></tr>       	
					<tr><td><img src="https://stoshb.info/assets/img/iYellowMeter.png"	/></td><td><button [ngClass]="{'enA' : toggles.L, 'disA': !toggles.L}" (click)="showMapType('L')">Meter on Life Line</button></td></tr>	
         	                                                                                                                                                                                          		
				</tbody>                                                                                                                                                                                  		
				</table>                                                                                                                                                                                  		
			</div>                                                                                                                                                                                    


		<div class=cm_map>
			<agm-map
				[(latitude)]="map_lat"
				[(longitude)]="map_lon"
				[(zoom)]="zoom_value"
				[disableDefaultUI]="true"
				[zoomControl]="true"
				(boundsChange)="checkMarkersInBounds($event)"
				(zoomChange)="zoomChanged($event)"
				>

				<agm-marker *ngFor="let marker of markers"
					[latitude]="marker['lat']"
   				[longitude]="marker['lng']"
   				[iconUrl]="marker['icon']"
   				(markerClick)="markerClick(marker['gid'])" >
      		
					<agm-info-window>
						<p>{{ marker.gid }}</p>
						</agm-info-window>
					</agm-marker>
				</agm-map>
			</div>

`,
	providers:	[MapService]
	})

export class HomeMapPart implements OnInit
	{

	customer:	Customer;
   OneLoc	= false;
   specifiedLocn: Location;
   specifiedMeter: Meter;
	map_lat = 34.00;
	map_lon = -96.00;
	zoom_value = 15;

	toggles = {
		A:	false,		// Active
		K:	false,		// OK
		I:	false,		//	InActive
		R:	false,		// On Order
		P:	true,			// Alarm
		L:	true,			// Life Line
		};

	pntNW = {
		lat:	0.0,
		lon:	0.0,
		};
	pntSE = {
		lat:	0.0,
		lon:	0.0,
		};

	mapBounds = {
		NW:	this.pntNW,
		SE:	this.pntSE,
		};
	boundString = '';

	kmap_lat=32;
	kmap_lon=-96;

	markers = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  draggable: true,
		  icon: "https://stoshb.info/assets/img/iLocn.png",
	  },
//	  {
//		  lat: 51.373858,
//		  lng: 7.215982,
//		  draggable: false,
//		  icon: "https://stoshb.info/assets/img/iGreenMeter.png",
//	  }
  ];

	DummyMarker = {
		  lat: 0,
		  lng: 0,
		  draggable: false,
		  icon: "https://stoshb.info/assets/img/iGreenMeter.png",
	  };

	constructor(
		private dataSrv:		DataService,
		private mapSrv:		MapService,
		public authService: 	AuthService,
		public router: 		Router,
		)
		{
		}

	ngOnInit()
		{

		this.map_lat = this.authService.utilityObject.LAT / 1.0;
		this.map_lon = this.authService.utilityObject.LON / 1.0;

		}

	checkMarkersInBounds(bounds)
		{
// 		console.log( bounds.toJSON() );
 		var J = bounds.toJSON();
		this.mapSrv.clearMarkers( this.markers );
		this.boundString =  '&SElat=' + J.south + '&NWlat=' + J.north + '&NWlon=' + J.west
			+ '&SElon=' + J.east  ;
//		console.log( 'Bound str: ' + this.boundString  );
		this.mapSrv.plotPoints(this.markers, this.boundString, this.toggles );
		}

	markerClick( locnGid )
		{
//		console.log( "call locn/gid ")
		this.router.navigate(['/locn/' + locnGid]);
//		console.log("Did that for: " + locnGid )

		this.dataSrv.getLocnByLocnGid( locnGid ).subscribe(
			( ldata ) => {
			this.customer 			= ldata.customer;
			this.OneLoc				= true;
		   this.specifiedLocn	= ldata.locn;
		   this.specifiedMeter	= ldata.meter;
//		   console.log( this.specifiedMeter );
			});

		}

	showMapType( Flag ) {
		switch	(Flag)
			{
			case 'A':
				{
				if ( this.toggles[ 'A' ] ) { this.zapToggles() }
				else
					{
					this.toggles[ 'A' ] =	true			// Active
					this.toggles[ 'I' ] =	false			//	InActive
					this.toggles[ 'K' ] =	true			// OK
					this.toggles[ 'R' ] =	true			// On Order
					this.toggles[ 'P' ] =	true			// Alarm
					this.toggles[ 'L' ] =	true			// Life Line
					}
				}
				break;
			case 'I':
				{
				if ( this.toggles[ 'I' ] ){ this.zapToggles() }
				else
					{
					this.toggles[ 'A' ] =	false			// Active
					this.toggles[ 'I' ] =	true			//	InActive
					this.toggles[ 'K' ] =	false			// OK
					this.toggles[ 'R' ] =	false			// On Order
					this.toggles[ 'P' ] =	false			// Alarm
					this.toggles[ 'L' ] =	false			// Life Line
					}
				}
				break;
			case 'K':											// OK
				{
				if ( this.toggles[ 'K' ] )
					{ 
					this.toggles[ 'K' ] = false;			// OK
					this.toggles[ 'A' ] = false;			// Active
					}
				else
					{
					this.toggles[ 'I' ] =	false			//	InActive
					this.toggles[ 'K' ] =	true			// OK
					}
				}
				break;
			case 'R':											// On Order
				{
				if ( this.toggles[ 'R' ] )
					{ 
					this.toggles[ 'R' ] = false;			// On Order
					this.toggles[ 'A' ] = false;			// Active
					}
				else
					{
					this.toggles[ 'I' ] =	false			//	InActive
					this.toggles[ 'R' ] =	true			// On Order
					}
				}
				break;
			case 'P':											// Alarm
				{
				if ( this.toggles[ 'P' ] )
					{ 
					this.toggles[ 'P' ] = false;			// Alarm
					this.toggles[ 'A' ] = false;			// Active
					}
				else
					{
					this.toggles[ 'I' ] =	false			//	InActive
					this.toggles[ 'P' ] =	true			// Alarm
					}
				}
				break;
			case 'L':											// Life Line
				{
				if ( this.toggles[ 'L' ] )
					{ 
					this.toggles[ 'L' ] = false;			// Life Line
					this.toggles[ 'A' ] = false;			// Active
					}
				else
					{
					this.toggles[ 'I' ] =	false			//	InActive
					this.toggles[ 'L' ] =	true			// Life Line
					}
				}
				break;
			default:
				this.toggles[ Flag ] = !this.toggles[Flag ]
				break;
			}
// 		console.log ( "Flag: " + Flag +  " = " + this.toggles[ Flag ] + " ==> " + !this.toggles[ Flag ]);;
		this.mapSrv.clearMarkers( this.markers );
//		console.log( 'Bound str: ' + this.boundString  );
		this.mapSrv.plotPoints(this.markers, this.boundString, this.toggles );
		}

	zapToggles()
		{
		this.toggles[ 'A' ] =	false			// Active
		this.toggles[ 'I' ] =	false			//	InActive
		this.toggles[ 'K' ] =	false			// OK
		this.toggles[ 'R' ] =	false			// On Order
		this.toggles[ 'P' ] =	false			// Alarm
		this.toggles[ 'L' ] =	false			// Life Line			
		}

	zoomChanged(zoom)
		{
		this.dataSrv.changeZoom( zoom );
		}



}

