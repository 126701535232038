
import { Component, OnInit, Input } 				from '@angular/core';
import { DataService }									from '..//../_services';
import { Observable }									from 'rxjs/Rx';
import { AuthService } 									from '../../_services';
import { Router }											from "@angular/router";

@Component({
	selector: 'app-billing-report',
	template:
`
<style>
div.list_info			{position:absolute;	top: 60px; left: 0px; width: 998px; height:1000px; border:1px solid blue }
ng2-smart-table		{ font-size: 12px}
</style>

<div class="container">
	<div class=list_info >
		<ng2-smart-table
			[settings]		=	"settings"
			[source]			=	"Useage"
			>
			</ng2-smart-table>
	</div>
</div>
`,
})

export class  BillingReportComponent implements OnInit {

	@Input() rpt_key: number;

	public Useage;

	constructor(
		public authService:	AuthService,
		private dataSrv:		DataService,
		) {
			if(!this.authService.isRoleOK("mp" )) {
			window.alert("You are not allowed to access this URL!");
			}
		}

	settings = {
		pager:	{
			display: true,
			perPage: 25,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},

		columns: {
			cust_acct:		{title: 'Cust Acct #', 	width: '120px'},
			cname:			{title: 'Customer',		width: '560px'},
			locn_acct:		{title: 'Locn Acct #',	width: '100px'},
			meter_id:      {title: 'Meter', 			width: '200px'},
			billing_code:	{title: 'Code', 			width: '20px'},
			useage:   		{title: 'Useage', 		width: '100px'},
			},
		attr: {
      	class: 'table table-bordered tr'
      	},
		};

	ngOnInit()
		{

//		this.dataSrv.getCustomerUseage ('2021-02-01', '2021-02-28' ).subscribe(
console.log (this.rpt_key );
		this.dataSrv.getBillingRecord(this.rpt_key ).subscribe(
			( useage ) => {
			console.log( useage );
			this.Useage = useage
			});
		}



}