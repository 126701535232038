<style>
	div.cm_svrsh		{text-align: left; width:989px; height:150px;  border:1px solid  black;}
	div.mr_title_ln	{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}

	div.cm_rdth		   {position:absolute; top:  85px;  left:   0px;text-align: left;  width:499px; height:60px; }
	div.cm_rdt 		   {position:absolute; top:   0px;  left: 160px;text-align: left}
	
	div.cm_nxth			{position:absolute; top: 105px;  left:   0px;text-align: left;  width:499px; height:60px; }
	div.cm_nxt			{position:absolute; top:   0px;  left: 160px;text-align: left;}

	div.sm_use			{position:absolute; top:  40px;  left:  00px; width:340px; height:30px; text-align:center }
	div.sm_char1		{position:absolute; top:   5px;  left:   0px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_cma1			{position:absolute; top:   5px;  left:  22px; 								 width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char2		{position:absolute; top:   5px;  left:  40px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char3		{position:absolute; top:   5px;  left:  70px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char4		{position:absolute; top:   5px;  left: 100px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_cma2			{position:absolute; top:   5px;  left: 122px; 								 width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char5		{position:absolute; top:   5px;  left: 140px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char6		{position:absolute; top:   5px;  left: 170px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char7		{position:absolute; top:   5px;  left: 200px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_dot			{position:absolute; top:   5px;  left: 222px; 								 width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char8		{position:absolute; top:   5px;  left: 240px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char9		{position:absolute; top:   5px;  left: 240px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char10		{position:absolute; top:   5px;  left: 270px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_char11		{position:absolute; top:   5px;  left: 300px; border:1px solid black; width: 30px; height:37px; font-size:26px;text-align:center;}
	div.sm_gals			{position:absolute; top:   5px;  left: 300px; height:30px; font-size:20px;text-align:left;}

	div.cm_tmphg		{position:absolute; top:   30px; left:  390px; }
	div.cm_prehg		{position:absolute; top:   30px; left:  510px; }
	div.cm_bathg		{position:absolute; top:   30px; left:  630px; }
	div.cm_sighg		{position:absolute; top:   30px; left:  870px; }


	</style>

<div class=cm_svrsh >

	<div class=mr_title_ln>Current Reading</div>
	<div class=cm_rdth>Last Read At:
		<div class=cm_rdt  >{{ lastReadDate }}</div>
		</div>

	<div class=cm_nxth>Next Scheduled:
		<div class=cm_nxt [innerHTML]="nextReport"></div>
		</div>
	

	<div class=sm_use id=METR_USE>
			<div class=sm_char1  id=SMU_1  >1 </div>
			<div class=sm_cma1  >, </div>
			<div class=sm_char2  id=SMU_2  >2 </div>
			<div class=sm_char3  id=SMU_3  >3 </div>
			<div class=sm_char4  id=SMU_4  >4 </div>
			<div class=sm_cma2  >, </div>
			<div class=sm_char5  id=SMU_5  >5 </div>
			<div class=sm_char6  id=SMU_6  >6 </div>
			<div class=sm_char7  id=SMU_7  >7 </div>
			<div class=sm_dot  >. </div>
			<div class=sm_char8  id=SMU_8  >8 </div>
			<div class=sm_gals >Gallons</div>

		</div>

	<div class=cm_tmphg><google-chart [title]="titleT" [data]="dataTemp" [type]="gaugeType" [options]="optT" ></google-chart>	</div>
	<div class=cm_prehg><google-chart [title]="titleP" [data]="dataPres" [type]="gaugeType" [options]="optP" ></google-chart>	</div>
	<div class=cm_bathg><google-chart [title]="titleV" [data]="dataVolt" [type]="gaugeType" [options]="optV" ></google-chart>	</div>
	<div class=cm_sighg><google-chart [title]="titleS" [data]="dataSigl" [type]="gaugeType" [options]="optS" ></google-chart>	</div>

</div>







