
<app-header></app-header>

<div class="container">
	<h1 style="text-align:center">Utility Detail Form</h1>
	<form #utilityDetailForm="ngForm" (ngSubmit)="onSubmit()"  class="form-horizontal"  >

		<div class="form-group row">
			<label class="control-label col-lg-2" for="utility_name">Utility Name</label>
			<input type="text" class="form-control col-lg-10" id="utility_name"
				[(ngModel)]="model.utility_name" placeholder="Utility Name"
				name="utility_name" #utility_name="ngModel" required >
			</div>
		<div class="form-group row">
			<label class="control-label col-lg-2" for="uAddr1">Address 1</label>
			<input type="text" class="form-control col-lg-10" id="uAddr1"
				[(ngModel)]="model.Addr1"  placeholder="Street Address 1"
				name="uAddr1" #uAddr1="ngModel" required >
			</div>
		<div class="form-group row">
			<label class="control-label col-lg-2" for="uAddr2">Address 2</label>
			<input type="text" class="form-control col-lg-10" id="uAddr2"
				[(ngModel)]="model.Addr2"  placeholder="Street Address 2"
				name="uAddr2" #uAddr2="ngModel">
			</div>

		<div class="form-group row">
				<label class="control-label col-lg-2" for="City"	>City, State</label>
				<input type="text" class="form-control col-lg-7"  id="uCity"   placeholder="City"
						required [(ngModel)]="model.City" name="uCity ">
				<select id="State" class="form-control col-lg-2 " name="State" required [(ngModel)]="model.State"  >
					<option selected>Choose...</option>
  					<option value="" selected="selected">Select a State</option>
  					<option value="AL">Alabama</option>  	<option value="AK">Alaska</option>
  					<option value="AZ">Arizona</option>		<option value="AR">Arkansas</option>
  					<option value="CA">California</option>	<option value="CO">Colorado</option>
  					<option value="CT">Connecticut</option>	<option value="DE">Delaware</option>
  					<option value="DC">District Of Columbia</option><option value="FL">Florida</option>
  					<option value="GA">Georgia</option>		<option value="HI">Hawaii</option>
  					<option value="ID">Idaho</option>			<option value="IL">Illinois</option>
  					<option value="IN">Indiana</option>		<option value="IA">Iowa</option>
  					<option value="KS">Kansas</option>		<option value="KY">Kentucky</option>
  					<option value="LA">Louisiana</option>	<option value="ME">Maine</option>
  					<option value="MD">Maryland</option>		<option value="MA">Massachusetts</option>
  					<option value="MI">Michigan</option>		<option value="MN">Minnesota</option>
  					<option value="MS">Mississippi</option>	<option value="MO">Missouri</option>
  					<option value="MT">Montana</option>		<option value="NE">Nebraska</option>
  					<option value="NV">Nevada</option>		<option value="NH">New Hampshire</option>
  					<option value="NJ">New Jersey</option>	<option value="NM">New Mexico</option>
  					<option value="NY">New York</option>		<option value="NC">North Carolina</option>
  					<option value="ND">North Dakota</option>	<option value="OH">Ohio</option>
  					<option value="OK">Oklahoma</option>		<option value="OR">Oregon</option>
  					<option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option>
  					<option value="SC">South Carolina</option>	<option value="SD">South Dakota</option>
  					<option value="TN">Tennessee</option>	<option value="TX">Texas</option>
  					<option value="UT">Utah</option>			<option value="VT">Vermont</option>
  					<option value="VA">Virginia</option>		<option value="WA">Washington</option>
  					<option value="WV">West Virginia</option><option value="WI">Wisconsin</option>
  					<option value="WY">Wyoming</option>
					</select>
		   	</div>

		<div class="form-group row">
				<label class="control-label col-lg-2" for="TZ"	>Timezone </label>
				<select id="TZ" class="form-control col-lg-3 " name="TZ" required [(ngModel)]="model.TZ"  >
					<option selected>Choose...</option>
  					<option value="" selected="selected">Select a timerzone</option>
					<option value="America/Anchorage"               >America/Anchorage</option>
					<option value="America/Chicago"                 >America/Chicago</option>
					<option value="America/Denver"                  >America/Denver</option>
					<option value="America/Detroit"                 >America/Detroit</option>
					<option value="America/Edmonton"                >America/Edmonton</option>
					<option value="America/Fort_Wayne"              >America/Fort_Wayne</option>
					<option value="America/Indiana/Indianapolis"    >America/Indiana/Indianapolis</option>
					<option value="America/Indiana/Knox"            >America/Indiana/Knox</option>
					<option value="America/Indiana/Marengo"         >America/Indiana/Marengo</option>
					<option value="America/Indiana/Petersburg"      >America/Indiana/Petersburg</option>
					<option value="America/Indiana/Tell_City"       >America/Indiana/Tell_City</option>
					<option value="America/Indiana/Vevay"           >America/Indiana/Vevay</option>
					<option value="America/Indiana/Vincennes"       >America/Indiana/Vincennes</option>
					<option value="America/Indiana/Winamac"         >America/Indiana/Winamac</option>
					<option value="America/Indianapolis"            >America/Indianapolis</option>
					<option value="America/Juneau"                  >America/Juneau</option>
					<option value="America/Kentucky/Louisville"     >America/Kentucky/Louisville</option>
					<option value="America/Kentucky/Monticello"     >America/Kentucky/Monticello</option>
					<option value="America/Knox_IN"                 >America/Knox_IN</option>
					<option value="America/Los_Angeles"             >America/Los_Angeles</option>
					<option value="America/Louisville"              >America/Louisville</option>
					<option value="America/Montreal"                >America/Montreal</option>
					<option value="America/New_York"                >America/New_York</option>
					<option value="America/North_Dakota/Beulah"     >America/North_Dakota/Beulah</option>
					<option value="America/North_Dakota/Center"     >America/North_Dakota/Center</option>
					<option value="America/North_Dakota/New_Salem"  >America/North_Dakota/New_Salem</option>
					<option value="America/Phoenix"                 >America/Phoenix</option>
					<option value="America/Toronto"                 >America/Toronto</option>
					<option value="America/Vancouver"               >America/Vancouver</option>
					<option value="America/Winnipeg"                >America/Winnipeg</option>
					</select>

				<label class="control-label col-lg-2" for="LAT">LAT/LON</label>
				<input type="text" class="form-control col-lg-2"   id="LAT" required [(ngModel)]="model.LAT" name="LAT">
				<input type="text" class="form-control col-lg-2"  id="LON" required [(ngModel)]="model.LON" name="LON">
			</div>

		<div class="form-group row">
			<label class="control-label col-sm-2" for="ALP"       >Low Pres    </label>
			<input type="text" class="form-control col-lg-1"
				id="ALP"        required [(ngModel)]="model.ALP"        name="ALP" #ALP="ngModel">
			<label class="control-label col-sm-2" for="AHP"       >High Pres   </label>
			<input type="text" class="form-control col-lg-1"   id="AHP"        required [(ngModel)]="model.AHP"        name="AHP">
			<label class="control-label col-sm-2" for="ALT"       >Low Temp    </label>
			<input type="text" class="form-control col-lg-1"   id="ALT"        required [(ngModel)]="model.ALT"        name="ALT">
			<label class="control-label col-sm-2" for="AHT"       >High Temp   </label>
			<input type="text" class="form-control col-lg-1"   id="AHT"        required [(ngModel)]="model.AHT"        name="AHT">
			</div>

		<div class="form-group row">
			<label class="control-label col-sm-2" for="LowPwr"    >Low Power   </label>
			<input type="text" class="form-control col-lg-1" id="LowPwr"     required [(ngModel)]="model.LowPwr"     name="LowPwr">
			<label class="control-label col-sm-2" for="ValveLL"	  >Life Line %	</label>
			<input type="text" class="form-control col-lg-1"   id="ValveLL" 	 required [(ngModel)]="model.ValveLL" 	  name="ValveLL">
			<label class="control-label col-sm-2" for="FlowMin"	  >Low Flow		</label>
			<input type="text" class="form-control col-lg-1"   id="FlowMin" 	 required [(ngModel)]="model.FlowMin" 	  name="FlowMin">
			<label class="control-label col-sm-2" for="FlowMax"   >High Flow	</label>
			<input type="text" class="form-control col-lg-1"   id="FlowMax" 	 required [(ngModel)]="model.FlowMax"    name="FlowMax">
			</div>

		<div class="form-group row">
			<label class="control-label col-sm-2" for="RptStartTm">Rpt Start Tm</label>
			<input type="text" class="form-control col-lg-1"   id="RptStartTm" required [(ngModel)]="model.RptStartTm" name="RptStartTm">
			<label class="control-label col-sm-2" for="RptEndTm"  >Rpt End Tm  </label>
			<input type="text" class="form-control col-lg-1"   id="RptEndTm" 	 required [(ngModel)]="model.RptEndTm"   name="RptEndTm">
			<label class="control-label col-sm-2" for="RptFreq "  >Rpt Interval</label>
			<input type="text" class="form-control col-lg-2"    id="RptFreq"    required [(ngModel)]="model.RptFreq"    name="RptFreq">
			</div>
			
		<div class="form-group row">
			<label class="control-label col-sm-2" for="SvrIP"     >Server IP   </label>
			<input type="text" class="form-control col-lg-2"  id="SvrIP"      required [(ngModel)]="model.SvrIP"      name="SvrIP">
			<label class="control-label col-sm-2" for="SvrPort"   >Server Port </label>
			<input type="text" class="form-control col-lg-2"   id="SvrPort"    required [(ngModel)]="model.SvrPort"    name="SvrPort">
			<label class="control-label col-sm-2" for="init_zoom" >Init Zoom   </label>
			<input type="text" class="form-control col-lg-1"    id="init_zoom"  required [(ngModel)]="model.init_zoom"  name="init_zoom">
			</div>

		<button type="submit" class="btn btn-success l"
			[disabled]="!utilityDetailForm.form.valid">Submit</button>


	</form>
</div>