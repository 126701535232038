export class UtilityDetail {
	constructor
		(
		public	gid:				number,		
		public	logo_link:		string,
		public	utility_name:	string,
		public	Addr1:			string ,
		public	Addr2:			string,

		public	Unit:				string,
		public	City:				string ,
		public	State:			string ,		
		public	AHP:				number,
		public	AHT:				number,

		public	ALP:				number,
		public	ALT:				number,
		public	init_zoom:		number,
		public	LAT:				number,
		public	LON:				number,

		public	LowPwr:			number,
		public	RptFreq:			number,
		public	SvrIP:			string ,
		public	SvrPort:			number,
		public	TZ:				string,

		public	RptEndTm:		string,
		public	RptStartTm:		string,
		public	Protocol:		number,
		public	FlowMax:			number,
		public	FlowMin:			number,

		public	ValveLL:			number


		) {}
	}
