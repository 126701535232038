<app-header>
	</app-header>


	<div class=cm_usec2 *ngIf="editInstall">
		<div class=ghead_box >Install Meter at
			<p>{{IStNbr}} {{IStName}}
			<p>{{ ICity }}
		</div>

		<div class="fg-meter_id">
			<label class="meter_pos">Meter ID</label>
			<input class="input-medium i-meter_id" (keyup)="onMeterIDKey($event)"
				type="text" ng-model="meter_idxx"  placeholder={{meter_id}} >
			<button type="button" class="btns btn-success" (click)="onExit(1)" >
				<img SRC="https://stoshb.info/assets/img/SaveData.jpeg" title="Click to saveimeter" />
				</button>
			<button type="button" class="btnc btn-concel" (click)="onExit(0)" >
				<img SRC="https://stoshb.info/assets/img/back-icon.png" title="Cancel and return" />
				</button>
			</div>

		</div>

	<div class=search_exp >
		<li>To search the 100 block enter 1**
		<li>To search the 1000 block enter 1***
		<li>To search the 10000 block enter 1****
		</div>

	<span *ngIf="showQuestion">
		<div class=search_title>Enter Block/Street</div>
		</span>

	<span *ngIf="!showQuestion">
		<div class=search_title>I am on Block/Street</div>
		<div class=search_nbr > {{addrNbrFilter}}</div>
		<div class=search_str > {{addrStrFilter}}</div>

		</span>

	<div class=cm_usec1 *ngIf="findLocation">

		<input (keyup)="onStNbr($event)" class="search_nbr   input-medium"
			type="text" ng-model="addrNbrFilter" placeholder="Block...">
		<input (keyup)="onStName($event)" class="search_str   input-medium"
				type="text" ng-model="addrStrFilter" placeholder="St Name...">
<!--					(userRowSelect)=	"onRowSelect1($event)" -->
		<span *ngIf="dispScrn">
			<div class="Screen1">
				Data for {{valueStNbr}}, {{valueStName}}
				<p>Make a selection
				<p>
				<ng2-smart-table
				    [settings]		=	"screen1List"
				    [source]		=	"blockStreet"

					(custom)			=	"onScreen1Custom($event)"
				    >
				</ng2-smart-table>
				</div>
			</span>

		<span *ngIf="!dispScrn">
			<div class="Screen1">
				Locations on {{valueStNbr}} {{IStName}}, {{ICity}}
				<p>Select a location
				<p>
				<ng2-smart-table
				    [settings]		=	"screen2List"
				    [source]		=	"locations"
					(userRowSelect)=	"onRowSelect2($event)"
				    >
				</ng2-smart-table>
				</div>
			</span>

   	</div>



