import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth 	} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { DataService 		} 			from '../_services/data.service';

@Injectable()
export class UserService {

	constructor
		(
		public db: AngularFirestore,
		private dataSrv: DataService,
		public afAuth: AngularFireAuth
		) { }


	getCurrentUser() {
		console.log('In function getCurrentUser');
		this.dataSrv.changeUtility( -1 );
		this.dataSrv.changeLogin( false, '', '' );
		return new Promise<any>((resolve, reject) => {
			const user = firebase.auth().onAuthStateChanged(function(user) {
				if (user) {
					console.log('User is logged in');
					console.log( user );
					console.log( 'displayName:' + user.displayName + ' is Anonymous: ' + user.isAnonymous );
					console.log( 'email:' + user.email  + ' verified: ' + user.emailVerified);
					console.log( 'providerID:' + user.providerId );
					console.log( 'refreshToken:' + user.refreshToken );
					console.log( 'uid:' + user.uid );
					resolve(user);
					} else {
					localStorage.removeItem('currentUser');
// 					this.dataSrv.changeUtility( -1 );
// 					this.dataSrv.changeLogin( '', false, '', '' );

					console.log('No user logged in');
					reject('No user logged in');
					}
				});
			});
		}

	updateCurrentUser(value) {
		return new Promise<any>((resolve, reject) => {
			const user = firebase.auth().currentUser;
			user.updateProfile({
				displayName: value.name,
				photoURL: user.photoURL
			}).then(res => {
				resolve(res);
			}, err => reject(err));
		});
	}
}
