import { Component, OnInit  }			from '@angular/core';
import { AuthService } 					from '../_services';

@Component({
  selector:	'part-user-info',
  template:
		`<span  style="position:absolute;left:78%;top:6px">
			{{ now | date:'MM/dd/yyyy HH:mm:ss'}}
			<p>
			{{ authService.currentUserName }} <!--  On for {{ deltaT  }}  Min -->
			</p>
		</span>`
	})

export class UserInfoPart implements OnInit
	{
	now:			number;
	deltaT:		number;

	constructor
		(
		public authService:	AuthService,
		)
		{
		setInterval(() => {
			this.now = Date.now();
			this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
			}, 1000);
		}

	ngOnInit()
		{
		}
}




