<app-header></app-header>



<div class="container">
	<h1>Create Test Utility And User</h1>
	<form #utilityDetailForm="ngForm" (ngSubmit)="onSubmit()"  >

		<div class="form-group row">
			<label class="control-label col-lg-2" for="utility_name">Utility Name</label>
			<input type="text" class="form-control col-lg-10" id="utility_name"
				[(ngModel)]="utility.utility_name" placeholder="Utility Name"
				name="utility_name" #utility_name="ngModel" required >
			</div>

		<div class="form-group row">
			<label class="control-label col-lg-2" for="uAddr1">Utility Address</label>
			<input type="text" class="form-control col-lg-10" id="uAddr1"
				[(ngModel)]="utility.Addr1"  placeholder="Street Address 1"
				name="uAddr1" #uAddr1="ngModel" required >
			</div>

		<div class="form-group row">
				<label class="control-label col-lg-2" for="City"	>City, State</label>
				<input type="text" class="form-control col-lg-7"  id="uCity"   placeholder="City"
						required [(ngModel)]="utility.City" name="uCity ">
				<select id="State" class="form-control col-lg-2 " name="State" required [(ngModel)]="utility.State"  >
					<option selected>Choose...</option>
  					<option value="" selected="selected">Select a State</option>
  					<option value="AL">Alabama</option>  	<option value="AK">Alaska</option>
  					<option value="AZ">Arizona</option>		<option value="AR">Arkansas</option>
  					<option value="CA">California</option>	<option value="CO">Colorado</option>
  					<option value="CT">Connecticut</option>	<option value="DE">Delaware</option>
  					<option value="DC">District Of Columbia</option><option value="FL">Florida</option>
  					<option value="GA">Georgia</option>		<option value="HI">Hawaii</option>
  					<option value="ID">Idaho</option>			<option value="IL">Illinois</option>
  					<option value="IN">Indiana</option>		<option value="IA">Iowa</option>
  					<option value="KS">Kansas</option>		<option value="KY">Kentucky</option>
  					<option value="LA">Louisiana</option>	<option value="ME">Maine</option>
  					<option value="MD">Maryland</option>		<option value="MA">Massachusetts</option>
  					<option value="MI">Michigan</option>		<option value="MN">Minnesota</option>
  					<option value="MS">Mississippi</option>	<option value="MO">Missouri</option>
  					<option value="MT">Montana</option>		<option value="NE">Nebraska</option>
  					<option value="NV">Nevada</option>		<option value="NH">New Hampshire</option>
  					<option value="NJ">New Jersey</option>	<option value="NM">New Mexico</option>
  					<option value="NY">New York</option>		<option value="NC">North Carolina</option>
  					<option value="ND">North Dakota</option>	<option value="OH">Ohio</option>
  					<option value="OK">Oklahoma</option>		<option value="OR">Oregon</option>
  					<option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option>
  					<option value="SC">South Carolina</option>	<option value="SD">South Dakota</option>
  					<option value="TN">Tennessee</option>	<option value="TX">Texas</option>
  					<option value="UT">Utah</option>			<option value="VT">Vermont</option>
  					<option value="VA">Virginia</option>		<option value="WA">Washington</option>
  					<option value="WV">West Virginia</option><option value="WI">Wisconsin</option>
  					<option value="WY">Wyoming</option>
					</select>
		   	</div>

		<div class="form-group row">
				<label class="control-label col-lg-2" for="TZ"	>Timezone </label>
				<select id="TZ" class="form-control col-lg-3 " name="TZ" required [(ngModel)]="utility.TZ"  >
					<option selected>Choose...</option>
  					<option value="" selected="selected">Select a timerzone</option>
					<option value="America/Anchorage"               >America/Anchorage</option>
					<option value="America/Chicago"                 >America/Chicago</option>
					<option value="America/Denver"                  >America/Denver</option>
					<option value="America/Detroit"                 >America/Detroit</option>
					<option value="America/Edmonton"                >America/Edmonton</option>
					<option value="America/Fort_Wayne"              >America/Fort_Wayne</option>
					<option value="America/Indiana/Indianapolis"    >America/Indiana/Indianapolis</option>
					<option value="America/Indiana/Knox"            >America/Indiana/Knox</option>
					<option value="America/Indiana/Marengo"         >America/Indiana/Marengo</option>
					<option value="America/Indiana/Petersburg"      >America/Indiana/Petersburg</option>
					<option value="America/Indiana/Tell_City"       >America/Indiana/Tell_City</option>
					<option value="America/Indiana/Vevay"           >America/Indiana/Vevay</option>
					<option value="America/Indiana/Vincennes"       >America/Indiana/Vincennes</option>
					<option value="America/Indiana/Winamac"         >America/Indiana/Winamac</option>
					<option value="America/Indianapolis"            >America/Indianapolis</option>
					<option value="America/Juneau"                  >America/Juneau</option>
					<option value="America/Kentucky/Louisville"     >America/Kentucky/Louisville</option>
					<option value="America/Kentucky/Monticello"     >America/Kentucky/Monticello</option>
					<option value="America/Knox_IN"                 >America/Knox_IN</option>
					<option value="America/Los_Angeles"             >America/Los_Angeles</option>
					<option value="America/Louisville"              >America/Louisville</option>
					<option value="America/Montreal"                >America/Montreal</option>
					<option value="America/New_York"                >America/New_York</option>
					<option value="America/North_Dakota/Beulah"     >America/North_Dakota/Beulah</option>
					<option value="America/North_Dakota/Center"     >America/North_Dakota/Center</option>
					<option value="America/North_Dakota/New_Salem"  >America/North_Dakota/New_Salem</option>
					<option value="America/Phoenix"                 >America/Phoenix</option>
					<option value="America/Toronto"                 >America/Toronto</option>
					<option value="America/Vancouver"               >America/Vancouver</option>
					<option value="America/Winnipeg"                >America/Winnipeg</option>
					</select>
			</div>

		<div class="form-group row">
			<label class="control-label col-lg-2" for="uAddr1">First Name</label>
			<input type="text" class="form-control col-lg-3" id="First_Name"
				[(ngModel)]="tester.emp.First_Name"  placeholder="First Name"
				required  name="First_Name" >
			<label class="control-label col-lg-2" for="uAddr1">Last Name</label>
			<input type="text" class="form-control col-lg-3" id="Last_Name"
				[(ngModel)]="tester.emp.Last_Name"  placeholder="Last Name"
				required  name="Last_Name" >
			</div>


		<div class="form-group row">
			<label class="control-label col-lg-2" for="taddr1">Address</label>
			<input type="text" class="form-control col-lg-4" id="taddr1"
				required [(ngModel)]="tester.addr1"  placeholder="Street Address 1"
				name="taddr1" >
			<input type="text" class="form-control col-lg-4" id="taddr2"
				[(ngModel)]="tester.addr2"  placeholder="Street Address 2"
				name="taddr2" >
			<input type="text" class="form-control col-lg-1" id="tUnit"
				[(ngModel)]="tester.unit"  placeholder="Unit"
				name="tUnit" >
			</div>

		<div class="form-group row">
				<label class="control-label col-lg-2" for="City"	>City, State</label>
				<input type="text" class="form-control col-lg-7"  id="uCity"   placeholder="City"
						required [(ngModel)]="tester.city" name="uCity ">
				<select id="State" class="form-control col-lg-2 " name="State" required [(ngModel)]="tester.state"  >
					<option selected>Choose...</option>
  					<option value="" selected="selected">Select a State</option>
  					<option value="AL">Alabama</option>  	<option value="AK">Alaska</option>
  					<option value="AZ">Arizona</option>		<option value="AR">Arkansas</option>
  					<option value="CA">California</option>	<option value="CO">Colorado</option>
  					<option value="CT">Connecticut</option>	<option value="DE">Delaware</option>
  					<option value="DC">District Of Columbia</option><option value="FL">Florida</option>
  					<option value="GA">Georgia</option>		<option value="HI">Hawaii</option>
  					<option value="ID">Idaho</option>			<option value="IL">Illinois</option>
  					<option value="IN">Indiana</option>		<option value="IA">Iowa</option>
  					<option value="KS">Kansas</option>		<option value="KY">Kentucky</option>
  					<option value="LA">Louisiana</option>	<option value="ME">Maine</option>
  					<option value="MD">Maryland</option>		<option value="MA">Massachusetts</option>
  					<option value="MI">Michigan</option>		<option value="MN">Minnesota</option>
  					<option value="MS">Mississippi</option>	<option value="MO">Missouri</option>
  					<option value="MT">Montana</option>		<option value="NE">Nebraska</option>
  					<option value="NV">Nevada</option>		<option value="NH">New Hampshire</option>
  					<option value="NJ">New Jersey</option>	<option value="NM">New Mexico</option>
  					<option value="NY">New York</option>		<option value="NC">North Carolina</option>
  					<option value="ND">North Dakota</option>	<option value="OH">Ohio</option>
  					<option value="OK">Oklahoma</option>		<option value="OR">Oregon</option>
  					<option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option>
  					<option value="SC">South Carolina</option>	<option value="SD">South Dakota</option>
  					<option value="TN">Tennessee</option>	<option value="TX">Texas</option>
  					<option value="UT">Utah</option>			<option value="VT">Vermont</option>
  					<option value="VA">Virginia</option>		<option value="WA">Washington</option>
  					<option value="WV">West Virginia</option><option value="WI">Wisconsin</option>
  					<option value="WY">Wyoming</option>
					</select>
		   	</div>

		<div class="form-group row">
			<label for="email" class="control-label col-lg-2" >Email address</label>
			<input type="email" class="control-label col-lg-4" id="email"  placeholder="Email Address"
				required [(ngModel)]="tester.emp.email_addr"   name="email" >
			<label for="phone" class="control-label col-lg-2">Phone</label>
			<input type="text" class="form-control control-label col-lg-2" id="phone"
				[(ngModel)]="tester.phone"   name="phone" placeholder="123-455-6789"  >
			</div>

		<div class="form-group row">
			<label for="Meter" class="control-label col-lg-2">Meter </label>
			<input type="text" class="control-label col-lg-4" id="Meter"   placeholder="Meter"
			[(ngModel)]="Meter_id" name="Meter">
			</div>

		<button type="button" class="btn btn-default" (click)="resetScreen(); utilityDetailForm.reset()">Reset</button>
		<button type="submit" class="btn btn-success" [disabled]="!utilityDetailForm.form.valid">Submit</button>

	</form>
</div>