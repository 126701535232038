<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />

	<a [routerLink]="['/home']" 	 >
		<i class="material-icons">home</i>
		Main Menu
		</a>

	<a [routerLink]="['/admin']" *ngIf="authService.isRoleOK( 'admin' )"
		style="position:absolute;left:45%">
		<i class="material-icons">perm_data_settings</i>
		Admin
		</a>

	<a (click)="authService.SignOut()" style="position:absolute;left:95%">
		<i class="material-icons">logout</i>
		</a>

	</nav>

<div class="displayTable">
  <div class="displayTableCell">

    <div class="authBlock">
      <h3>Create New User</h3>


      <div class="formGroup">
        <input type="text" class="formControl" placeholder="First Name" #userFirstName required>
      </div>
      <div class="formGroup">
        <input type="text" class="formControl" placeholder="Last Name" #userLastName required>
      </div>

      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email Address" #userEmail required>
      </div>

 <!--
       <div class="formGroup">
        <input type="text" class="formControl" placeholder="Utility Token" #userToken required>
      </div>
-->

      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Password" #userPwd required>
      </div>


     <div class="formGroup">
        <input type="button" class="btn btnPrimary" value="Sign Up"
        	(click)="SignUp(userEmail.value, userFirstName.value, userLastName.value, userPwd.value)">
 <!--       	userToken.value, userPwd.value)"> -->
      </div>

    <div class="redirectToLogin">
      <span>Already have an account? <span class="redirect" routerLink="/login">Log In</span></span>
    </div>
  </div>

</div>