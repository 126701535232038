import { Injectable } 		from '@angular/core';
import { Meter }				from '../_models';
import { DomSanitizer }		from '@angular/platform-browser';

@Injectable()
export class MeterService {

	constructor
		(
		private sanitizer: DomSanitizer
		) { }


	getNextReportDTG( _meter: Meter ) : Date
		{
		var rdelta = _meter.RptFreq/1.0;
_meter.read_date =  _meter.read_date.replace(' ', 'T' );
		console.log("RptFreq: " + _meter.RptFreq + "  rdelta: " + rdelta
			+ "  read_date: " + _meter.read_date + "  parm_flag: " +  _meter.parm_flag );
		var d = new Date( _meter.read_date);
		if ( d === null)
			{
			console.log("d = null");
			}
		if ( (_meter.parm_flag & 0x01) == 0 )
			{
			d.setSeconds( d.getSeconds() + rdelta  );
			}
		return d;
		}

	getNextReportDTGString( _meter: Meter )
		{
//		console.log( _meter );
		var rdelta = _meter.RptFreq/1.0;
_meter.read_date =  _meter.read_date.replace(' ', 'T' );
		console.log("RptFreq: " + _meter.RptFreq + "  rdelta: " + rdelta
			+ "  read_date: " + _meter.read_date + "  parm_flag: " +  _meter.parm_flag );
		var d = new Date( _meter.read_date );
//		console.log( d.toLocaleString() + "   " +  rdelta );
		if ( (_meter.parm_flag & 0x0) == 0 )
			{
			d.setSeconds( d.getSeconds() + rdelta  );
			}

		var nd = new Date();
		const delta = d.getTime() -  nd.getTime();
//		console.log ( delta );
		var colR = "blue";
		if ( delta < 0 ) { colR = "red";}
		const spStr = '<span style="color:' + colR + '" >';
//		console.log ( spStr );
		return this.sanitizer.bypassSecurityTrustHtml( spStr + d.toLocaleString() + "</span>");
		}
}
