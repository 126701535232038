import { Component, OnInit, Input } from '@angular/core';
import { DataService }					from '../_services';
import { Meter }							from '../_models';

@Component({
	selector: 'part-meter-detail',
	template: 
		`
		<style>
		:host /deep/ ng2-smart-table { 
    		font-size: 14px; 
			}
		</style>
		
		<ng2-smart-table    
		[settings]		=	"detailSettings"
		[source]			=	"detailData"
		(userRowSelect)=	"onDetailSelect($event)"
		>
		</ng2-smart-table>`
})

export class MeterDetailPart implements OnInit {

	_meter:	Meter;

	detailData:		any;
	detailSettings =
		{
		hideSubHeader:	true,
		pager:	{ perPage:	10, },
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			DTG:			{title: 'DTG',		 width: '220px'},
			Reading:		{title: 'Reading', type: 'html', valuePrepareFunction: function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }},
			useage:		{title: 'Useage',	 type: 'html', valuePrepareFunction: function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }},
			temperature: {title: 'Temp',	 type: 'html', valuePrepareFunction: function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }},
			pressure:	{title: 'Pres',	 type: 'html', valuePrepareFunction: function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }},
			b1v: 			{title: 'CPU',		 width: '10px'},
			b2v: 			{title: 'RTU',		 width: '10px'},
			vlv:			{title: 'Valve',	 type: 'html', valuePrepareFunction:
				function(value) {
					if ( value == 'O' ) {return '<div class="centerAlign"> Open </div>';}
					if ( value == 'L' ) {return '<div class="centerAlign"> LifeLine </div>';}
					return '<div class="centerAlign"> Closed </div>';
				}},
			sig:			{title: 'Signal',	 type: 'html', valuePrepareFunction: function(value) {return '<div class="centerAlign"> ' + value + ' </div>'; }},
//			status:		{title: 'Status',  width: '10px'},
			},
		};

	@Input()

		set meter(dis_meter: Meter) {
			this._meter = dis_meter;
			this.dataSrv.listUseageByMeter( this._meter.gid, 'D' ).subscribe(
				(response) => {
					this.detailData = response    ;
					});
			}



	constructor(private dataSrv: DataService) {		}

	ngOnInit() {		}

	onDetailSelect($event) {
		console.log('On Detail Select: ' + $event);
		}


}
