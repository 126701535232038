import { Component, OnInit } 							from '@angular/core';
import { UtilityDetail }								from '../../_models';
import { DataService }									from '../../_services';
import { AuthService } 									from '../../_services';
import { Router }											from "@angular/router";
// import { NgSelectModule }		from '@ng-select/ng-select';//
//import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');
//import "~@ng-select/ng-select/themes/default.theme.css";



@Component({
	selector: 'app-utility-form',
	templateUrl: './utility-form.component.html',
	styleUrls: ['./utility-form.component.scss']
})

export class UtilityFormComponent implements OnInit {
	now:		number;
	deltaT:	number;

	constructor(
		public authService:	AuthService,
		public router: Router,
		private dataSrv:		DataService
		)
		{
		if(!this.authService.isRoleOK("ut" )) {
			window.alert("You are not allowed to access this URL!");
			this.router.navigate(['home'])
			}
       setInterval(() => {
          this.now = Date.now();
          this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
        }, 1000);

		}

	Blankmodel = new UtilityDetail(
		0, '', '', '', '', '', '', '', 0, 0,
		0, 0, 0, 0, 0,	 0,  0, '', 0, 'America/Chicago',
		'', '', 0, 0, 0, 0);
	model = this.Blankmodel;

	submitted = false;
	onSubmit() {
		console.log( this.model );
		this.dataSrv.updateUtility( this.model ).subscribe(
			( data ) => {
			console.log('Sucessful Update');
//			console.log( data );
			this.submitted = true;
			});
		}

	resetScreen()
		{
//		console.log('Reset Screen');
		}

	ngOnInit()
		{
		this.dataSrv.getUtility(
			this.authService.currentUtility ).subscribe( ( data ) => {
				this.model = data;
//				console.log( data );
				});
		}

	}
