export class Location {
	gid:						number;
	cust_gid:				number;

	meter_id:				string;
	meter_gid:				number;
	acct_nbr:				string;
	utility_acct_nbr:    string;
	customer_name:       string;
	date_in:             string;
	date_out:            string;
	st_num:              string;
	st_name:             string;
	building:            string;
	unit:                string;
	city:                string;
	state:               string;
	zip:                 string;
	lat:                 number;
	lon:                 number;
}

