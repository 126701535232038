export class Utility {
	ALP:				number;
	AHP:				number;
	ALT:				number;
	AHT:				number;
	ALF:				number;
	AHF:				number;
	Addr1:			string;
	Addr2:			string;
	Unit:				string;
	City:				string;
	LAT:				number;
	LON:				number;
	LowPwr:			number;
	Protocol:		number;
	RptEndTm:		string;
	RptFreq:			number;
	RptStartTm:		string;
	State:			string;
	SvrIP:			string;
	SvrPort:			number;
	TZ:				string;
	gid: 				number;
	init_zoom:		number;
	logo_link:		string;
	utility_name: 	string;
	ValveLL:			number;
	FlowLow:			number;	// discard these two fields
	FlowHigh:		number;	// discard these two fields
 }
