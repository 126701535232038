import { Component, OnInit } 					from '@angular/core';
import { AuthService }							from '../../_services';
import { DataService }							from '../../_services';
import { Router, NavigationEnd }				from '@angular/router';
import { UiModule } 								from '../ui/ui.module';

@Component({
	selector:		'app-admin',
	templateUrl:	'./admin.component.html',
	styleUrls:		['./admin.component.scss']
})

export class AdminComponent implements OnInit {

	editEmployee 	= false;
	editCtrlPanel	= false;
	editUDPanel		= false;
	flgShowMenu		= true;

	MonitorPanel	= false;
	MonthUse			= false;
	now:		number;
	deltaT:	number;

	constructor
		(
		private dataSrv: DataService,
		public authService: AuthService,
		public router: Router,
		)
		{
		if(!this.authService.isRoleOK("admin" )) {
			window.alert("You are not allowed to access this URL!");
			this.router.navigate(['home'])
			}
		setInterval(() => {
          this.now = Date.now();
          this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
        }, 1000);
		}

	ngOnInit() {
		this.flgShowMenu		= true;
		}

	clickEmployee() {
//		console.log('Employee Clicked');
		this.editEmployee 	= true;
		this.editCtrlPanel	= false;
		this.flgShowMenu		= true;
		this.editUDPanel		= false;
		this.MonthUse			= false;
		this.MonitorPanel		= false;
		}

	clickControl() {
//		console.log('Control Clicked');
		this.editEmployee 	= false;
		this.editCtrlPanel	= true;
		this.flgShowMenu		= true;
		this.editUDPanel		= false;
		this.MonitorPanel		= false;
		this.MonthUse			= false;
		}

	clickUtilityDetail() {
//		console.log('Utility Detail Clicked');
		this.editEmployee 	= false;
		this.editCtrlPanel	= false;
		this.flgShowMenu		= true;
		this.editUDPanel		= true;
		this.MonitorPanel		= false;
		this.MonthUse			= false;
		}

	clickMonitor() {
//		console.log('Monitor Clicked');
		this.editEmployee 	= false;
		this.editCtrlPanel	= false;
		this.flgShowMenu		= true;
		this.editUDPanel		= false;
		this.MonitorPanel		= true;
		this.MonthUse			= false;
		}

	clickMonUse() {
//		console.log('Monitor Clicked');
		this.editEmployee 	= false;
		this.editCtrlPanel	= false;
		this.flgShowMenu		= true;
		this.editUDPanel		= false;
		this.MonitorPanel		= false;
		this.MonthUse			= true;
		}

}


