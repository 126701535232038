import { Component, OnInit, Input, Output, EventEmitter }	from '@angular/core';
import { Observable }					from 'rxjs';
import { DataService }					from '../_services';
import { Location, Meter }				from '../_models';
import { MeterService }					from '../_services';
import { AuthService } 					from '../_services';

@Component({
  selector: 	'part-meter-reading',
  templateUrl: './meter-reading.part.html',
})

export class MeterReadingPart implements OnInit
	{
	_meter:				Meter;
	lastReadDate:		any;
	nextReport:			any;
	AHP:					number;
	AHT:					number;
	ALP:					number;
	ALT:					number;

	dataTemp = [[ 'Temperature', 88]] ;
	titleT = 'Temperature';
	optT		= {width: 400, height: 120,  minorTicks: 5, min: 30, max: 120, redFrom: 30, redTo: 33, 
		greenFrom: 33, greenTo: 115, 
		yellowFrom: 115, yellowTo: 120, };

	dataPres	= [[ 'Pressure', 0]] ;
	titleP = 'Pressure';
	optP		= {width: 400, height: 120,  minorTicks: 5, min:  0, max: 120, 
		redFrom: 0, redTo: 33, 
		greenFrom: 33, greenTo: 115, 
		yellowFrom: 115, yellowTo: 120, };

	dataVolt	= [[ 'CPU V', 0], [ 'RTU V', 0]] ;
	titleV = 'Battery';
	optV		= {width: 400, height: 120,  minorTicks: .5, min:  2.8, max: 3.9, redFrom: 2.8, redTo: 3.0, yellowFrom: 3.0, yellowTo: 3.3, greenFrom: 3.3, greenTo: 3.9, };

	dataSigl	= [[ 'Signal', 0]] ;
	titleS = 'Signal';
	optS		= {width: 400, height: 120,  minorTicks: 5, min:  0, max: 100, redFrom: 30, redTo: 33, greenFrom: 33, greenTo: 115, yellowFrom: 115, yellowTo: 120, };
   gaugeType = 'Gauge';

	@Input()

	set meter(meter: Meter)
		{

		this.optP.redTo		= this.authService.utilityObject.ALP ;
		this.optP.greenFrom	= this.authService.utilityObject.ALP ;		
		this.optP.greenTo		= this.authService.utilityObject.AHP ;
		this.optP.yellowFrom	= this.authService.utilityObject.AHP ;
		
		this.optT.redTo		= this.authService.utilityObject.ALT ;
		this.optT.greenFrom	= this.authService.utilityObject.ALT ;		
		this.optT.greenTo		= this.authService.utilityObject.AHT ;
		this.optT.yellowFrom	= this.authService.utilityObject.AHT ;
		  
		this.dataTemp[0][1] 	= 29.0 ;
		if ( typeof(meter) !== 'undefined' ) 
		{
			this._meter = meter;
			this.dataTemp[0][1] 	= this._meter.Temperature / 1 ;
			this.dataPres[0][1] 	= this._meter.Pressure / 1;
			this.dataVolt[0][1] 	= this._meter.b1v / 10;
			this.dataVolt[1][1] 	= this._meter.b2v / 10;
			this.dataSigl[0][1]  = this._meter.signal / 1 ;

			var d = new Date( this._meter.read_date );
			console.log( this._meter.read_date + "     " + d.toLocaleString() )
			this.lastReadDate = d.toLocaleString();
			this.nextReport = this.meterService.getNextReportDTGString( this._meter );

			let d_str = ( '00000000000' + this._meter.last_reading.toString()).replace(",", "").replace('.','');
			d_str = d_str.substr(-8);
			for (let i = 1; i <= d_str.length; i++) {
				if ( document.getElementById('SMU_' + i))
					{
					document.getElementById('SMU_' + i).innerHTML = d_str.substr(i - 1, 1);
					}
				else
					{
//					console.log("Bad i: " + i);
					}
				}
			}
			console.log( this.optT);

		}

	get meter(): Meter { return this._meter; }

	constructor
		(
			public	meterService:	MeterService,
			private	dataSrv: 		DataService,
			public	authService:	AuthService,
			)
		{
//		console.log("constructor")  ;
		}

	ngOnInit()
		{ 
//		console.log("ngOnInit") ;
		}




}
