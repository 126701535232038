import { AuthService }									from '../../_services';
import { UserService }									from '../../_services';
import { DataService } 									from '../../_services';
import { FirebaseUserModel }							from '../../_models/user.model';

import { ActivatedRoute, Router }					from '@angular/router';
import { Component, OnInit }							from '@angular/core';
import { Location }										from '@angular/common';
import { FormBuilder, FormGroup, Validators } 	from '@angular/forms';
import { UiModule } 										from '../ui/ui.module';
import { Employee, UA }									from '../../_models';
import { Utility }										from '../../_models';

import { NgModule } 										from "@angular/core";
import { platformBrowserDynamic } 					from "@angular/platform-browser-dynamic";
import { BrowserModule } 								from "@angular/platform-browser";
import { FormsModule } 									from "@angular/forms";
import { NgSelectModule } 								from '@ng-select/ng-select';

@Component({
	selector: 'page-user',
	templateUrl: 'user.component.html',
	styleUrls: ['user.scss']
})
export class UserComponent implements OnInit {

	user: FirebaseUserModel = new FirebaseUserModel();
	profileForm: FormGroup;
	addUtilityObj: any;
	defUtilityObj: any;
	flgAddActive = "disabled";
	flgCngDef = "disabled";
	flgRemove = "disabled";

	blankEmp = new Employee;
	model = this.blankEmp;
	UAs = [];
	Utils = [];

	unames = ["Emil", "Tobias", "Linus"];
	addlUtilityName = 'Cedar Hill Public Works';
	currentUtilityName = 'Cedar Hill Public Works';

	flgW: boolean;
	employee: any;
	curIndex = 0;
	Emp: Employee;
	empSD:	any;
	empED:	any;
	addEmp:	boolean;
	date_in:	any;
	date_out: 	any;
	now:			number;
	deltaT:		number;


	constructor(
		public	userService: UserService,
		public	authService: AuthService,
		private	route: ActivatedRoute,
		private	location: Location,
		private	dataSrv: DataService,
		private	fb: FormBuilder,
		public	router: Router
		)
		{
		if(!this.authService.isRoleOK("user" )) {
			window.alert("You are not allowed to access this URL!");
			this.router.navigate(['home'])
			}

		this.route.params.subscribe(
			params => {
				console.log( params );
				if ( typeof params.auth_id  != 'undefined' )
					{
					console.log("Get Employee:" + params.auth_id);
					this.dataSrv.getUserByAuthID( params.auth_id ).subscribe( Result =>
						{
						console.log( Result );
						this.model = Result;
						this.UAs = this.model.utility_array;
						console.log( this.UAs);
						this.currentUtilityName = this.UAs[0].utilityName;
						for (var u in this.UAs)
							{
							console.log( this.UAs[u].utility_gid + "   " + this.model.utility_gid);
							if ( this.UAs[u].utility_gid == this.model.utility_gid )
								{
								this.currentUtilityName = this.UAs[u].utilityName;
								console.log("pick: " + this.currentUtilityName)
								}
							}

						this.empSD = this.model.date_in;
						this.empED = this.model.date_out;
						this.flgW  = (this.model.working == '1');

						//x	 console.log( this.model.utility_array )  ;

						this.flgRemove = "disabled";
// 					this.user = Employee;
// 					this.createForm(this.user.name);
// 					//x	 console.log( "User form created:" + this.user.name)
						});

					//x	 console.log( "Get Utilities");
					this.dataSrv.getUtilities(  ).subscribe( us =>
						{
						//x	 console.log( us );
						this.Utils = us ;
						this.addlUtilityName = us[0].utility_name;
						//x	 console.log(this.addlUtilityName);
						this.flgAddActive = "disabled";
						this.flgCngDef = "disabled";
						});

		   		}
				});

       setInterval(() => {
          this.now = Date.now();
          this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
        }, 1000);


		}

 	ngOnInit(): void
 		{
	 	}

 	createForm(name)
 		{
 		this.profileForm = this.fb.group({
 			name: [name, Validators.required ]
 		});
 		}

 	save(value){
 		this.userService.updateCurrentUser(value)
 		.then(res =>
 			{
 			//x	 console.log(res);
 			},
 		err => console.log(err)
 		)
 	}

	onSubmit( form_editEmployee ) {
		console.log( this.model );
		console.log( form_editEmployee );

		var E = new Employee;
		E['utility_gid']		=	this.model.utility_gid		;
		E['First_Name']		=	this.model.First_Name		;
		E['Last_Name']			=	this.model.Last_Name			;
		E['role']				=	this.model.role				;
		E['date_in']			=	this.empSD			;
		E['date_out']			=	this.empED			;
		E['auth_id']			=	this.model.auth_id			;

		//x	 console.log ( E );
 		this.dataSrv.updateUser( E ).subscribe(
 			( data ) => {
 				 console.log("Sucessful Update");
 				 console.log( data );
 				 console.log( data.SQL);
 			var strUA="";
 			for (var u in this.UAs)
 				{
 				strUA += this.UAs[u].utility_gid + '|';
 				}

 			this.dataSrv.updateUserUA( this.model.auth_id, strUA ).subscribe(
 				( retv ) => {
 					//x	 console.log( retv );
				});
				this.authService.SetUtilityData( this.model.utility_gid );
 			});
		this.router.navigate(['employee'  ]);
		}

	toggleWorking(event) {
		//x	 console.log(event.target.checked);
		this.flgW = event.target.checked;
		//x	 console.log( 'Flag is ' + this.flgW);
		const D = new Date().toJSON();
		const Dn = D.slice(0, 10) + ' ' + D.slice(11, 19);
		if ( !this.flgW ) {
			this.empED = Dn;
			this.empSD = this.model.date_in;
			} else {
			this.empSD = Dn;
			this.empED = '2099-12-31 12:00:00';
			}
		}

	cancelEdit() {
		console.log('Canceled employee edit');
		this.router.navigate(['employee']);
//		console.log("cancel edit")
//		this.dataSrv.getUsersByUtility(  ).subscribe(
//			(dataAry:  Array<object>) => {
//				console.log('Canceled employee ass/edit');
//				console.log( dataAry );
//				this.employee = dataAry;
//				});
		}

	newPassword() {
		//x	 console.log('New Password');
		}

addUtility()
	{
	console.log( this.addUtilityObj );
	this.addlUtilityName = this.addUtilityObj.utility_name
	this.UAs.push( this.addUtilityObj );
	this.flgAddActive = "disabled";
	this.flgCngDef = "disabled";
	console.log( this.UAs );
	}

remUtility()
	{
	//x	 console.log("Rem")
	this.flgCngDef = "disabled";
	this.flgRemove = "disabled";
	}

cngDefUtility()
	{
	//x	 console.log("Change Default Utility");
	//x	 console.log( this.model.utility_gid )
	this.flgCngDef = "disabled";
	this.flgRemove = "disabled";
	}

pickDefUtility( selGid )
	{
	//x	 console.log("Change Default Utility");
	//x	 console.log( this.model.utility_gid )
	//x	 console.log( selGid );
	for (var u in this.Utils)
		{
		if ( this.Utils[u].gid == selGid )
			{
			//x	 console.log( this.Utils[u] );
			//x	 console.log( this.Utils[u].utility_name ) ;
			var X = new UA;
			X.utility_gid = this.Utils[u].gid;
			X.utilityName = this.Utils[u].utility_name;
			X.utilityLogo = this.Utils[u].logo_link;
			this.currentUtilityName = X.utilityName;
			this.flgCngDef = "";
			this.flgRemove = "";
         this.defUtilityObj = X;
			//x	 console.log( this.defUtilityObj );
			}
		}

	}

pickAddlUtility( selGid )
	{
	//x	 console.log( selGid );
	for (var u in this.Utils)
		{
		if ( this.Utils[u].gid == selGid )
			{
			//x	 console.log( this.Utils[u] );
			//x	 console.log( this.Utils[u].utility_name ) ;
			var X = new UA;
			X.utility_gid = this.Utils[u].gid;
			X.utilityName = this.Utils[u].utility_name;
			X.utilityLogo = this.Utils[u].logo_link;
         this.addUtilityObj = X;
			this.flgAddActive = "";
			//x	 console.log( this.addUtilityObj );
			}
		}

	}


}
