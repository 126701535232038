<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<a  [routerLink]="['/pick-utility']" >
		<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />
		</a>

	<a [routerLink]="['/home']"
		style="position:absolute;left:10%">
		<i class="material-icons">home</i>
		Home
		</a>

	<a [routerLink]="['/employee']"
		style="position:absolute;left:20%">
		<i class="material-icons">people</i>
		Employee
		</a>

	<a [routerLink]="['/utility']"
		style="position:absolute;left:33%">
		<i class="material-icons">store</i>
		Utility
		</a>

	<a [routerLink]="['/mpanel']"
		style="position:absolute;left:43%">
		<i class="material-icons">report_problem</i>
		Monitor
		</a>

	<a [routerLink]="['/reports']"
		style="position:absolute;left:55%">
		<i class="material-icons">receipt</i>
		Reports
		</a>

	<a [routerLink]="['/install']"
		style="position:absolute;left:65%">
		<i class="material-icons">build</i>
		Install
		</a>

	<a (click)="authService.SignOut()" style="position:absolute;left:95%">
		<i class="material-icons">logout</i>
		</a>

	</nav>

<div class=cm_usec *ngIf="editEmployee">
	<app-employee ></app-employee>
	</div>

<div class=cm_usec *ngIf="editCtrlPanel">
	<app-utility-form  ></app-utility-form>
	</div>

<div class=cm_usec *ngIf="MonitorPanel">
	<app-monitor-panel  ></app-monitor-panel>
	</div>

<div class=cm_usec *ngIf="MonthUse">
	<app-monthy-useage  ></app-monthy-useage>
	</div>


