import { Component, OnInit }								from '@angular/core';
import { DataService }										from '../../_services';
import { Customer, Location, Meter }					from '../../_models';
import { ActivatedRoute }									from '@angular/router';
import { Router } 											from '@angular/router';
import { UiModule } 											from '../ui/ui.module';

@Component({
	selector:		'app-customer',
	template:
		`<style>
			:host /deep/ ng2-smart-table {
   	 		font-size: 16px;
				}
			div.cust_info		{position:absolute; top:  70px; left:  10px;width: 998px; height:90px; border:1px solid black;}
			div.title_ln		{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}
			div.g_uacth 		{position:absolute; top:  35px; left:   5px;text-align: left}
			div.g_uact			{position:absolute; top:  35px; left:  60px;text-align: left;}
			div.g_nam			{position:absolute; top:  35px; left: 190px;text-align: left;}
			div.g_phn			{position:absolute; top:  60px; left: 190px;text-align: left;}
			img.btn_RptB		{position:absolute; top:   1px; left:  10px;width:  40px; height: 40px; }
			div.cust_locn_info{position:absolute; top: 160px; left:  10px;width: 998px; height:875px; border:1px solid  black;}
		</style>

		<app-header>
			</app-header>


		<div class=cust_info >
			<div class=title_ln >Billing Account Information</div>
			<div class=g_nam>{{ _customer.customer_name }}</div>
			<div class=g_phn>{{ _customer.phone_number  }}</div>
			<div class=g_uacth>Acct</div>
			<div class=g_uact>{{ _customer.customer_acct_nbr }}</div>
			<img	class=btn_RptB
				SRC="https://stoshb.info/assets/img/icons8-business-report-48.png "
				title="Print Billing Account Report"  (click)="printReport(1)" >
			</div>

		<div class=cust_locn_info >
			<div class=title_ln >Service Locations</div>
			<div class=list_info >
			<ng2-smart-table
				[settings]		=	"settings"
				[source]			=	"_location"
				(userRowSelect)=	"onUserRowSelect($event)"
				>
				</ng2-smart-table>
				</div>
			</div>

		`
	})

export class CustomerComponent implements OnInit {

	settings = {
		pager:	{
			display: true,
			perPage: 18,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			acct_nbr:			{title: 'Acct', 		width: '100px'},
			st_num:           {title: 'StNbr',		width: '100px'},
			st_name:          {title: 'Street Name', width: '700px'},
			unit:             {title: 'Unit', 		width: '100px'},
			city:					{title: 'City', 		width: '100px' },
			meter_id:         {title: 'Meter', 		width: '100px'},
			}
		};



	_customer =	new Customer();
	_location:	Location[];

	custGid:	number;
	meterGid: number;
	specifiedLocn: Location;
	specifiedMeter: Meter;

	constructor(
		private route: ActivatedRoute,
		private dataSrv: DataService,
		private router: Router
		)
		{
		this.route.params.subscribe(
			params =>
				{
				if ( typeof params.c_gid  != 'undefined' )
					{
					this.custGid  = params.c_gid;
		   		this.dataSrv.getCustomerByGid( this.custGid ).subscribe(
						(data) => {
						this._customer = data['customer'];
						const locn_array = data['composit'];
						this._location = [];

						let irt = 0;
						locn_array.forEach(composit => {
							composit.locn.irt = irt++;
							this._location.push(composit.locn);

						   });

					   this.specifiedLocn = locn_array[0].locn;

						if  ( this._location.length == 1 )
							{
							this.router.navigate(['/locn/' + this._location[0].gid  ]);
							}


		   			});
		   		}

				});
		}

	ngOnInit() {
		}

	public  getCustomer( custName )
		{
		this.dataSrv.getCustomerByName( custName ).subscribe(
			(data) => {
				this._customer = data;
				});
		}

	onUserRowSelect(event: any)
		{
		const locn_gid = this._location[ event.data.irt ].gid;
		this.router.navigate(['/locn/' + locn_gid  ]);
		}

	printReport(param: any)
		{
		alert("Customer Account Report\nPrint Server Not Operational");
		const ReportID = ['BJghjNZzVH', 'BJghjNZzVH'];
		console.log( "Print customer account report");
		this.dataSrv.useJsReportByGid( ReportID[ param ], this._customer.gid, 'Customer Snapshot', 'No comment'  ).subscribe(
			( rptData ) =>
				{
				console.log("Back from report");
				console.log( rptData );
				});
		}


	}

