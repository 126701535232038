import { Component, Input, OnInit }	from '@angular/core';
import { DataService }					from '../_services';
import { Customer }						from '../_models';

@Component({
	selector:		'part-cus',
	template:	
	`<style>	
		div.p_cust_info	{width: 489px; height:115px; border:1px solid black;}
		div.p_title_ln		{position:absolute; top:   3px; width:100%; height:25px; text-align:center;font-size:24px;}
		div.p_nam			{position:absolute; top:  35px; width:100%; height:25px; text-align:center;font-size:18px;}
		div.p_uacth 		{position:absolute; top:  60px; left:  10px; height:25px; text-align:left;font-size:18px;}
		div.p_uact			{position:absolute; top:  60px; width:100%; height:25px; text-align:center;font-size:18px;}
		div.p_phn			{position:absolute; top:  85px; width:100%; height:25px; text-align:center;font-size:18px;}	
		img.btn_CusRpt		{position:absolute; top:   1px; left:  10px;width:  40px; height: 40px; }
		</style>

	<div class=p_cust_info >
		<div class=p_title_ln >Billing Account Information</div>
		<div class=p_nam>{{ _customer.customer_name }}</div>
		<div class=p_phn>{{ _customer.phone_number  }}</div>
		<div class=p_uacth>Acct</div>
		<div class=p_uact>{{ _customer.customer_acct_nbr }}</div>
		<img	class=btn_CusRpt
			SRC="https://stoshb.info/assets/icons8-business-report-48.png"
			title="Print Billing Account Report"  (click)="printReport(1)" >
		</div>`
	})

export class CusPart implements OnInit
	{
	_customer:			Customer;

	@Input()
	set customer(customer: Customer)
		{
		this._customer = customer;
		}

	constructor
		(
		private	dataSrv:	DataService,
		)
		{		}

	ngOnInit()
		{		}

	printReport(param: any)
		{
		alert("Print Customer Report\nPrint Server Not Operational");
		const ReportID = ['BJghjNZzVH', 'BJghjNZzVH'];
		console.log( "Print customer account report");
		this.dataSrv.useJsReportByGid( ReportID[ param ], this._customer.gid, 'Customer Snapshot', 'No comment'  ).subscribe(
			( rptData ) =>
				{
				console.log("Back from report");
				console.log( rptData );
				});
		}


}
