import { Component, OnInit } 			from '@angular/core';
import { UiModule } 						from '../ui/ui.module';
import { DataService }					from '../../_services';
import { AuthService } 					from '../../_services';

@Component({
	selector: 		'app-pick-utility',
	template:
	`<style>
		div.cust_info		{position:absolute; top:  50px; left:  10px;width: 998px; height:90px; border:1px solid black;}
		div.title_ln		{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}
		div.g_uacth 		{position:absolute; top:  35px; left:   5px;text-align: left}
		div.g_uact			{position:absolute; top:  35px; left:  60px;text-align: left;}
		div.g_nam			{position:absolute; top:  35px; left: 190px;text-align: left;}
		div.g_phn			{position:absolute; top:  60px; left: 190px;text-align: left;}
		div.cust_locn_info1{position:absolute; top: 140px; left:  10px;width: 998px; height:875px; border:1px solid  black;}
		div.cust_locn_info2{position:absolute; top: 140px; left:  10px;width: 998px; height:445px; border:1px solid  black;}
		div.list_info		{position:absolute; top:  20px; left:    0px;width: 988px; height:625px; }

		img.btn_RptB		{position:absolute; top:   1px; left:  10px;width:  40px; height: 40px; }
		</style>

	<app-header>
			</app-header>

	<div class=ctrlPanel >

		<div class=search_nameh >This user can work with these utiites.  Click on one to change current selection.</div>

		<ng2-smart-table
			[settings]		=	"matchedUtilities"
			[source]		=	"utilities"
			(userRowSelect)=	"onRowSelect($event)"
			>
			</ng2-smart-table>
		</div>`
})

export class PickUtilityComponent implements OnInit {

	matchedUtilities = {
		hideSubHeader:	true,
		pager:	{
			display: true,
			perPage: 18,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
//			gid: 				{title: 'gid'},
//			utilityLogo: 	{title: 'Logo'},<img src="https://stoshb.info/assets/{{util.logo_link}}" width="20px" height="20px"/>


			utilityLogo:	{title: 'Logo',	 type: 'html', valuePrepareFunction:
				function(value) {
					return '<img src="https://stoshb.info/assets/' + value + '" width="60px" height="40px"/>';
				}},

			utilityName:   {title: 'Name' },

			}
		};

	utilities:  Array<object> = [];

	constructor
		(
		public authService: AuthService,
		private dataSrv: DataService,
		)
		{}

	ngOnInit(): void
		{
		this.utilities = this.authService.Emp_Utility_Array
		}

	onRowSelect(event: any)
		{
		const Employee = JSON.parse(localStorage.getItem('employee'));
		Employee.utility_gid = event.data.utility_gid;
		var d = new Date();
		Employee.time_last_auth = d.getTime();
		localStorage.setItem('employee', JSON.stringify(Employee));

		this.dataSrv.updateCurrentUtility( event.data.utility_gid ).subscribe( Result =>
			{
//			console.log( Result );
			})
		this.authService.SetUtilityData( event.data.utility_gid );
		}

}
