<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<a  [routerLink]="['/pick-utility']" >
		<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />
		</a>
					
	<a [routerLink]="['/home']" 	 >
		<i class="material-icons">home</i>
		Home
		</a>

<span  style="position:absolute;left:75%">
	{{ now | date:'dd/MM/yyyy HH:mm:ss'}}
	<p>
	{{ authService.currentUserName }} On for {{ deltaT  }}  Min
	</p>
</span>


	<a (click)="authService.SignOut()" style="position:absolute;left:95%">
		<i class="material-icons">logout</i>
		</a>

	</nav>


<div class=box  >
		<h1>Edit Employee Information</h1>
		<form #editEmployee="ngForm" (ngSubmit)="onSubmit(editEmployee)"  >

			<div class="form-group fname">
				<label for="First_Name     ">Employee Name</label>
				<input type="text" class="form-control fname" id="First_Name      " required
					[(ngModel)]="model.First_Name"   name="First_Name" #First_Name="ngModel">
				<div [hidden]="First_Name.valid || First_Name.pristine" class="alert alert-danger">First name is required</div>
				<input type="text" class="form-control lname" id="Last_Name      " required [(ngModel)]="model.Last_Name"   name="Last_Name" #Last_Name="ngModel">
				<div [hidden]="Last_Name.valid || Last_Name.pristine" class="alert alert-danger">Last name is required</div>
				</div>

<!--
			<div class="form-group email">
				<label for="email     ">Email Address</label>
				<input type="text" class="form-control email"
					id="email_addr      " required [(ngModel)]="model.email_addr"
					name="email_addr" #email_addr="ngModel">
				<div [hidden]="email_addr.valid || email_addr.pristine"
					class="alert alert-danger">Email address is required</div>
				</div>
-->
			<div class="form-group working">
				<input class="flgW" type="checkbox" [(ngModel)]="flgW"
					[ngModelOptions]="{standalone: true}"
					data-md-icheck (change)="toggleWorking($event)"
					/>

				<label class="flW" >Currently
					<span *ngIf="flgW">Working</span>
					<span *ngIf="!flgW">Not Working</span>
					<span > Active Between {{ empSD }} and  {{ empED  }}</span>
					</label>

				</div>
<!--
			<div class="form-group">
				<label for="curUtil">Default Utility</label>
				<select class="form-control" id=curUtil
					required
					[(ngModel)]="model.utility_gid" name="currentUtilityName">
        			<option *ngFor="let util of UAs" [value]="util.utility_gid"
						[selected]="model.utility_gid == util.utility_gid">
						<img src="https://stoshb.info/assets/{{util.logo_link}}" width="20px" height="20px"/>
          			{{ util.utilityName }}
       				</option>
      			</select>
      		</div>
-->


<label class=curU>Default Utility</label>
<div class=curU>
<ng-select name="curU"  class="custom" data-ng-attr-size="5"
   [(ngModel)]="currentUtilityName"
	(change)	= "pickDefUtility($event)"
	>

	<ng-option [value]="util.utility_gid"
			*ngFor="let util of UAs">
		<img src="https://stoshb.info/assets/{{util.utilityLogo}}" width="20px" height="20px"/>
		{{util.utilityName}}
		</ng-option>
	</ng-select>
</div>

<label class=allU>All Utilities</label>
<div class=allU>
<ng-select name="whyM"  class="custom" data-ng-attr-size="5"
   [(ngModel)]="addlUtilityName"
	(change)	= "pickAddlUtility($event)"
	>

	<ng-option [value]="util.gid"
			*ngFor="let util of Utils">

		<img src="https://stoshb.info/assets/{{util.logo_link}}" width="20px" height="20px"/>
		{{util.utility_name}}
		</ng-option>
	</ng-select>
</div>



<div class=btns>
			<button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
			<button type="button" class="btn btn-default" (click)="newPassword()">New Password</button>
			<button type="button" class="btn btn-link" disabled="{{flgAddActive}}"
				(click)="addUtility()">Add Utility</button>
			<button type="button" class="btn btn-link" disabled="{{flgRemove}}"
				(click)="remUtility()">Remove Utility</button>
			<button type="button" class="btn btn-link" disabled="{{flgCngDef}}"
				(click)="cngDefUtility()">Change Default</button>
			<button type="submit" class="btn btn-success" [disabled]="!editEmployee.form.valid">Submit</button>
</div>
		</form>
	</div>

