import { Component, OnInit, ViewChild, NgZone  	}	from '@angular/core';
import { ActivatedRoute, Router, RouterModule	}	from '@angular/router';
import { DataService, MapService 					}	from '../../_services';
import { AuthService 									}	from '../../_services';
import { Location, Utility, Meter, Customer 		}	from '../../_models';
import { MapsAPILoader, AgmMap 						}	from '@agm/core';
import { GoogleMapsAPIWrapper 						}	from '@agm/core/services';
import { UiModule 										} 	from '../ui/ui.module';

interface Marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

@Component(
	{
	template:	`
	<app-header></app-header>

	<part-home-buttons></part-home-buttons>
	`,
	})

export class HomeComponent implements OnInit
	{

	constructor(
		)
		{
//		console.log( "home contructor")
		}

	ngOnInit()
		{
		}

	}

