export class Employee
	{
	gid!:						number;
	utility_gid!:			number;
	password!:				string;
	First_Name!:			string;
	Last_Name!:				string;
	role!:					string;
	role_array!:			RA[];
	date_in!:				string;
	date_out!:				string;
	email_addr!:			string;
	token!:					string;
	working!:				string;
	auth_id!:				string;
	utility_array!:		UA[];
	avail_util!:			string;
	time_last_auth!:		number;
	}

export class UA
	{
	utility_gid!:			number;
	utilityName!:			string;
	utilityLogo!:			string;
	}

export class RA
	{
	t:		string;			// Role type
	g:		number;			// Valid for utility  -1 = all
	v:		number;			// 0 = no, 1 = yes
	}
