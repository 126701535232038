import { Component, OnInit }				from '@angular/core';
import { DataService, AuthService }		from '../../../_services';
import { Router, NavigationEnd }			from '@angular/router';
//import { mdiAccountDetails } 			from '@mdi/js';
import { UserInfoPart }						from '../../../parts';

@Component({
	selector: 'app-header',
	template:
	`<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="authService.isLoggedIn">

		<a  [routerLink]="['/pick-utility']" >
			<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />
			</a>

		<a [routerLink]="['/home']" 	 >
			<i class="material-icons">home</i>
			Home
			</a>

		<a  [routerLink]="['/csr']" style="position:absolute;left:25%">
			<i class="material-icons">search</i>
			Search
			</a>

		<a [routerLink]="['/admin']" *ngIf="authService.isRoleOK( 'admin' )" style="position:absolute;left:40%">
			<i class="material-icons">perm_data_settings</i>
			Admin
			</a>

		<a (click)="authService.SignOut()" style="position:absolute;left:95%">
			<i class="material-icons">logout</i>
			</a>
</nav>`,

})

export class HeaderComponent implements OnInit {

	ScreenName	= 'This is a screen';
	UtilityName	= 'Nebula - TOF Co.';
	Logo	= 'assets/img/dallas_water_logo.png';

	constructor(
		private dataSrv: DataService,
		private router: Router,
		public authService: AuthService,
		) {
		}


  ngOnInit()
  	{
  	console.log("hi");
	}

}
