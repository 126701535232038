import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template:
	`
	<script>
		img.img_google {}
		</script>
	<nav class="navbar fixed-bottom navbar-light bg-light mt-5 ">
		<div class="navbar-expand m-auto navbar-text">
<!-->			Copyright &copy; 2017, 2018, 2019, 2020, 2022, 2023 by Kaunas Systems, Inc. -->
				Nebula Water Meter Company
			</div>
<!--		<img class=img_google src="assets/img/GoogleCloud.png" width=50px height=50px /> -->
		</nav>`
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
