import { Component, OnInit, Input }	from '@angular/core';
//import { Observable }			from 'rxjs';
import { Meter }							from '../_models';

@Component({
	selector: 	'part-meter',
	template: 
	`<style>
		div.mp_box			{width: 489px; height:215px;  border:1px solid  black;}
		div.mp_title_ln	{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}	
		div.mp_IDh	      {position:absolute; top:  30px; left:   0px; }
		div.mp_ID	      {position:absolute; top:  30px; left: 115px; }	
		div.mp_imcidh		{position:absolute; top:  55px; left:   0px;text-align: left; width:100px}
		div.mp_imcid 		{position:absolute; top:  55px; left: 115px;text-align: left; width:150px}
		div.mp_sieih		{position:absolute; top:  80px; left:   0px;text-align: left; width:100px}
		div.mp_sid			{position:absolute; top:  80px; left: 115px;text-align: left; width:400px}
		div.mp_eid			{position:absolute; top:  80px; left: 300px;text-align: left; width:400px}	
		div.mp_pdteh		{position:absolute; top: 105px; left:   0px;text-align: left; width:100px}
		div.mp_pdte 		{position:absolute; top: 105px; left: 115px;text-align: left; width:300px}
		div.mp_pplch		{position:absolute; top: 105px; left: 260px;text-align: left; width:100px}
		div.mp_pplc 		{position:absolute; top: 105px; left: 365px;text-align: left; width:300px}
		div.mp_swrvh		{position:absolute; top: 130px; left:   0px;text-align: left; width:100px}
		div.mp_swrv 		{position:absolute; top: 130px; left: 115px;text-align: left; width:300px}
		div.mp_mtyh		   {position:absolute; top: 130px; left: 260px;text-align: left; width:100px}
		div.mp_mty 		   {position:absolute; top: 130px; left: 365px;text-align: left; width:300px}	
		div.mp_modmh 		{position:absolute; top: 155px; left:   0px;text-align: left; width:300px}
		div.mp_modm		   {position:absolute; top: 155px; left: 115px;text-align: left; width:100px}
		div.mp_modlh 		{position:absolute; top: 155px; left: 260px;text-align: left; width:300px}
		div.mp_modl 		{position:absolute; top: 155px; left: 325px;text-align: left; width:300px}
		div.mp_frmwh		{position:absolute; top: 180px; left:   0px;text-align: left; width:100px}
		div.mp_frmw 		{position:absolute; top: 180px; left: 115px;text-align: left; width:300px}	
	</style>

	<div class=mp_box *ngIf="_meter">		
		<div class=mp_title_ln>Meter Detail</div>
		<div class=mp_IDh 	>Meter&nbsp;ID</div>
		<div class=mp_ID    	>{{ _meter.meter_id }}	</div>
		<div class=mp_imcidh>ICCID</div>
		<div class=mp_imcid  >{{ _meter.ICCID }}</div>
		<div class=mp_sieih  >IMSI/IMEI</div>
		<div class=mp_sid  >{{ _meter.IMSI }}/ </div>
		<div class=mp_eid  >{{ _meter.IMEI }}</div>
		<div class=mp_pdteh>Prod Date</div>
		<div class=mp_pdte  >{{ _meter.production_date }}</div>
		<div class=mp_pplch>Prod Place</div>
		<div class=mp_pplc  >{{ this.ProdPlace[ _meter.production_place] }}</div>
		<div class=mp_swrvh>Software&nbsp;Ver</div>
		<div class=mp_swrv  >{{ _meter.software_version }}</div>
		<div class=mp_mtyh>Meter Type</div>
		<div class=mp_mty  >{{ _meter.meter_type}}</div>
		<div class=mp_modmh>Mod.&nbsp;Manuf</div>
		<div class=mp_modm  >{{ ModManufacturer[_meter.ModManuf] }}</div>
		<div class=mp_modlh>Model</div>
		<div class=mp_modl  >{{ _meter.ModModel }}</div>
		<div class=mp_frmwh>Firmware</div>
		<div class=mp_frmw  >{{ _meter.ModFirmware }}</div>
		</div>
	`,
})

export class MeterPart implements OnInit
	{
	_meter:				Meter;
	ProdPlace			=	['Unknown', 'ITL',     'Client'];
	ModManufacturer 	=	['Unknown', 'Quectel', 'Nebula'];


	@Input()

	set meter(meter: Meter)
		{
		if ( typeof(meter) !== 'undefined' ) {
			this._meter = meter;
			}
		}

	get meter(): Meter { return this._meter; }

	constructor()
		{
		}

	ngOnInit()
		{
		}

}
