import { Component, OnInit, Input, Output, EventEmitter }	from '@angular/core';
import { Observable }					from 'rxjs';
import { DataService }					from '../../_services';
import { Location, Meter }				from '../../_models';
import { Router } 						from '@angular/router';
import { ModalService }					from '../../_services';
import { AuthService } 					from '../../_services';
import { MeterService }					from '../../_services';

@Component({
	selector: 	'meter-ctrl',
	templateUrl: './meter-ctrl.component.html',
	styleUrls: [	'./meter-ctrl.component.scss']
})

export class MeterCtrlComponent implements OnInit {
	_meter:		Meter;

	ValveOn: 						boolean;
	ValveOff:						boolean;
	flg_valve_change_status:	boolean;
	valve_change_status_pink:	boolean;
	valve_change_status_red: 	boolean;
	valve_change_status_green: boolean;
	valve_change_status_lime: 	boolean;
	valveInError: 					boolean;
	valve:							number;
	flg24:							boolean;
	UtilityRptFreq					 = 3000;
	fastFlag:						boolean;
	normFlag:						boolean;
	nextReport:						any;

	@Input()

		set meter(meter: Meter) {
			if ( typeof(meter) !== 'undefined' ) {
				this._meter = meter;
//				console.log( meter.read_date );
				this.refreshScreen();
	  			}
			}

	get meter(): Meter { return this._meter; }

	constructor(
		private dataSrv: DataService,
		private router: Router,
		private modalService: ModalService ,
		public authService: AuthService,
		public meterService: MeterService,
		) {		}

	ngOnInit() {
		this.UtilityRptFreq = this.authService.utilityObject.RptFreq;
		}

	valveClicked( position: number ) {
		console.log ( 'Check to make sure we are not already changing valve position' );
		if ( ! this.flg_valve_change_status ) {
// 			console.log ( "Click valve to : " + position );
			this._meter.valve = position;
			this.dataSrv.updateValve( this._meter.gid, position ).subscribe(
				( meter ) => {
//				console.log('Returned from updateValve');
// 				console.log( meter );
				this._meter = meter;

				let val = 0;
				this.flg_valve_change_status = true ;
				if ( this.ValveOn ) {
					val = 0;
					this.ValveOff = true;
					this.ValveOn  = false;
					} else {
					val = 1;
					this.ValveOff = false;
					this.ValveOn ; true;
					}
				this.refreshScreen();
//				console.log(' Now tell server to reload meter info.');
				}
			); }
		}

	setLifeLine = function(Flag)
		{
		this.dataSrv.setLifeLine( this._meter.gid, Flag ).subscribe(
			( meter ) => {
			if ( this.ValveOn )
				{
				this.dataSrv.updateValve( this._meter.gid, 1 ).subscribe(
					( meter ) => {
					this._meter = meter;
					this.refreshScreen();
				});
				}
			else
				{
				this._meter = meter;
				this.refreshScreen();
				}
			})
		}

	abnormalMeter = function (flag)
		{
		console.log( "Abnormal meter: " + this._meter.ErrStatus );
		this.modalService.open('custom-modal-1');
		}

	printReport(param: any)
		{
		alert("Print Meter Report\nPrint Server Not Operational");
		const ReportID = ['Hylyi3eyLS', 'HJxdvJeV8B'];
		console.log( "Print meter report");
		this.dataSrv.useJsReportByGid( ReportID[ param ], this._meter.gid, 'Test Subject', 'Test Comment'  ).subscribe(
			( rptData ) =>
				{
				console.log("Back from report");
				console.log( rptData );
				});
		}

	resetMeter (position: any)
		{
//		this.dataSrv.updateCmdReset( this._meter.gid ).subscribe(
		this.dataSrv.requestGPS( this._meter.gid ).subscribe(
			( meter ) => {
			this._meter = meter;
			this.refreshScreen();
			});
		}

	refreshScreen() {
		this.ValveOn						= ( this._meter.valve > 10 ) || ( this._meter.valve == 1 );
		this.ValveOff						= (this._meter.valve <= 10) && ( this._meter.valve != 1 );
		this.flg_valve_change_status	= (this._meter.parm_flag & 0x8) ? true : false;
		this.valve_change_status_red		= false ;
		this.valve_change_status_pink		= false ;
		this.valve_change_status_green	= false ;
		this.valve_change_status_lime		= false ;
		if ( this.ValveOn ) {
			this.valve_change_status_green	= !this.flg_valve_change_status ;
			this.valve_change_status_lime		= this.flg_valve_change_status ;
			} else {
			this.valve_change_status_red		= !this.flg_valve_change_status ;
			this.valve_change_status_pink		= this.flg_valve_change_status ;
			}
		this.flg24									= (this._meter.cnt_24 > 0) ;
		this.fastFlag = this._meter.RptFreq == 300 ;
		this.normFlag = (this._meter.RptFreq == this.authService.utilityObject.RptFreq );
		this.nextReport = this.meterService.getNextReportDTG( this._meter ).toLocaleString();
		}

	start24 = function ()
		{
		const numTicks = 24 * 4 + 10;
		this.dataSrv.updateCnt24( this._meter.gid, numTicks, 1200 ).subscribe(
			( meter ) => {
			this._meter = meter;
			this.refreshScreen();
			});
		};

	testMeter = function() {
		this.dataSrv.commandTest( this._meter.gid ).subscribe(
			( meter ) => {
			this._meter = meter;
			this.refreshScreen();
			});
		};

	buttonSpeed = function ( speed: any) {
		let RptFreq = this.authService.utilityObject.RptFreq;
		if ( speed == 'F' ) {
			RptFreq = 300;
			}
		if ( speed == 'N' ) {
			RptFreq = this.authService.utilityObject.RptFreq;
			}
		this.dataSrv.updateRptFreq( this._meter.gid, RptFreq ).subscribe(
			( meter ) => {
			this._meter = meter;
			this.refreshScreen();
			});
		};

}
