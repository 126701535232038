export class Meter {
	gid:						number;
	meter_id:				string;

	on_line:					number;
	last_reading:			number;
	read_date:				string;

	cust_gid:				number;
	locn_gid:				number;

	acct_nbr:				string;
	utility_acct_nbr:    string;
	customer_name:       string;
	date_in:             string;
	date_out:            string;
	st_num:              string;
	st_name:             string;
	building:            string;
	unit:                string;
	city:                string;
	state:               string;
	zip:                 string;
	lat:                 number;
	lon:                 number;
	valve:					number;
	valve_change_status:	number;
	valve_test_count:		number;
	parm_flag:				number;

	Temperature:			number;
	Pressure:				number;
	b1v:						number;
	b2v:						number;
	signal:					number;
	RptFreq:					number;
	cnt_24:					number;
	paramDetail:			string;
	IMSI:						string;
	ICCID:					string;
	IMEI:						string;
	ModModel:				string;
	ModFirmware:			string;
	production_place:		string;
	production_date:		string;
	software_version:		string;
	meter_type:				string;
	ModManuf:				string;

	valve_lifeline:		number;
	ErrStatus:				number;
	CurrentReportGid:		number;
}

