import { Component, Input, OnInit, Output }		from '@angular/core';
import { ActivatedRoute	}								from '@angular/router';
import { DataService }									from '../../_services';
import { Location, Meter }								from '../../_models';
import { Customer }										from '../../_models';
import { AuthService }									from '../../_services';
import { Observable }									from 'rxjs';
import { CusPart, LocnPart, LocnMapPart } 		from '../../parts';
import { MeterPart, NoMeterPart } 					from '../../parts';
import { CommonModule } 								from '@angular/common';
import { MeterCtrlComponent } 						from '../meter-ctrl';

@Component({
	selector:		'loc-comp',
	templateUrl:	'./loc-comp.component.html'
	})

export class LocComponent implements OnInit
	{
	_location:			Location;
	_meter:				Meter;
	_customer:			Customer;
	meter_gid:			number;
	locn_gid:			number

	@Input()
		set meter(meter: Meter)
			{
			if ( typeof(meter) !== 'undefined' ) {
				if (( typeof(this._meter ) === 'undefined' ) || (this._meter.gid != meter.gid)) {
					this._meter = meter;
//					console.log( "loc-comp -1: " + meter.read_date );

			var d = new Date( this._meter.read_date );
			console.log( this._meter.read_date + "     " + d.toLocaleString() )

					if ( typeof(this._location ) !== 'undefined' ) { this.doMyThing(); }
 	   			}
				}
			}

	@Input()
	set location(location: Location)
		{
		if ( typeof(location) !== 'undefined' ) {
			if (( typeof(this._location ) === 'undefined' ) || ( this._location.gid != location.gid)) {
				this._location = location;
//				console.log( "loc-comp -2: " + this._meter.read_date );
				if ( typeof(this._meter ) !== 'undefined' ) { this.doMyThing(); }
 				}
			}
		}

	get location(): 
		Location { 
//			console.log(this._location);
			return this._location; 
			}

	constructor
		(
		private	dataSrv:	DataService,
		private route: ActivatedRoute,
		public authService: AuthService,
		)
		{
		this.route.params.subscribe(
			params =>
				{
//				console.log(params);
				if ( typeof params.l_gid  != 'undefined' )
					{
					this.locn_gid  = params.l_gid;
		   		this.dataSrv.getLocnByLocnGid( params.l_gid ).subscribe(
						(data) => {
						this._location = data['locn'];
						this._customer = data['customer'];
						this._meter = data['meter'];
						
			var d = new Date( this._meter.read_date );
			console.log( this._meter.read_date + "     " + d.toLocaleString() )						
						
						this.doMyThing();
		   			});
		   		}
				});

		}

   showOrderMeter =	false;
   showMeterOrdered = false;
   showMeterData =	false;
   showMeterCtrl =	false;
   showMeterHistory = false;
	historyData:		Location[];
	historySettings =
		{
		hideSubHeader:	true,
		pager:	{ perPage:	3, },
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			meter_id:	{title: 'Meter ID',	width: '200px'},
			on_line:		{title: 'On/Line',   width: '80px'},
			date_in:		{title: 'Date In', 	width: '200px'},
			date_out:	{title: 'Date Out', 	width: '200px'},
			}
		};

	orderMeter()
		{
		this.dataSrv.orderMeter( this._location.gid, 'NOW' ).subscribe(
			(obj ) => {
			this.showOrderMeter = false;
			this.showMeterOrdered = true;
			this.showMeterHistory = true;
			this.dataSrv.getMetersForLocn( this._location.gid ).subscribe(
				(data ) => {
				this.historyData = data    ;
				});
			});
		}

	doMyThing()
		{
//		console.log(this._location);
		if ( this._location.meter_id == 'No Meter') {
			this.showOrderMeter = true;
   		this.showMeterData = false;
   		this.showMeterCtrl =	false;
   		this.showMeterOrdered = false;
			}
		else if ( this._meter.meter_id == 'On Order') {
			this.showOrderMeter = false;
   		this.showMeterData = false;
   		this.showMeterCtrl =	false;
   		this.showMeterOrdered = true;
   		this.showMeterHistory = true;
			}
		else {
			this.showOrderMeter = false;
   		this.showMeterOrdered = false;
	   	this.showMeterData = true;
	   	this.showMeterCtrl =	this.authService.isRoleOK("mc");
      	}

		this.dataSrv.getMetersForLocn( this._location.gid ).subscribe(
			(data ) => {
			this.historyData = data    ;
			this.showMeterHistory = true;
			});
		}

	refreshScreen()
		{
		console.log('refresh data for meter: ' + this._meter.gid);
		}

	ngOnInit()
		{
		}

	onUserRowSelect(event: any)
		{
		console.log('Select: '  + event.data.gid + ' isSelected: ' + event.isSelected );
		console.log( event );
		console.log( event.data );
		}

	printReport(param: any)
		{
		alert("Print Customer Account Report\nPrint Server Not Operational");
		const ReportID = ['BJghjNZzVH', 'BJghjNZzVH'];
		console.log( "Print customer account report");
		this.dataSrv.useJsReportByGid( ReportID[ param ], this._customer.gid, 'Customer Snapshot', 'No comment'  ).subscribe(
			( rptData ) =>
				{
				console.log("Back from report");
				console.log( rptData );
				});
		}

	}
