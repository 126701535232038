import { AuthGuard }						from './_guards';

import { SecureInnerPagesGuard }		from './_guards';

import { AdminComponent }				from './components/admin';
import { CustomerComponent }			from './components/customer';
import { MeterComponent }				from './components/meter';
import { CsrComponent }					from './components/csr';
import { EmployeeComponent }			from './components/employee';
import { CreateUtilityComponent }	from './components/create-utility';
import { HomeComponent }				from './components/home';
import { InstallComponent }			from './components/install';
import { LocComponent }					from './components/loc-comp';
import { LoginComponent } 				from './components/login';
import { MonitorPanelComponent } 	from './components/monitor-panel/';
import { MonthlyUseageComponent }	from './components/reports';
import { PickUtilityComponent } 		from './components/pick-utility';
import { ReportsComponent }			from './components/reports';
import { SignUpComponent }				from './components/sign-up';
import { UserComponent }				from './components/user';
import { UtilityFormComponent } 		from './components/utility-form';

import { NgModule }						from '@angular/core';
import { PageNotFoundPart }    		from './parts';

import { Routes }							from '@angular/router';
import { RouterModule }					from '@angular/router';
import { ForgotPasswordPart }			from './parts';

import { VerifyEmailComponent }		from './verify-email/verify-email.component';


// import { UserManagementComponent } 	from './user';

const routes: Routes = [
	{ path: '',								redirectTo: '/login', pathMatch: 'full' },
	{ path: 'login',	 					component: LoginComponent, 			},
	{ path: 'logout', 					component: LoginComponent,  			},
	{ path: 'home',						component: HomeComponent, 				canActivate: [AuthGuard]},
	{ path: 'user/:auth_id',			component: UserComponent, 				canActivate: [AuthGuard]},
	{ path: 'install',					component: InstallComponent, 			canActivate: [AuthGuard]},
	{ path: 'csr',							component: CsrComponent, 				canActivate: [AuthGuard]},
	{ path: 'reports',					component: ReportsComponent, 			canActivate: [AuthGuard]},
	{ path: 'mon_use',					component: MonthlyUseageComponent, 	canActivate: [AuthGuard]},	
	{ path: 'admin',						component: AdminComponent, 			canActivate: [AuthGuard]},
//	{ path: 'admin',						component: AdminComponent, 			canActivate: [SecureInnerPagesGuard]},
	{ path: 'employee',					component: EmployeeComponent, 		canActivate: [AuthGuard]},
	{ path: 'createutility',			component: CreateUtilityComponent, 	canActivate: [AuthGuard]},
	{ path: 'utility',					component: UtilityFormComponent, 	canActivate: [AuthGuard]},
	{ path: 'mpanel',						component: MonitorPanelComponent, 	canActivate: [AuthGuard]},
	{ path: 'pick-utility',				component: PickUtilityComponent, 	canActivate: [AuthGuard]},
	{ path: 'cust',						component: CustomerComponent, 		canActivate: [AuthGuard]},
	{ path: 'cust/:c_gid',				component: CustomerComponent, 		canActivate: [AuthGuard]},
	{ path: 'locn/:l_gid',				component: LocComponent, 				canActivate: [AuthGuard]},
	{ path: 'meter/:m_gid',				component: MeterComponent, 			canActivate: [AuthGuard]},
	{ path: 'sign-up',					component: SignUpComponent, 			},
	{ path: 'forgot-password', 		component: ForgotPasswordPart, 		canActivate: [SecureInnerPagesGuard]},
	{ path: 'verify-email-address',	component: VerifyEmailComponent, 	canActivate: [SecureInnerPagesGuard]},
//	{ path: 'register', 					component: RegisterComponent, canActivate: [AuthGuard] },
	{ path: '**', 							component: PageNotFoundPart },
//	{ path: 'logout', 				component: UserComponent },
// 	{path: '',						redirectTo: '/',	pathMatch: 'full'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }
