import { Component, OnInit						}	from '@angular/core';
//import { Observable 								}	from 'rxjs';
import { AuthService, DataService 			}	from '../_services';


@Component({
	selector: 	'part-home-status',
	template: `
		<style>  
			td.Nbr				{text-align: right}
			th.RA					{text-align: right}
			td.txt				{text-align: left}
			table.mStat			{left:10%; width:98%; center;}
			table, th, td 		{  border: 1px solid black;}
			caption				{text-align: center; font-size: 24px; caption-side: top;font-weight: bold;}

  			div.MeterStatus	{position:absolute;  top: 60px; left: 5%; width:90%; height:500px; font-size: 18px;}
			</style>
		<div class=MeterStatus >
			<table class=mStat>
				<caption>System Status</caption>
				<thead><tr><th>Statistic</th><th class=RA >Value</th></tr></thead>
				<tbody>
					<tr><td class=txt>Unassigned Meters				</td><td class=Nbr >{{ Current_Inventory_Count	}}</td><tr>
					<tr><td class=txt>Locations Waiting Install	</td><td class=Nbr >{{ Waiting_Install_Count		}}</td><tr>
					<tr><td class=txt>Water On 						</td><td class=Nbr >{{ On_Line_Count				}}</td><tr>
					<tr><td class=txt>Water Off 						</td><td class=Nbr >{{ Off_Line_Count				}}</td><tr>
					<tr><td class=txt>Total Count						</td><td class=Nbr >{{ Total_Current_Count		}}</td><tr>
					<tr><br/></tr>                      			
					<tr><td class=txt>On Life Line 					</td><td class=Nbr >{{ Life_Line_Count				}}</td><tr>
					<tr><br/></tr>                      			
					<tr><td class=txt>Last Hour Use	 				</td><td class=Nbr >{{ Last_Hour_Useage			}}</td><tr>
					<tr><br/></tr>                      			
					<tr><td class=txt>Meters In Error				</td><td class=Nbr >{{ Meters_In_Error				}}</td><tr>
					<tr><td class=txt>Pressure Errors 				</td><td class=Nbr >{{ Meters_Pressure_Error		}}</td><tr>
					<tr><br/></tr>					         			
					<tr><td class=txt>Current User	 				</td><td class=txt >{{ Current_User					}}</td><tr>
					
					</tbody>
				</table>
			</div>			`,
})

export class HomeStatusPart implements OnInit 
	{
	Current_Inventory_Count:	number;
	Waiting_Install_Count: 		number;
	Off_Line_Count: 				number;
	On_Line_Count:					number;
	Life_Line_Count:				number;
	Total_Current_Count: 		number;
	Last_Hour_Useage:				number;
	Meters_In_Error:				number;
	Meters_Pressure_Error:		number;
	Current_User:					string;

	constructor(private dataSrv: DataService, private authService: AuthService)
		{
		}

	ngOnInit()
		{
		this.Current_User = this.authService.currentUserName;
		this.dataSrv.getMeterStatus( this.authService.currentUtility ).subscribe(
			response => {
				this.Current_Inventory_Count	= response.Current_Inventory_Count	;
				this.Waiting_Install_Count		= response.Waiting_Install_Count		;
				this.Off_Line_Count				= response.Off_Line_Count				;
				this.On_Line_Count				= response.On_Line_Count				;
				this.Total_Current_Count		= response.Total_Current_Count		;
				this.Last_Hour_Useage			= response.Last_Hour_Useage			;
				this.Meters_In_Error				= response.Meters_In_Error				;
				this.Meters_Pressure_Error		= response.Meters_Pressure_Error		;
				this.Life_Line_Count				= response.Life_Line_Count		;
				})  ;
		}

}
