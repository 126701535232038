import { Component, OnInit						}	from '@angular/core';
//import { Observable 								}	from 'rxjs';
import { AuthService, DataService 			}	from '../_services';
import { Router }										from "@angular/router";

@Component({
	selector: 	'part-home-aveUse',
	template: `
		<style>  
			td.Nbr				{text-align: right}
			th.RA					{text-align: right}
			td.txt				{text-align: left}
			table.mStat			{left:10%; width:98%; center;}
			table, th, td 		{  border: 1px solid black;}
			caption				{text-align: center; font-size: 24px; caption-side: top;font-weight: bold;}

  			div.MeterStatus	{position:absolute;  top: 60px; left: 5%; width:90%; height:500px; font-size: 18px;}
  			
			div.list_info			{position:absolute;	top: 60px; left: 10px; width: 998px; height:1000px; border:1px solid blue }
			ng2-smart-table		{ font-size: 12px}  			
			:host /deep/ .centerText{ text-align: center; }  			
			:host /deep/ .rightAText{ text-align: right; }  			
			</style>
		<div class=MeterStatus >
			<h1>Average Useage in Gallons Per Hour</h1>
			<div class=list_info >
				<ng2-smart-table
					[settings]		=	"settings"
					[source]			=	"Averages"
					(userRowSelect)=	"onUserRowSelect($event)"
					>
					</ng2-smart-table>
			</div>
				</div>			`,
})

export class HomeAverageUse implements OnInit 
	{
		
	settings = {                                                                               
		pager:	{                                                                              
			display: true,                                                                       
			perPage: 25,                                                                         
			},                                                                                  
		actions: {                                                                             
			add:		false,                                                                     
			delete:	false,                                                                     
			edit:		false,                                                                     
			},                                                                                  
		columns: {                                                                             
			customer_acct_nbr:	{title: 'Customer Acct:'	, width: '80px'},	
			meter_id:            {title: 'Meter ID'			, width: '80px'},
			GPH_60:              
					{title: '60 Days Ago'		, width: '80px', type: 'html',
					valuePrepareFunction: function(value){ return '<div class="rightAText"> ' + value + ' </div>'},
					compareFunction(direction: any, a: any, b: any) 
						{	
						let first = isNaN(Number(a)) ? a.toLowerCase() : Number(a);
						let second = isNaN(Number(b)) ? b.toLowerCase() : Number(b);		
						if (first < second || (!isNaN(Number(a)) && isNaN(Number(b)))) { return -1 * direction; }
						if (first > second || (isNaN(Number(a)) && !isNaN(Number(b)))) { return direction; }
						return 0;
						}  
							},
			GPH_30:              
					{title: '30 Days Ago'		, width: '80px', type: 'html',
					valuePrepareFunction: function(value){ return '<div class="rightAText"> ' + value + ' </div>'},
					compareFunction(direction: any, a: any, b: any) 
						{	
						let first = isNaN(Number(a)) ? a.toLowerCase() : Number(a);
						let second = isNaN(Number(b)) ? b.toLowerCase() : Number(b);		
						if (first < second || (!isNaN(Number(a)) && isNaN(Number(b)))) { return -1 * direction; }
						if (first > second || (isNaN(Number(a)) && !isNaN(Number(b)))) { return direction; }
						return 0;
						}  
							},
			GPH_1:	            
					{title: 'Last Day'			, width: '80px', type: 'html',
					valuePrepareFunction: function(value){ return '<div class="rightAText"> ' + value + ' </div>'},
					compareFunction(direction: any, a: any, b: any) 
						{	
						let first = isNaN(Number(a)) ? a.toLowerCase() : Number(a);
						let second = isNaN(Number(b)) ? b.toLowerCase() : Number(b);		
						if (first < second || (!isNaN(Number(a)) && isNaN(Number(b)))) { return -1 * direction; }
						if (first > second || (isNaN(Number(a)) && !isNaN(Number(b)))) { return direction; }
						return 0;
						}  
							},
			GPH_NOW:             
					{title: 'Last Reading'		, width: '80px', type: 'html',
					valuePrepareFunction: function(value){ return '<div class="rightAText"> ' + value + ' </div>'},
					compareFunction(direction: any, a: any, b: any) 
						{	
						let first = isNaN(Number(a)) ? a.toLowerCase() : Number(a);
						let second = isNaN(Number(b)) ? b.toLowerCase() : Number(b);		
						if (first < second || (!isNaN(Number(a)) && isNaN(Number(b)))) { return -1 * direction; }
						if (first > second || (isNaN(Number(a)) && !isNaN(Number(b)))) { return direction; }
						return 0;
						}  
							},
			},                                                                                  
		attr: {                                                                                
      	class: 'table table-bordered tr'                                                    
      	},                                                                                  
		};                                                                                     
	Averages:	any[];
	              		
	constructor(
		private dataSrv: DataService, 
		private authService: AuthService,
		private router: 		Router)
		{
		}

	ngOnInit()
		{
		this.dataSrv.getAverageUseage ( ).subscribe(                        
					( dbAverages ) => {                                                             
					console.log( dbAverages );                                                      
					this.Averages = dbAverages                                                        
					});                                                                           

		}
                                                                                                                                                                            
	onUserRowSelect(event: any) {                                                             
//		console.log('Select: '  + event.data.gid + ' isSelected: ' + event.isSelected );       
//		console.log( event );                                                                  
		console.log( event.data );                                                             
		this.router.navigate(['/meter/', event.data.M_gid]);                               
		}                                                                                      
 
                                                                                             
	}                                                                                    