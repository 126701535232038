import { Component, OnInit } 							from '@angular/core';
import { Utility, UtilityDetail }					from '../../_models';
import { Employee }										from '../../_models';
import { DataService }									from '../../_services';
import { AuthService }									from '../../_services';
import { AngularFireAuth }								from "@angular/fire/auth";
import { Router }											from "@angular/router";


@Component({
	selector: 'app-new-utility',
	templateUrl: './create-utility.component.html',
	styleUrls: ['./create-utility.component.scss']
})


export class CreateUtilityComponent implements OnInit {

	settingsUtil = {
//		mode:	'external',
		pager:	{
			perPage:	12,
			},
		actions: {
    		add: {
      		addButtonContent: '<i class="nb-plus"></i>',
      		createButtonContent: '<i class="nb-checkmark"></i>',
      		cancelButtonContent: '<i class="nb-close"></i>',
    			},
			delete:	false,
			edit:		false,
			},
		columns: {
			gid:				{title: 'Current',  },
			utility_name:	{title: 'First Name ', },
			logo_link:		{title: 'Last Name	', },
			} ,
		};

	Blankutility = new UtilityDetail(
		0, 'l', '', '', '',
		'', '', '', 200, 150,
		2, 3, 4, 0, 0,
		7, 8, 'SvrIP', 3301, '',
		'0800', '2200', 11, 20, 30,
		40);
	utility = this.Blankutility;

	tester =
		{
//		First_Name:			"A",
//		Last_Name:			"B",
//		email_addr:			"C",
		addr1:				"",
		addr2:				"",
		unit:					"",
		city:					"",
		state:				"",
		LAT:					0,
		LON:					0,
		acct_nbr:			'',
		utility_acct_nbr:	'',
		customer_name:		'',
		st_num:				'',
		st_name:				'',
		zip:					'',

		phone:				'No Phone',
		emp:					new Employee(),
		}

	Meter_id;	string;

	now:			number;
	deltaT:		number;

	constructor
		(
		private dataSrv: DataService,
		public authService: AuthService,
		public router:	Router,
		public afAuth: AngularFireAuth,		// Inject Firebase auth service
		)
		{
		}

	ngOnInit()
		{
		console.log( this.tester );
		console.log( this.utility );
		}

	submitted = false;
	has_utility_loc = false;
	has_tester_loc = false;


//	tester.emp.gid = 0;

	onSubmit()
		{
		console.log( this.utility );
		console.log( this.tester );
		this.submitted = true;
		this.has_utility_loc = false;
		this.has_tester_loc = false;
		this.tester.emp.gid = 0;

		this.CheckForUserID( this.tester.emp.email_addr );

		this.dataSrv.getMapInfoFromAddr( this.utility.Addr1, this.utility.City, this.utility.State ).subscribe(
			( data ) => {
			console.log('Utility addr');
			let R =  data['results'][0];
			console.log( R );
			if ((R.geometry.location_type != 'ROOFTOP')  && (R.geometry.location_type != 'GEOMETRIC_CENTER'))
				{
				alert("Invalid Utility: " + R.geometry.location_type)
				}
			else
				{
				let RX = R.formatted_address.split(',');
				console.log( RX );
				this.utility.Addr1 = RX[0].trim();
				this.utility.City  = RX[1].trim();
				this.utility.State = RX[2].trim().split(' ')[0];
				this.utility.LAT   = R.geometry.location.lat;
				this.utility.LON   = R.geometry.location.lng;
				console.log( this.utility );
				this.has_utility_loc = true;
				this.check_ready_to_save();
				}
			});

		this.dataSrv.getMapInfoFromAddr( this.tester.addr1, this.tester.city, this.tester.state ).subscribe(
			( data ) => {
			console.log('Tester addr');
			let R =  data['results'][0];
			console.log( R );
			if ((R.geometry.location_type != 'ROOFTOP')  && (R.geometry.location_type != 'GEOMETRIC_CENTER'))
				{
					alert("Invalid Tester: " + R.geometry.location_type)
				}
			else
				{
				let RX = R.formatted_address.split(',');
				console.log( RX );
				this.tester.addr1	= RX[0].trim();
				this.tester.city  = RX[1].trim();
				this.tester.state = RX[2].trim().split(' ')[0];
				this.tester.zip 	= RX[2].trim().split(' ')[1]
				this.tester.LAT   = R.geometry.location.lat;
				this.tester.LON   = R.geometry.location.lng;
				this.tester.customer_name = this.tester.emp.First_Name + " " + this.tester.emp.Last_Name;
				this.tester.utility_acct_nbr = 'U-' + this.tester.emp.Last_Name ;
				this.submitted = true;
				this.has_tester_loc = true;
				this.check_ready_to_save();
				}
			});
		}

	check_ready_to_save()
		{
		if ( this.has_utility_loc && this.has_tester_loc && (this.tester.emp.gid > 0) )
			{
			console.log("Save Data");
			this.dataSrv.addUtility(
					this.utility.utility_name, this.utility.Addr1, this.utility.Addr2, this.utility.Unit, this.utility.City,
					this.utility.State,  this.utility.LAT, this.utility.LON ).subscribe
				(( utility_gid ) =>
					{
					console.log("Utility Gid:" + utility_gid );
 					this.dataSrv.updateUserUA( this.tester.emp.auth_id, utility_gid + '|11|' ).subscribe(
 						( retv ) => {
 							console.log( retv );
 							})
					const	perms = "["
						+	`{"t":"mh","g":11,"v":1},{"t":"mp","g":11,"v":1},`
						+	`{"t":"mh","g":` + utility_gid + `,"v":1},{"t":"ut","g":` + utility_gid + `,"v":1},{"t":"mc","g":` + utility_gid + `,"v":1},`
						+	`{"t":"mp","g":` + utility_gid + `,"v":1},{"t":"user","g":` + utility_gid + `,"v":1},{"t":"admin","g":` + utility_gid + `,"v":1}]`;
					console.log( perms );
 					this.dataSrv.updateUserPerm( this.tester.emp.auth_id, perms ).subscribe(
 						( retv ) => {
 							console.log( retv );
 							})
 					this.dataSrv.updateCurrentTestUtility( this.tester.emp.auth_id, utility_gid ).subscribe(
 						( retv ) => {
 							console.log( retv );
 							})
 					console.log( this.tester );
 					this.dataSrv.addCustomer( utility_gid, this.tester.utility_acct_nbr,
 						this.tester.customer_name, this.tester.phone,
 						this.tester.addr1, this.tester.addr2, this.tester.unit, this.tester.city, this.tester.state, this.tester.zip).subscribe(
 						( cust_gid ) => {
 							console.log( "Cust gid: " + cust_gid );
 							this.dataSrv.addLocation( cust_gid, utility_gid, this.tester.utility_acct_nbr,
 								this.tester.customer_name, this.tester.phone,
 								this.tester.addr1, this.tester.unit, this.tester.city, this.tester.state, this.tester.zip,
 								this.tester.LAT, this.tester.LON ).subscribe(
 								( locn_gid ) => {
 									console.log( locn_gid );
									if ( this.Meter_id )
										{
										this.dataSrv.installMeter( this.Meter_id, locn_gid  )
										.subscribe(
											(postAry:  Array<object>) => {
												console.log( postAry );
												alert("Finished with new utility");
												});
										}
									else
										{
										alert("Finished with new utility with no meter");
										}
								});
 						});
					});
			}
		}

	resetScreen()
		{
//		console.log('Reset Screen');
		}

	editUtility(event: any)
		{
		this.router.navigate(['utility/' + event.data.auth_id ]);
		}

	CheckForUserID( email )
		{
		this.tester.emp.gid = 0;
		this.dataSrv.findUser( this.tester.emp.email_addr ).subscribe(
			( data ) => {
				if ( data  )
					{
					console.log("null employee");
					this.SignUp(this.tester.emp.email_addr, this.tester.emp.First_Name,
						this.tester.emp.Last_Name, 'Nebula');
					}
				else
					{
					console.log("Found: " + data.gid );
					this.tester.emp = data;
					this.check_ready_to_save();
					}
			});
		}

	SignUp(email, firstName, lastName, password)
		{
		console.log("sign up: " + firstName + " " + lastName + " " + email
			+ " " + password );

    	return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      	.then((result) => {

				console.log("employee created in firestore")  ;
				console.log( result );
				console.log( result.user )

				const rl = result.user;
				console.log( rl );
				console.log( rl.email );
				console.log( rl['uid']);

				var tempEmp = new Employee;
				tempEmp.gid = -1;
				tempEmp.auth_id = rl['uid']  ;
				tempEmp.email_addr = email;

				tempEmp.First_Name = firstName;
				tempEmp.Last_Name = lastName;
//				tempEmp.token = "Unknown";
//				this.authService.SendVerificationMail();

				var D = new Date().toJSON();
				const date_in = D.slice(0, 10) + ' ' + D.slice(11, 19);
				tempEmp.date_in = date_in;
				tempEmp.date_out = '2099-12-31 12:00:00';
//				tempEmp.working = 'W';
//				tempEmp.utility_gid = -1;
				tempEmp.utility_gid = 11;

//				console.log( tempEmp )
				this.dataSrv.addTestUser( tempEmp )
					.subscribe(
						( retdata ) => {
							console.log( retdata );
							this.tester.emp = retdata;
							console.log("Done with sign-up for "  + this.tester.emp.email_addr )  ;
							this.check_ready_to_save();
							});
			}).catch((error) => {
				window.alert("error on add user: " + error.message)
			})
		console.log("Done with sigin-up")  ;

//	[{"t":"mh","g":-1,"v":1},{"t":"ut","g":-1,"v":1},{"t":"mc","g":-1,"v":1},{"t":"mp","g":-1,"v":1},{"t":"user","g":-1,"v":1},{"t":"admin","g":-1,"v":1}]

		}

	Install_Meter( meter_id, locn_gid)
		{
		}


	}


//export class UtilityFormComponent implements OnInit {
//	now:		number;
//	deltaT:	number;
//
//	constructor(
//		public authService:	AuthService,
//		public router: Router,
//		private dataSrv:		DataService
//		)
//		{
//		if(!this.authService.isRoleOK("ut" )) {
//			window.alert("You are not allowed to access this URL!");
//			this.router.navigate(['home'])
//			}
//       setInterval(() => {
//          this.now = Date.now();
//          this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
//        }, 1000);
//
//		}
//
//	Blankutility = new UtilityDetail(
//		0, '', 'Utility Name', 'Addr1', 'City',
//		this.States[5], 2, 3, 4, 5,
//		7, 76, 42, 9, 25,
//		'SvrIP', 5, this.tZones[1], '', '',
//		0, 0, 0, 55);
//	utility = this.Blankutility;
//

//

//
//	ngOnInit()
//		{
//		this.dataSrv.getUtility(
//			this.authService.currentUtility ).subscribe( ( data ) => {
//				this.utility = data;
////				console.log( data );
//				});
//		}
//
//	}
//