<h3>Contact Crisis Center</h3>

<div *ngIf="details">

  {{ details }}

</div>

<div>

  <div>

    <label>Message: </label>

  </div>

  <div>

    <textarea [(ngModel)]="message" rows="10" cols="35" [disabled]="sending"></textarea>

  </div>

</div>

<p *ngIf="!sending">

  <button (click)="send()">Send</button>

  <button (click)="cancel()">Cancel</button>

</p>



