import { Component, OnInit, Input  }	from '@angular/core';
import { Observable }			from 'rxjs';
import { DataService }			from '../_services';
import { Meter }					from '../_models';
//import { ViewChild} 				from '@angular/core';

@Component({
  selector: 	'part-meter-graph',
  template: 
	`		<google-chart 
			[type]			="graphType"
			[title]			="graphTitle"
			[data]			="graphData"
			[columnNames]	="graphColumnNames"
			[width] 			="graphWidth"
			[height]			="graphHeight"
			></google-chart>
			<google-chart 
			[type]			="VgraphType"
			[title]			="VgraphTitle"
			[data]			="VgraphData"
			[columnNames]	="VgraphColumnNames"
			[width] 			="graphWidth"
			[height]			="graphHeight"
			></google-chart>
			<google-chart 
			[type]			="BgraphType"
			[title]			="BgraphTitle"
			[data]			="BgraphData"
			[columnNames]	="BgraphColumnNames"
			[width] 			="graphWidth"
			[height]			="graphHeight"
			></google-chart>
			`,
})

export class MeterGraphPart implements OnInit {
	graphWidth = 990;
	graphHeight = 500;
 
	graphType = 'ColumnChart';
	graphTitle = '';
	graphColumnNames = ['Date', 'Gallons' ];
	graphOptions = {       };
	graphData = [];
		
	BgraphType = 'LineChart';
	BgraphTitle = '';
	BgraphColumnNames = ['Date', 'deg F', 'PSI' ];
	BgraphOptions = {
		"fill": 20,
		"vAxis": {
			 "title": "Sales unit",
			 "gridlines": {"count": 10}
					},
		};
	BgraphData = [];

	VgraphType = 'LineChart';
	VgraphTitle = '';
	VgraphColumnNames = ['Date', 'RTU Volts', 'CPU Volts' ];
	VgraphOptions = {
		"fill": 20,
		"vAxis": {
			 "title": "Sales unit",
			 "gridlines": {"count": 10}
					},
		};
	VgraphData = [];

	_meter:		Meter;

	ChartUse = {
		chartType:  'ColumnChart',
		dataTable: [],
		options: {},
		};

	strMon: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	@Input()	
		set meter(meter: Meter)
			{
			console.log("set meter")
			this._meter = meter;
			}

	@Input() 
		set GraphPeriod(GraphPeriod: string)
			{		
			console.log("set graphperiod")
		if (this._meter === undefined) {	return;	}
		if (this._meter.meter_id == 'On Order') {	return;}

		let Gtitle: string;
		let BGtitle: string;
		let VGtitle: string;
		const LblS = [];
		switch   ( GraphPeriod )  {
			case 'Y':
				Gtitle  = 'Useage by month for last 13 Months';
				BGtitle = 'Temperature/Pressure by month for last 13 Months';
				VGtitle = 'Battery voltage by month for last 13 Months';
				break;
			case 'M':
				{
				Gtitle = 'Useage by day for last 31 days';
				BGtitle = 'Temperature/Pressure by day for last 31 days';
				VGtitle = 'Battery voltage by day for last 31 days';
				break;
				}
			case 'W':
				{
				Gtitle  = 'Useage by day for last 8 days';
				BGtitle = 'Temperature/Pressure by day for last 8 days';
				VGtitle = 'Battery voltage by day for last 8 days';
				break;
				}
			case 'D':
				Gtitle  = 'Useage by hour for last day';
				BGtitle = 'Temperature/Pressure by hour for last day';
				VGtitle = 'Battery voltage by hour for last day';
				break;
			default:
				Gtitle  = 'Useage by month for 3 years';
				BGtitle = 'Temperature/Pressure month for 3 years';
				VGtitle = 'Battery voltage by month for 3 years';
				break;
			}

		this.dataSrv.getMeterUseage( this._meter.meter_id, GraphPeriod  ).subscribe(
			(Records) => {

				const DT = [];
				const BDT = [];
				const VDT = [];
				for (let i = 0; i < Records.length; i++) 
					{
					const Record = Records[i];
					var meaD = '';  
//            	console.log( Record );
//            	console.log( Record['D'] + ' ' + Record['H'] + ":00");
					switch ( GraphPeriod )
						{
						case 'D':
							meaD = Record['D'] + ' ' + Record['H'] + ":00";
							break;
						case 'W':
							meaD = Record['D'] + ' ' + Record['H'] + ":00";
							break;
						case 'M':
							meaD = Record['D'] ;
							break;
						case 'Y':
							meaD = Record['D'];
							break;
						default:
							meaD = Record['D'];
							break;
						}
					const dd = new Date(meaD);
//					console.log("Data point: " + dd + "   " + Record['P']);
					Record['U'] = Record['U'] < 0 ? 0 : Record['U'];
//					if ( Record['P'] === null)
//						{
//						console.log( "Null: " + dd ) ;
//						}
//					else
//						{
						const newV = [ dd, Record['U']/1.0  ];
						DT.push( newV );
						const BnewV = [ dd, Record['T']/1.0 , Record['P']/1.0  ];
						BDT.push( BnewV );
						const VnewV = [ dd, Record['VR']/1.0, Record['VC']/1.0  ];
						VDT.push( VnewV );
//						}
					}

				console.log( DT );
				console.log( BDT );

				this.graphData = DT;
				this.graphTitle = Gtitle;

				this.BgraphData = BDT;
				this.BgraphTitle = BGtitle;

				this.VgraphData = VDT;
				this.VgraphTitle = VGtitle;
			});
		}


	constructor(private dataSrv: DataService)
		{
		console.log("constructor")
		}

	ngOnInit()
		{
		console.log("ngoninit")
		}


}
