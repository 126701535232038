import { Component, OnInit, Input }		from '@angular/core';
import { Meter }								from '../_models';
import { AuthService, DataService } 	from '../_services';

@Component({
  selector: 	'part-meter-buttons',
  template:
	`	<style>
div.btn_monitor	{position:absolute; top:  0px; left:   0px;text-align: left; width:990px; height:48px; border:1px solid black;}
.cl-part-box		{position:absolute; top: 50px; left:   0px; width:990px; height:570px;border:1px solid black;}

button.cb_current	{position:absolute; top:   4px; left:    0px; font-size:20px;}
button.cb_ml-his	{position:absolute; top:   4px; left:   90px; font-size:20px;}
button.cb_lm-his	{position:absolute; top:   4px; left:  134px; font-size:20px;}
div.cb_gra			{position:absolute; top:   4px; left:  200px; font-size:20px;border:1px solid green;width:517px; height:38px}
button.cb_life		{position:absolute; top:   0px; left:  150px; font-size:20px;}
button.cb_year		{position:absolute; top:   0px; left:  200px; font-size:20px;}
button.cb_month	{position:absolute; top:   0px; left:  257px; font-size:20px;}
button.cb_week		{position:absolute; top:   0px; left:  331px; font-size:20px;}
button.cb_day		{position:absolute; top:   0px; left:  398px; font-size:20px;}
button.cb_24		{position:absolute; top:   0px; left:  449px; font-size:20px;}
div.cb_dtl			{position:absolute; top:   4px; left:  720px; font-size:20px;border:1px solid green;width:260px; height:38px}
button.cb_details	{position:absolute; top:   0px; left:  100px; font-size:20px;}
button.cb_events	{position:absolute; top:   0px; left:  177px; font-size:20px;}

	</style>

 	<div class=btn_monitor>
		<button class=cb_current		type="submit" (click)="showCurrentData()">Current</button>
		<button class=cb_ml-his			type="submit" (click)="showMLHistoryData()" *ngIf="authService.isRoleOK('mh')">ML</button>
		<button class=cb_lm-his			type="submit" (click)="showLMHistoryData()" *ngIf="authService.isRoleOK('mh')">LM</button>
		<div class=cb_gra>Useage History
			<button class=cb_life	 	type="submit" (click)="graphResolution('L')">Life</button>
			<button class=cb_year	 	type="submit" (click)="graphResolution('Y')">Year</button>
			<button class=cb_month   	type="submit" (click)="graphResolution('M')">Month</button>
			<button class=cb_week	 	type="submit" (click)="graphResolution('W')">Week</button>
			<button class=cb_day	    	type="submit" (click)="graphResolution('D')">Day</button>
			<button class=cb_24	    	type="submit" (click)="graph24()" *ngIf="show24Btn">24 Hr</button>
			</div>
		<div class=cb_dtl>Show
			<button class=cb_details 	type="submit" (click)="showDetailBtn('D')">Details</button>
			<button class=cb_events	 	type="submit" (click)="showDetailBtn('E')">Events</button>
			</div>
		</div>

<part-meter-detail	class=cl-part-box [meter]="_meter" *ngIf="showDetail">
	</part-meter-detail>

<part-meter-event		class=cl-part-box [meter]="_meter" *ngIf="showEvents">
	</part-meter-event>

<part-meter-reading	class=cl-part-box [meter]="_meter"  *ngIf="showCurrent">
	</part-meter-reading>

<part-meter-graph		class=cl-part-box [meter]="_meter" [GraphPeriod]='GraphPeriod' *ngIf="showGraph">
	</part-meter-graph>

<part-meter-24		class=cl-part-box [meter]="_meter" *ngIf="showGraph24">
	</part-meter-24>

<part-meter-locn-history	class=cl-part-box [meter]="_meter" *ngIf="showMLHistory">
	</part-meter-locn-history>

<part-locn-meter-history	class=cl-part-box [location]="_location" *ngIf="showLMHistory">
	</part-locn-meter-history>
`

})

export class MeterButtonsPart implements OnInit
	{
	_meter:				Meter;
	showGraph = false;
	showGraph24 = false;
	show24Btn = false;
	showLMHistory = false;
	showMLHistory = false;
	showDetail = false;
	showEvents = false;
	showCurrent = true;
	GraphPeriod = '';


	@Input()

	set meter(meter: Meter)
		{
		if ( typeof(meter) !== 'undefined' ) {
//			console.log( meter.read_date );
			this._meter = meter;

			var d = new Date( this._meter.read_date );
			console.log( this._meter.read_date + "     " + d.toLocaleString() )

			this.dataSrv.getMeter24List( this._meter.gid ).subscribe(
				(Records) => { 
					this.show24Btn = (typeof Records[0] === 'undefined') ? false : true  ; 
					});

			}
		}

	get meter(): Meter { return this._meter; }

	constructor( public authService: AuthService, private dataSrv: DataService )
		{
		}

	ngOnInit()
		{
		}

	showCurrentData()
		{
		this.showGraph = false;
		this.showGraph24 = false;
		this.showDetail = false;
		this.showEvents = false;
		this.showCurrent = true;
		this.showLMHistory = false;
		this.showMLHistory = false;
		}

	showMLHistoryData()
		{
		this.showGraph = false;
		this.showGraph24 = false;
		this.showDetail = false;
		this.showEvents = false;
		this.showCurrent = false;
		this.showMLHistory = true;
		this.showLMHistory = false;
		}

	showLMHistoryData()
		{
		this.showGraph = false;
		this.showGraph24 = false;
		this.showDetail = false;
		this.showEvents = false;
		this.showCurrent = false;
		this.showLMHistory = true;
		this.showMLHistory = false;
		}


	graphResolution = function (Parm)
		{
		this.GraphPeriod = Parm;
//		this.show_graph( Parm );
		this.showGraph = true;
		this.showGraph24 = false;
		this.showDetail = false;
		this.showEvents = false;
		this.showCurrent = false;
		this.showLMHistory = false;
		this.showMLHistory = false;
    	};

	graph24 = function ()
		{
		this.showGraph = false;
		this.showGraph24 = true;
		this.showDetail = false;
		this.showEvents = false;
		this.showCurrent = false;
		this.showLMHistory = false;
		this.showMLHistory = false;
    	};

	onGraphSelect(  )
		{
		console.log( 'clicked on graph');
		}

	showDetailBtn(param: string)
		{
		this._meter.paramDetail = param;
		if ( ! this.showGraph ) { this.showGraph = true; }
		this.showGraph = false;
		this.showGraph24 = false;
		this.showDetail = false;
		this.showCurrent = false;
		this.showLMHistory = false;
		this.showMLHistory = false;
		if ( param == 'D' ) {
			this.showDetail = true;
			this.showEvents = false;
			} else {
			this.showDetail = false;
			this.showEvents = true;
			}
		}

 	onDetailSelect(event: any)
 		{
		console.log( event.data );
		}


	}
