<app-header>
	</app-header>

<div class=list_box *ngIf="flgListBox" >

<!--	<img	class=retMenu     src="assets/img/menu.png" (click)="returnToMenu()" >-->

	<div class="rpt_hdr centerAlign HLT">Click on selected report</div>

	<div class=rpt_part >
	<ng2-smart-table
	    [settings]		=	"settingReports"
	    [source]		=	"reportData"
		(userRowSelect)=	"onReportRowSelect($event)"
	    >
	</ng2-smart-table>
	</div>

</div>

<div class=list_box *ngIf="flgBillList" >

	<div class="rpt_hdr centerAlign HLT">Click on selected billing record</div>
	<a (click)="newBillRecord()" style="position:relative;left:25px:top:150px">
		<i class="material-icons">build</i>
		Build new billing record
		</a>

	<div class=rpt_part >
	<ng2-smart-table
	    [settings]		=	"configBilling"
	    [source]		=	"reportData"
		(userRowSelect)=	"onBillingRowSelect($event)"
	    >
	</ng2-smart-table>
	</div>

</div>

<div class=list_box *ngIf="flgNewBill" >
	<h1 style="text-align:center">{{ headerReport }}</h1>
	<div class=rpt_part >
				
		<div class="container">
			<h1 style="text-align:center">Billing Report Configurator</h1>
			<form #billControlForm="ngForm" (ngSubmit)="onSubmitBill()"  class="form-horizontal"  >
		
				<div class="form-group row">
					<label class="control-label col-lg-2" for="rpt_key">Report Key:</label>
					<input type="text" class="form-control col-lg-10" id="rpt_key"
						[(ngModel)]="billCtrlRec.rpt_key" placeholder="rpt_key"
						name="rpt_key" #rpt_key="ngModel"  >
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="DTG">Report created on:</label>
					<input type="text" class="form-control col-lg-10" id="DTG"
						[(ngModel)]="billCtrlRec.DTG" placeholder="DTG"
						name="DTG" #DTG="ngModel"  >
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="as_of_date">Bill as of:</label>
					<input type="text" class="form-control col-lg-10" id="as_of_date"
						[(ngModel)]="billCtrlRec.as_of_date" placeholder="as_of_date"
						name="as_of_date" #as_of_date="ngModel"  >
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="billing_infocol">Bill Status:</label>
					<input type="text" class="form-control col-lg-10" id="billing_infocol"
						[(ngModel)]="billCtrlRec.billing_infocol" placeholder="billing_infocol"
						name="billing_infocol" #billing_infocol="ngModel"  >
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="number_gallons">Number of Gallons:</label>
<!--
					<input type="text" class="form-control col-lg-10" id="number_gallons"
						[(ngModel)]="billCtrlRec.number_gallon/10.0" placeholder="number_gallons"
						name="number_gallons" #number_gallons="ngModel"  >
-->
					<div class="form-control col-lg-10" id="number_gallons">{{ billCtrlRec.number_gallon/10.0 }}</div>
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="c_user">Bill created by:</label>
					<input type="text" class="form-control col-lg-10" id="c_user"
						[(ngModel)]="billCtrlRec.c_user" placeholder="c_user"
						name="c_user" #c_user="ngModel"  >
					</div>
				<div class="form-group row">
					<label class="control-label col-lg-2" for="s_user">Status changed by:</label>
					<input type="text" class="form-control col-lg-10" id="s_user"
						[(ngModel)]="billCtrlRec.s_user" placeholder="s_user"
						name="s_user" #s_user="ngModel"  >
					</div>
		
				<button type="submit" class="btn btn-success l">Submit</button>
		
		
				</form>
			</div>		
		
		
		
		
		</div>
	</div>

<div class=list_box *ngIf="flgQuestion" >

	<div class="rpt_hdr">
		Answer questions here
		<form #parameterForm="ngForm" (ngSubmit)="doReports()"  #f="ngForm">
			<select name="mon_id" [(ngModel)]="model.mon_id" #mon_id="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mon_id.invalid }" required>
				<option value="" disabled selected>Select Month</option>
				<option *ngFor="let name of allMonths" [value]="name.mon_id">{{name.name}}</option>
				</select>

			<select name="year" [(ngModel)]="model.year" #year="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mon_id.invalid }" required>
				<option value="" disabled selected>Select Year/option>
				<option *ngFor="let name of allYears" [value]="name.year">{{name.year}}</option>
				</select>

			<button type="button" class="btn btn-default" (click)="restart();f.reset()">Reset</button>
			<button type="submit" class="btn btn-success" [disabled]="!f.form.valid">Submit</button>

			</form>
		</div>

	</div>


<div class=list_box *ngIf="flgReport" >

	<div class="rpt_hdr centerAlign HLT">{{ headerReport }}</div>

	<div class=rpt_part >
		<ng2-smart-table
		    [settings]		=	"reportConfig"
		    [source]		=	"reportData"
			(userRowSelect)=	"onReportRowSelect($event)"
			(custom)			=	"onBillCustom($event)"
		    >
		</ng2-smart-table>
		</div>

</div>


<div class=list_box *ngIf="flagMU" >
	<div class="rpt_hdr centerAlign HLT">{{ headerReport }}</div>
	<app-monthy-useage  [ST_DT]="ST_DT" [EN_DT]="EN_DT"> </app-monthy-useage>
	</div>

<div class=list_box *ngIf="flagBR" >
	<div class="rpt_hdr centerAlign HLT">{{ headerReport }}</div>
	<app-billing-report  [rpt_key]="rpt_key" > </app-billing-report>
	</div>
