import { Component, OnInit, Input  }					from '@angular/core';
import { Location, Meter }									from '../_models';
import { MapsAPILoader, AgmMap 						}	from '@agm/core';

@Component({
	selector:		'part-locn-map',
	template:
		`<style>
			div.cm_map			{border:1px solid black; width:499px; height:244px}
			agm-map 				{  height: 244px;}
			</style>
		<div class=cm_map >
			<agm-map
				[latitude]="kmap_lat"
				[longitude]="kmap_lon"
				[disableDefaultUI]="true"
				[zoomControl]="true"
				[zoom]="18"
				[fitBounds]="true"
				[mapTypeId]="'satellite'"
				>
				<agm-marker
				[agmFitBounds]="true"
					*ngFor="let m of markers; let i = index"
						[latitude]="m.lat"
						[longitude]="m.lng"
						[label]="m.label"
						[iconUrl]="m.icon">
					</agm-marker>
				</agm-map>
		</div>`
	})

export class LocnMapPart implements OnInit
	{

	_location:			Location;
	_meter:				Meter;

	@Input()
		set meter(meter: Meter)
			{
			if ( typeof(meter) !== 'undefined' ) {
				if (( typeof(this._meter ) === 'undefined' ) || (this._meter.gid != meter.gid)) {
					this._meter = meter;
 	   			}
				}
			}

	@Input()
		set location(location: Location)
			{
			if ( typeof(location) !== 'undefined' ) {
				if (( typeof(this._location ) === 'undefined' ) || ( this._location.gid != location.gid)) {
					this._location = location;
 					}
				}
			}

//	get location(): Location { return this._location; }

	kmap_lat=32;
	kmap_lon=-96;

	markers = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  draggable: true,
		  icon: "https://stoshb.info/assets/img/iLocn.png",
	  },
//	  {
//		  lat: 51.373858,
//		  lng: 7.215982,
//		  draggable: false,
//		  icon: "https://stoshb.info/assets/img/iGreenMeter.png",
//	  }
  ];

	DummyMarker = {
		  lat: 0,
		  lng: 0,
		  draggable: false,
		  icon: "https://stoshb.info/assets/img/iGreenMeter.png",
	  };

	constructor(
		)
		{
		}

	ngOnInit()
		{
		if ( this._location )
			{
			this.kmap_lat = this._location.lat/1.0;
			this.kmap_lon = this._location.lon/1.0;

			this.markers[0].lat = this._location.lat ;
			this.markers[0].lng = this._location.lon ;
//			console.log( this._meter );

			if ( this._meter.meter_id == 'On Order' )
				{
				this.markers[0].icon = "https://stoshb.info/assets/img/iWhiteMeter.png" ;
      		}
			else if (this._meter.meter_id == 'No Meter')
				{
				this.markers[0].icon = "https://stoshb.info/assets/img/iMauveL.png" ;
      		}
      	else
      		{
      		if (this._meter  )
      			{
      			this.markers.push( this.DummyMarker );
					this.markers[1].lat = this._meter.lat / 1.0;
					this.markers[1].lng = this._meter.lon / 1.0;
            	
					if ( this._meter.valve_lifeline == 1 )	{ this.markers[1].icon = "https://stoshb.info/assets/img/iYellowMeter.png" ; }
					else if ( this._meter.ErrStatus > 0 )	{ this.markers[1].icon = "https://stoshb.info/assets/img/iRedMeter.png" ; }
 					else												{ this.markers[1].icon = "https://stoshb.info/assets/img/iGreenMeter.png" ; }
      			}
      		}
			}
		else { console.log("no location available");}
		}

//	mapClick(event)
//		{
//		console.log(event);
//		}

	}
