import { Injectable }		from '@angular/core';
import { HttpClient }		from '@angular/common/http';
import { HttpHeaders }		from '@angular/common/http';
import { BehaviorSubject, Observable }	from 'rxjs';
import { AuthService } 		from './auth.service';
// const PubSub = require('@google-cloud/pubsub');

import { Utility, UtilityDetail, Location, Meter, Customer, Employee, UA }
	from '../_models';

@Injectable({	providedIn: 'root'})

export class DataService {

	googleDB	= true;
//	uriGA		= 'https://stoshb.info:5488/';
	uriGA		= 'https://stoshb.info/do_sql/';
uriG		= 'https://127.0.0.1:8000/';
	URI		= '';
	URL		= '';
	httpOptions =
		{
		headers: new HttpHeaders({
			'Content-Type':  'application/json',
			'Authorization': 'my-auth-token',
			'responseType': 	'text'
	  		})
	  	};

 	constructor(
 		private authService: AuthService,
 		private http: HttpClient
 		) {
		if ( this.googleDB )
			{
			this.URI = this.uriGA;
			}
//		this.URI = 'https://stoshb.info:5488/';	// 'http://74.208.51.40';
		this.URI = 'https://stoshb.info/do_sql/';	// 'http://74.208.51.40';
 		}

	private flagRfrsh = new BehaviorSubject(false);
	RfrsFlag = this.flagRfrsh.asObservable();

//	private RefreshLoc = new BehaviorSubject(false);
//	flgRefreshLoc = this.RefreshLoc.asObservable();

	userEmail = new BehaviorSubject(false);
	strUserEmail = this.userEmail.asObservable();
	localUserEmail: string;

	private screenName = new BehaviorSubject('');
	currentScreen = this.screenName.asObservable();

//	private utility_gid = new BehaviorSubject( -1 );

//	private localUGid: number;

	private UtilityName = new BehaviorSubject( 'Nebula Water Meter Co.' );
	currentUName = this.UtilityName.asObservable();
	private localUtitilyName = '';

//	private UtilityLogo = new BehaviorSubject('https://stoshb.info/assets/176_Nebula_logo_V2.1.png');
//	currentULogo = this.UtilityLogo.asObservable();
//	logoLink = '176_Nebula_logo_V2.1.png';

	private UtilityLat = new BehaviorSubject( 0.0 );
	currentULat = this.UtilityLat.asObservable();

	private UtilityLon = new BehaviorSubject( 0.0 );
	currentULon = this.UtilityLon.asObservable();

	private UserKey = new BehaviorSubject( 'b54a1bf7255f' );
	currentUserKey = this.UserKey.asObservable();

	private Menu = new BehaviorSubject('MainMenu');
	flgMenu = this.Menu.asObservable();

	private Util_ALT = new BehaviorSubject( 30 );
	currentUALT = this.Util_ALT.asObservable();

	private Util_AHT = new BehaviorSubject( 125.0 );
	currentUAHT = this.Util_AHT.asObservable();
	private Util_ALP = new BehaviorSubject( 0.0 );
	currentUALP = this.Util_ALP.asObservable(  );

	private Util_AHP = new BehaviorSubject( 100.0 );
	currentUAHP = this.Util_AHP.asObservable();


// ============================================================================================================

	changeLogin( flgLog: boolean, userAuthID, userEmail ) {
//		console.log('Change login state to: ' + flgLog + ' Email: ' + userEmail);

		this.userEmail.next( userEmail );
		this.localUserEmail = userEmail;

		}

//	changeRefresh( flag: boolean ) {
// 		console.log("Refresh Flag touched");
//		this.flagRfrsh.next( flag );
//		}

	changeUtility( gid: number ) {
//		console.log('Change Utility to: ' + gid );
//		this.utility_gid.next( gid );
//		this.authService.currentUtility = gid;

		this.getUtility( gid ).subscribe(
   		data => {
//   			console.log("Gid changed then got correct utility data");
//   			console.log( data );
 				this.UtilityName.next( data.utility_name );
// 				console.log( "Utility name: " +    this.UtilityName      );
 				this.localUtitilyName    =       data.utility_name  ;
// 				console.log( "localUtitilyName: " + this.localUtitilyName );
//	  			this.UtilityLogo.next( 'assets/' + data.logo_link );
//	  			this.logoLink = data.logo_link;
	  			if ( data.LAT ) {
		  			this.UtilityLat.next( data.LAT / 1.0 );
		  			this.UtilityLon.next( data.LON / 1.0 );
		  			} else {
		  			this.UtilityLat.next( 0.0 );
		  			this.UtilityLon.next( 0.0 );
					}
//	  			this.UtilityRptFreq.next( data.RptFreq );
//	  			this.UtilityIZ.next( data.init_zoom / 1.0 );
 				this.Util_ALT.next( data.ALT );
 				this.Util_AHT.next( data.AHT );
 				this.Util_ALP.next( data.ALP );
 				this.Util_AHP.next( data.AHP );
  				})	;
		}

	changeZoom(zoom: number) {
//		this.UtilityIZ.next( zoom );
		}

// ============================================================================================================


	getAverageUseage (  ): Observable<any[]>
 		{
 		this.URL = this.URI + 'get/average/useage/' + this.authService.currentUtility;
		console.log( this.URL );
		return this.http.get<any[]>(this.URL);
		}


	getCustomerByCustID ( cid: string ): Observable<Customer[]> {
 		{this.URL = this.URI + 'get/cust/acct/' + cid + '/utility/' +  + this.authService.currentUtility; }
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}

 	getCustomerByGid ( gid: number ) {
 		{this.URL = this.URI + 'get/cust/' + gid;   }
 		console.log( this.URL );
 		return this.http.get<any>(this.URL);
 		}

	getCustomerByLocnGid ( locn_gid: number ): Observable<Customer[]>
		{
		this.URL = this.URI + 'get/cust/locn/' + locn_gid;
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}

	getCustomerByMeterGid ( meter_gid: number ) {
		{this.URL = this.URI + 'get/cust/meter/' + meter_gid;   }
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getCustomerByName ( search_name: string )
		{
		this.URL = this.URI + 'get/cust/name/' + this.authService.currentUtility + '/' + search_name.replace(' ', '%20');
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	getCustomerByPhone ( phone: string ): Observable<Customer[]>
		{
		this.URL = this.URI + 'get/cust/phone/' + this.authService.currentUtility + '/' + phone.replace(' ', '%20');
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}

	getCustomerByStNumStName ( st_num: string, st_name: string  ): Observable<Customer[]>
		{
		this.URL = this.URI + 'get/cust/stadr/' + this.authService.currentUtility + '/' + st_num.replace(' ', '%20') + '/' + st_name.replace(' ', '%20');
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}

	getCustomerUseage ( st_date: string, end_date: string  )
		{
		this.URL = this.URI + 'get/cust/useage/' + this.authService.currentUtility + '/' + st_date + '/' + end_date;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getMeterUseage = function (meter_id: string, by: string  )
		{
		this.URL = this.URI + 'get/meter/useage/' + meter_id + '/' + by ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getMeterReports = function (meter_gid: number, start_gid: number, end_gid: number  )
		{
		this.URL = this.URI + 'get/meter/reports/' + meter_gid + '/' + start_gid + "/" + end_gid ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}



	getDataForMeter ( gid: number ): Observable<Meter>
		{
		this.URL = this.URI + 'get/meter/' + gid;
		console.log( this.URL );
		return this.http.get<Meter>(this.URL);
		}

	getHistoryDataForMeter ( gid: number ): Observable<Location[]>
		{
		this.URL = this.URI + 'get/meter/' + gid + '/history';
		console.log( this.URL );
		return this.http.get<Location[]>(this.URL);
		}

	getLocationsByBlockStreet( st_num: string, st_name: string, city: string  ): Observable<Customer[]>
		{
		this.URL = this.URI + 'get/locn/block/' + this.authService.currentUtility + '/' + st_num.replace(' ', '%20' )
		+ '/' + st_name.replace(' ', '%20') + '/' + city.replace(' ', '%20');
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}

	getLocnByLocnGid ( gid: number )
		{
		this.URL = this.URI + 'get/locn/'  + gid;
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	getLocnByStNumStName ( st_num: string, st_name: string  ): Observable<Customer[]>
		{
		this.URL = this.URI + 'get/locn/bnbr/' + this.authService.currentUtility + '/'
			+ st_num.replace(' ', '%20' )
			+ '/' + st_name.replace(' ', '%20');
		console.log( this.URL );
		return this.http.get<Customer[]>(this.URL);
		}


	getMapPoints (locn_gid: number, meter_gid: number, toggleStr, boundString: string, jtStartIndex: number, jtPageSize: number )
		{
		this.URL = this.URI + 'get/utility/mappoints/' + this.authService.currentUtility + '/' + locn_gid  + '/' + meter_gid + '/'
			+ toggleStr +  '/' + boundString + '/' + jtStartIndex + '/' + jtPageSize ;
 		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	getOrderedMapPoints (locn_gid: number, toggleStr, boundString: string, jtPageSize: number )
		{
		this.URL = this.URI + 'get/utility/orderedmappoints/' + this.authService.currentUtility + '/' + locn_gid + '/'
			+ toggleStr +  '/' + boundString + '/' + jtPageSize ;
 		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	getMetersForLocn ( gid: number )
		{
		this.URL = this.URI + 'get/meters/locn/' + gid;
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	getMeterStatus ( gid: number )
		{
		this.URL = this.URI + 'get/utility/status/' + gid;
		console.log( this.URL);
		return this.http.get<any>(this.URL);
		}

	getReports ( )
		{
		this.URL = this.URI + 'get/reports/' + this.authService.currentUtility;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getUserByAuthID( auth_id ): Observable<Employee>
		{
		this.URL = this.URI + 'get/user/' + auth_id;
		console.log( this.URL );
		return this.http.get<Employee>(this.URL);
		}

	getUsersByUtility( ): Observable<Employee[]>
		{
		{this.URL = this.URI + 'get/utility/users/' + this.authService.currentUtility ; }
		console.log( this.URL );
		return this.http.get<Employee[]>(this.URL);
		}

	getUtility( gid?: number ): Observable<UtilityDetail>
		{
		if ( gid == undefined ) { gid = -1 }
		this.URL = this.URI + 'get/utility/' + gid;
		console.log( this.URL );
		return this.http.get<UtilityDetail>( this.URL );
		}

	getUtilities(  ): Observable<Utility[]>
		{
		this.URL = this.URI + 'get/utilities' ;
		console.log( this.URL );
		return this.http.get<Utility[]>( this.URL );
		}

	listUseageByMeter ( m_gid: number, parm: string )
		{
		this.URL = this.URI  + 'get/meter/' + m_gid + '/useage/' + parm;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getMonthlyUseage ( st_date: string, end_date: string )
		{
		this.URL = this.URI  + 'get/cust/useage/' + this.authService.currentUtility + '/' + st_date + '/' +  end_date;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getBillingReportList (  )
		{
		this.URL = this.URI  + 'get/billing/' + this.authService.currentUtility  ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getBillingRecord ( gid: number )
		{
		this.URL = this.URI  + 'get/billing/record' + gid ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	createBillingReport ( start_date: string, end_date: string )
		{
		this.URL = this.URI  + 'create/billing/' + this.authService.currentUtility + '/'
			+ start_date + "/" + end_date + '/' +  this.authService.userAuthUid  ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	setBillingReportStatus ( rpt_key: number, status: number )
		{
		this.URL = this.URI  + 'status/billing/' + status + '/' +  this.authService.userAuthUid  ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	updateBillCtrlRec( rpt_key, as_of_date, billing_infocol )
		{
		this.URL = this.URI  + 'update/billctrl/' + rpt_key + "/" + as_of_date + '/' +  billing_infocol + '/'
			+ this.authService.currentUtility + '/' +this.authService.userAuthUid  ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getUtilityUseage = function( by: string )
		{
		this.URL = this.URI + 'get/useage/' + this.authService.currentUtility + '/' + by;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getIssueData ( st_date: string, end_date: string ) {
		this.URL = this.URI  + 'get/meter/issues/' + this.authService.currentUtility + '/' + st_date + '/' +  end_date;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getIssueDataCurrent ( cmd_flag: boolean, msg_flag: boolean, err_flag: boolean ) {
		this.URL = this.URI  + 'get/meter/issues/current/' + this.authService.currentUtility + '/' + cmd_flag + "/" + msg_flag + "/" + err_flag;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	useJsReportA ( template: string )
		{
//		this.URL = "https://kjitx.com:5489/templates/" + template;
		this.URL = "https://kjitx.com:5489/api/report";
		const BODY =
			{
			"template": { "shortid" : template  },
			"data": {}
			};

//		console.log( this.URL );
//		console.log( BODY );
		return this.http.post<any>( this.URL, BODY, this.httpOptions );
		}

	useJsReport ( template: string )
		{
//		console.log("Use template: " + template + " and send to: " + this.localUserEmail );
		return this.http.post<any>(
			"https://kjitx.com:5489/api/report",
			{
				"template": { "shortid" : template  },
				"data":
					{
					"cust_gid": 674782,
					"email": 		this.localUserEmail,
//					"logo_link":	this.logoLink,
					"utilityName":	this.localUtitilyName
					}
			},
			this.httpOptions );
		}

	useJsReportByGid ( template: string, gid: any, subject: string, comment: string )
		{
//		console.log("for useJsReportByGid use template: " + template + " gid: " + gid + " and send to: " + this.localUserEmail
//			+ " Subject: " + subject + " Comment: " + comment );
		return this.http.post<any>(
			"https://kjitx.com:5489/api/report",
			{
				"template": { "shortid" : template  },
				"data":
					{
					"gid": 			gid,
					"email": 		this.localUserEmail,
//					"logo_link":	this.logoLink,
					"utilityName":	this.localUtitilyName,
					"subject":		subject,
					"comment":		comment
					}
			},
			this.httpOptions );
		}

	rpt_by_date ( template: string, gid: any, start_date: string, end_date: string, subject: string, comment: string )
		{
//		console.log("for rpt_by_date use template: " + template + " gid: " + gid + " start: " + start_date + " end: " + end_date + " and send to: " + this.localUserEmail );
		return this.http.post<any>(
			"https://kjitx.com:5489/api/report",
			{
				"template": { "shortid" : template  },
				"data":
					{
					"gid": 			gid,
					"email": 		this.localUserEmail,
//					"logo_link":	this.logoLink,
					"utilityName":	this.localUtitilyName,
					"start_date":	start_date,
					"end_date":		end_date,
					"subject":		subject,
					"comment":		comment
					}
			},
			this.httpOptions );
		}


// ============================================================================================================

	commandTest( gid: number ) {
		{this.URL = this.URI + 'order/meter/test/' + gid + '/' + this.authService.userAuthUid;  }
		console.log( this.URL );
		return this.http.get( this.URL);
		}

	installMeter( meter_id: string, locn_gid: number )
		{
		{this.URL = this.URI + 'install/meter/' + meter_id + '/'
			+  locn_gid + '/' +  this.authService.userAuthUid; }
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	updateCnt24( gid: number, cnt_24: number, RptFreq: number) {
		{this.URL = this.URI + 'update/meter/cnt24/' + gid + '/' + cnt_24
			+ '/' + RptFreq + '/' + this.authService.userAuthUid;  }
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	getMeter24List( meter_gid: number )
		{
		this.URL = this.URI + 'get/meter/24list/' + meter_gid ;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	setLifeLine( gid: number, flag: string) {
		{this.URL = this.URI + 'update/meter/valve_open_cmd/' + gid + '/' + flag
			+ '/' + this.authService.userAuthUid;  }
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	orderMeter ( locn_gid, order_dt ) {
		{this.URL = this.URI + 'order/meter/' + locn_gid + '/' + this.authService.userAuthUid + '/' + order_dt; }
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	orderMetersByBlockStreet ( StNum: string, st_name: string, city: string  )
		{
		let pstr = encodeURI( StNum  + "/" + st_name + "/"			+  city);
		this.URL = this.URI + 'order/meter/block/' + pstr + '/' +  this.authService.currentUtility + '/' + this.authService.userAuthUid;
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	updateRptFreq(gid: number, RptFreq: number) {
		{this.URL = this.URI + 'update/meter/freq/' + gid + '/' + RptFreq + '/' + this.authService.userAuthUid; }
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	addUser( Emp: Employee ) : Observable<Employee>{
		{this.URL = this.URI + 'add/user/' + this.authService.userAuthUid; }
		console.log( this.URL );
		console.log( Emp );
		return this.http.post<any>(this.URL, Emp);
		}

	findUser( email ) : Observable<Employee>{
		{
		this.URL = this.URI + 'find/user/' + email; }
		console.log( this.URL );
		return this.http.get<any>(this.URL);
		}

	addTestUser( Emp: Employee ) : Observable<Employee>{
		{
		this.URL = this.URI + 'add/test/user'  }
		console.log( this.URL );
		console.log( Emp );
		return this.http.post<any>(this.URL, Emp);
		}

	updateUser( Emp: Employee )
		{
		this.URL = this.URI + 'update/user/' + this.authService.userAuthUid;
		console.log( this.URL );
		console.log( Emp );
		return this.http.post<any>(this.URL, Emp);
		}

	updateUserUA( AuthID, strUA )
		{
		this.URL = this.URI + 'update/user/UA/' + AuthID + "/" + strUA;
		console.log( this.URL );
		return this.http.get<any>(this.URL );
		}

	updateUserPerm( AuthID, perm )
		{
		this.URL = this.URI + 'update/user/perm/' + AuthID + "/" + perm;
		console.log( this.URL );
		return this.http.get<any>(this.URL );
		}

	updateCurrentTestUtility( AuthID, utility_gid )
		{
		this.URL = this.URI + 'update/user/currentutility/' + AuthID + "/" + utility_gid;
		console.log( this.URL );
		return this.http.get<any>(this.URL );
		}

	updateCurrentUtility( utility_gid: number)
		{
		this.URL = this.URI + 'update/user/currentutility/' + this.authService.userAuthUid
			+ '/' + utility_gid;
		console.log( this.URL );
		return this.http.get<Meter>(this.URL);
		}


	updateValve( gid: number, position: number): Observable<Meter>
		{
		this.URL = this.URI + 'update/meter/valve/' + gid + '/' + this.authService.userAuthUid + '/' + position;
		console.log( this.URL );
		return this.http.get<Meter>(this.URL);
		}

	addUtility( Uname: string, Addr1: string, Addr2: string, Unit: string, City: string, State: string, LAT: number, LON: number)
		{
		Addr2 = (Addr2 == '') ? '.na.' : Addr2;
		Unit =  (Unit == '')  ? '.na.' : Unit;
		let pstr = encodeURI( Uname + '/' + Addr1 + '/' + Addr2 + '/' + Unit + '/' + City + '/'  + State + '/' + LAT + '/' + LON);
		this.URL = this.URI + 'add/utility/' + pstr;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

	addCustomer( utility_gid, customer_acct_nbr, customer_name, phone_number,
 						addr1, addr2, unit, city, state, zip)
		{
		addr2 = (addr2 == '') ? '.na.' : addr2;
		unit =  (unit == '')  ? '.na.' : unit;
		let pstr = encodeURI( utility_gid + '/' + customer_acct_nbr + '/' + customer_name + '/'  + phone_number
			+ '/' + addr1 + '/' + addr2 + '/' + unit + '/' + city + '/' + state + '/' + zip);
		this.URL = this.URI + 'add/customer/' + pstr;
		console.log( this.URL );
		return this.http.get(this.URL);
		}

 	addLocation( cust_gid, utility_gid,customer_acct_nbr, customer_name, phone_number,
			addr, unit, city, state, zip, LAT, LON )
		{
		unit =  (unit == '')  ? '.na.' : unit;
		let pstr = encodeURI( cust_gid + '/' + utility_gid + '/' + customer_acct_nbr + '/' + customer_name
			+ '/'  + phone_number + '/' + addr + '/' + unit + '/' + city + '/' + state + '/' + zip + '/' + LAT + '/' + LON);
		this.URL = this.URI + 'add/location/' + pstr;
		console.log( this.URL );
		return this.http.get<number>(this.URL);
		}

	updateUtility ( util: UtilityDetail) {
		this.URL = this.URI + 'update/utility/' + this.authService.userAuthUid;
//		console.log( this.URL );
//		console.log( util );

 		this.UtilityName.next( util.utility_name );
// 	  	this.UtilityLogo.next( 'assets/' + util.logo_link );
		this.UtilityLat.next( util.LAT / 1.0 );
		this.UtilityLon.next( util.LON / 1.0 );
//	  	this.UtilityRptFreq.next( util.RptFreq );
//	  	this.UtilityIZ.next( util.init_zoom / 1.0 );
 		this.Util_ALT.next( util.ALT );
 		this.Util_AHT.next( util.AHT );
 		this.Util_ALP.next( util.ALP );
 		this.Util_AHP.next( util.AHP );

//		console.log ( JSON.stringify( util ) );
//		return this.http.post(this.URL, util , this.httpOptions);
		return this.http.post(this.URL, util );
		}

// ============================================================================================================

	searchCustomerByMeterID ( meter_id: string ): Observable<Customer[]> {
		{
		this.URL = this.URI + 'search/meter/' + this.authService.currentUtility + '/' + meter_id; }
		console.log (this.URL);
		return this.http.get<Customer[]>(this.URL);
		}

	updateCmdReset( gid: number ): Observable<Meter> {
		{
		this.URL = this.URI + 'update/meter/reset/' + gid + '/' + this.authService.userAuthUid;  }
		console.log( this.URL );
		return this.http.get<Meter>(this.URL);
		}

	requestGPS( gid: number ): Observable<Meter> {
		{
		this.URL = this.URI + 'request/gps/' + gid + '/' + this.authService.userAuthUid;  }
		console.log( this.URL );
		return this.http.get<Meter>(this.URL);
		}
	getMeterReadingGraph = function( ID, baset ) {
		{
		this.URL = this.URI + 'get/meter/' + ID + '/plot/'  + baset; }		console.log( this.URL );
		return this.http.get( this.URL);
		};

// ============================================================================================================

	getMapInfoFromAddr( addr: string, city: string, state: string )
		{
		this.URL = 'https://maps.googleapis.com/maps/api/geocode/json?address='
			+ (addr + "+" + city + "+" + state).replace(' ','+')
			+ '&key=AIzaSyDMpFujbx7FQptNGYBmFicln6L_ZwBGC2g';
		console.log( this.URL );
		return this.http.get(this.URL);
		};

	notifyServer ( gid, cmd, val ) {
		const message = {
			'cmd': cmd,
			'gid': gid,
			'val': val
			};
		console.log(message);
// 		this.publishMessage( message );
		this.URL = 'https://kjitx.com:3303?meter_gid=' + gid + '&cmd=' + cmd + '&value=' + val + '&user=' + this.authService.userAuthUid;
		console.log( this.URL );
		return this.http.post(this.URL, {responseType: 'text'});
		}

// 	publishMessage( Packet )
// 		{
// 		const topicName = 'notify-server';
// 		const dataBuffer = Buffer.from(JSON.stringify( Packet ))
// 		const messageId = PubSub.topic(topicName).publish(dataBuffer);
// 		}

	reportTest()
		{
		this.URL = 'https://kjitx.com:5489/templates/SkgX-OqCRV';
		const jsonX = {'template': {'name': 'template1'}, 'data': {'to': 'Pavel Sladek', 'from': 'Jan Blaha', 'price': 800}};
		console.log( this.URL );
		return this.http.post<any>(this.URL, jsonX);
		}

// ============================================================================================================



// 	getCustForUtility ( gid: number, jtStartIndex: number, jtPageSize: number ) :Observable< number[]>
// 		{
// 	   let URL = 'http://meter.kjitx.com/models/ajx_cust.php?utility_gid=' + gid
// 	   		+ "&jtStartIndex=" + jtStartIndex + "&jtPageSize=" + jtPageSize
// 	   		+	"&action=getCustForUtility";
// 		console.log( this.URL )
// 		return this.http.get<number[]>(this.URL)
// 		};
//
// 	getLocationsForCust ( gid: number ): Observable<Location[]>
// 		{
//   	let URL = 'http://meter.kjitx.com/models/ajx_locn.php?action=getLocationForCust'
//   		+	'&cust_gid=' + gid
// 		console.log( this.URL )
// 		return this.http.get<Location[]>(this.URL)
// 		}  ;
//
// 	getLocnByCustGid ( gid: number ): Observable<Location>
// 		{
//   	let URL = 'http://meter.kjitx.com/models/ajx_locn.php?action=getLocnByGid'
//   		+	'&locn_gid=' + gid
// 		console.log( this.URL )
// 		return this.http.get<Location>(this.URL)
// 		}  ;
//


//
//
// 	runReport (rpt_name, utility_gid, P1, P2, P3)
// 		{
// 		let URL ="http://meter.kjitx.com/app/reports/qry_report.php?NAME=" + rpt_name + "&utility_gid=" + utility_gid + "&user=" + this.localUserName
// 			+ "&P1=" + P1
// 			+ "&P2=" + P2
// 			+ "&P3=" + P3;
// 		console.log( URL );
// 		return this.http.get(this.URL)
// 		}
//
// 	updateCmdReset( gid: number ): Observable<Meter>
// 		{
// 		let URL = 'http://meter.kjitx.com/models/ajx_meter.php?meter_gid=' + gid + "&user=" + "&user=" + this.localUserName
// 					+ "&action=updateCmdReset";
// 		console.log( URL );
// 		return this.http.get<Meter>(this.URL)
// 		}  ;
//
//
//
//

//




	}


