import { Component }										from '@angular/core';
import { Router, Params }								from '@angular/router';
import { FormBuilder, FormGroup, Validators }	from '@angular/forms';
import { AuthService }									from '../../_services';
import { DataService } 									from '../../_services';

@Component({
	selector: 		'page-login',
	templateUrl: 	'login.component.html',
	styleUrls: 		['login.component.scss']
})
export class LoginComponent {

	loginForm: FormGroup;
	errorMessage = '';

	constructor
		(
		public authService: AuthService,
		private router: Router,
		private fb: FormBuilder,
		private dataSrv: DataService,
		)
		{
		this.createForm();
		}

	createForm() {
		this.loginForm = this.fb.group({
			email: ['', Validators.required ],
			password: ['', Validators.required]
		});
	}

// 	tryFacebookLogin(){
// 		this.authService.doFacebookLogin()
// 		.then(res => {
// 			this.router.navigate(['/user']);
// 		})
// 	}
//
// 	tryTwitterLogin(){
// 		this.authService.doTwitterLogin()
// 		.then(res => {
// 			this.router.navigate(['/user']);
// 		})
// 	}

// 	tryGoogleLogin(){
// 		this.authService.doGoogleLogin()
// 		.then(res => {
// 			console.log( "Google Logged In" );
// 			console.log( res );
// 			this.router.navigate(['/home']);
// 		})
// 	}

	tryLogin(value)
		{
		const x = document.getElementById('fldPassword');
		const y =  this.loginForm.get('password');
		this.authService.doLogin(value)
			.then(res => {
				console.log( 'Email login used' );
				console.log( res.user );
				this.dataSrv.getUserByAuthID( res.user.uid ).subscribe( Employee =>
					{
					this.dataSrv.changeUtility( Employee.utility_gid );
					this.dataSrv.changeLogin( true, Employee.auth_id, 'stoshb@icloud.com' );

					this.router.navigate(['/dashboard']);
					}); })
			, err => {
				console.log(err);
				this.errorMessage = err.message;
			};
		}

	showPassword() {
		const x = <HTMLInputElement>document.getElementById('fldPassword');
		if 	(x.type === 'password') { x.type = 'text'; } else									{ x.type = 'password'; }
		}

	checkValue(event: any) {
   	console.log(event);
	}

	}

// 		this.route.data.subscribe(routeData => {
// 			let data = routeData['data'];
// 			if (data) {
// 				this.user = data;
// 				this.createForm(this.user.name);
// 			}
// 		})


