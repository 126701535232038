
export class Marker {
    gid:				number;
    utilityGid:	number;
//    userName:		string;
    password:		string;
    firstName:		string;
    lastName:		string;
    role:			string;
    dateIn:			string;
    dateOut:		string;
    emailAddr:		string;
}
