import { Component, OnInit } 							from '@angular/core';
import { Employee }										from '../../_models';
import { DataService }									from '../../_services';
import { AuthService }									from '../../_services';
import { Router }											from "@angular/router";

@Component({
	selector: 'app-employee',
	template: `<nav class="navbar navbar-expand-lg navbar-light bg-light">

	<a  [routerLink]="['/pick-utility']" >
		<img class="navbar-brand"  src="{{ authService.utilityLogo }}" width=100px height=55px />
		</a>

	<a [routerLink]="['/home']"
		style="position:absolute;left:10%">
		<i class="material-icons">home</i>
		Home
		</a>
	<a [routerLink]="['/sign-up']" *ngIf="authService.isRoleOK( 'admin' )"
		style="position:absolute;left:25%">
		<i class="material-icons">perm_data_settings</i>
		Add Employee
		</a>
	<a [routerLink]="['/admin']" *ngIf="authService.isRoleOK( 'admin' )" style="position:absolute;left:50%">
		<i class="material-icons">perm_data_settings</i>
		Admin
		</a>

	<a (click)="authService.SignOut()" style="position:absolute;left:95%">
		<i class="material-icons">logout</i>
		</a>
	</nav>
	<div style="position:absolute;	top:	100px;	left:  10px;	width: 998px;	height:400px;" >
		<ng2-smart-table
			[settings]		=	"settingsEmp"
			[source]			=	"employee"
			(userRowSelect)=	"editEmployee($event)"
			>
		</ng2-smart-table>
		</div>`,

})

export class EmployeeComponent implements OnInit {

	settingsEmp = {
//		mode:	'external',
		pager:	{
			perPage:	12,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			working:			{title:	'Current', width: '10px' },
			First_Name:		{title:	'First Name ', },
			Last_Name:		{title:	'Last NameA	', },
			Email_Addr:		{title:	'Email Addrr '},
			Auth_ID:			{title:	'Auth ID'},
			} ,
		};

	employee!:	Employee[];
	now!:			number;
	deltaT!:		number;

	constructor
		(
		private	dataSrv: 		DataService,
		public	authService:	AuthService,
		public	router:			Router,
		)
		{
		setInterval(() => {
			this.now = Date.now();
			this.deltaT = Math.floor(( this.now - authService.currentUserLoggedOnAt)/60000) ;
			}, 1000);

		}

	ngOnInit() {
		console.log("Init employee component");
		this.dataSrv.getUsersByUtility(  ).subscribe(
			(dataAry:  Array<Employee>) => {
				console.log( dataAry );
				this.employee = dataAry;				
				});
		}

	editEmployee(event: any)
		{
		console.log( "Event: ");
		console.log( event );
		this.router.navigate(['user/' + event.data.Auth_ID ]);
		}

	}


