import { Component, OnInit, Input }	from '@angular/core';
import { Location }						from '../_models';
import { DataService }					from '../_services';
import { Router }							from "@angular/router";

import { DOCUMENT } 						from '@angular/common';
import { Inject } 						from '@angular/core';

@Component({
  selector:	'part-no-meter',
  template: 		
			`<style>
			div.nom_box			{width: 489px; height:215px;  border:1px solid  black;}		
			div.a_PMT			{position:absolute; top:   50px; left:   35%;width:105px; height:80px}
			div.a_PMTRh			{position:absolute; top:   10px; left:   5px;	z-index:9;	width: 300px;}
			div.a_PMTR			{position:absolute; top:   30px; left:  25px;	z-index:9;}
			div.mp_title_ln	{position:absolute; top:   3px; left:   0px;width:100%; height:25px; text-align:center;font-size:24px;}
			</style>
			<div class=nom_box>
			<div class=mp_title_ln>There is no meter yet assigned to this location</div>
			<div class=a_PMT  >
			<div class=a_PMTRh>Order a meter</div>
			<div CLASS=a_PMTR >
				<button type="submit" class="btn" (click)="orderMeter()">
				<IMG SRC="https://stoshb.info/assets/img/GreenPlus_16.png" title="Click to order meter" ></button>
			</div></div>`
	})

export class NoMeterPart implements OnInit
	{
	_location:	Location;
	
	@Input()
	set location(location: Location)
		{
		this._location = location;
 		}

	constructor(private dataSrv: DataService,
		private router:	Router,
		@Inject(DOCUMENT) private _document: Document)
		{
		}

	ngOnInit()
		{
		}


	orderMeter()
		{
		this.dataSrv.orderMeter( this._location.gid , 'NOW' ).subscribe(
			(obj ) => {
			console.log( obj);
//			this._location.meter_id = 'On Order';
			this._document.defaultView.location.reload();
			});
		}
}




