<style>
	.cl-part-meter    		{position:absolute; top:  310px; left:  10px;}
	.cl-part-meter-ctrl		{position:absolute; top:  300px; left: 500px;}
	.cl-part-meter-button   {position:absolute; top:  525px; left:  10px;}
	.cl-part-cus				{position:absolute; top:   55px; left:  10px;}
	.cl-part-locn     	  	{position:absolute; top:  170px; left:  10px;}
	.cl-part-map       		{position:absolute; top:   55px; left: 500px;}
	</style>

<app-header>
	</app-header>


<part-cus 				class=cl-part-cus 			*ngIf="_customer"	[customer]="_customer" >
	</part-cus>

<part-locn 				class=cl-part-locn 			*ngIf="_location"	[location]="_location"  >
	</part-locn>

<part-locn-map 		class=cl-part-map 			*ngIf="_location"	[location]="_location" [meter]="_meter"  >
	</part-locn-map>

<part-meter 			class=cl-part-meter 			*ngIf="_meter"		[meter]="_meter" >
	</part-meter>

<meter-ctrl   	class=cl-part-meter-ctrl			*ngIf="showMeterCtrl"	[meter]="_meter" >
	</meter-ctrl>

<part-meter-buttons	class=cl-part-meter-button	*ngIf="showMeterData"  	[meter]="_meter" >
	</part-meter-buttons>

<part-no-meter 		class=cl-part-meter 			*ngIf="showOrderMeter"	[location]="_location" >
	</part-no-meter>

