import { Component, OnInit, Input } from '@angular/core';
import { DataService }					from '../_services';
import { Meter }							from '../_models';

@Component({
	selector: 'part-meter-event',
	template: 
		`
		<style>
		:host /deep/ ng2-smart-table { 
    		font-size: 14px; 
			}
		</style>
		
		<ng2-smart-table    
		[settings]		=	"eventSettings"
		[source]			=	"eventData"
		(userRowSelect)=	"onEventSelect($event)"
		>
		</ng2-smart-table>`
})

export class MeterEventPart implements OnInit {

	_meter:	Meter;

	eventData:		any;
	eventSettings =
		{
		hideSubHeader:	true,
		pager:	{ perPage:	12, },
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns:  {
			DTG:			{title: 'DTG',		width: '220px'},
			cmd:         	{title: 'Type', 			type: 'html',
				valuePrepareFunction:
				function(value) {
					if ( value == 'ERR' ) {return '<div class="back_red_text_white">ERR</div>';}
					if ( value == 'MSG' ) {return '<div class="back_tan">MSG</div>';}
					if ( value == 'CMD' ) {return '<div class="back_powderblue">CMD</div>';}
					if ( value == 'CLR' ) {return '<div class="back_green_text_white">CLR</div>';}
					return '<div class="centerAlign"> ' + value  + '</div>';
				}},
			message:		{title: 'Message', width: '450px'},
// 			user:			{title: 'User'},
			full_name:	{title: 'User'},
			},
		};

	@Input()

		set meter(dis_meter: Meter) {
			this._meter = dis_meter;
			this.dataSrv.listUseageByMeter( this._meter.gid, 'E' ).subscribe(
				(response) => {
					this.eventData = response    ;
					});
			}



	constructor(private dataSrv: DataService) {		}

	ngOnInit() {		}

	onEventSelect($event) {
//		console.log('On Event Select: ' + $event);
		}


}
