import { Component, OnInit } 			from '@angular/core';
import { AuthService }					from "../../_services";
import { DataService }					from "../../_services";
import { Employee }						from '../../_models';
import { AngularFireAuth }				from "@angular/fire/auth";
import { Router } 						from '@angular/router';

//import * as admin from 'firebase-admin';

@Component({
  selector: 	'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: 	['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
//	admin: any;
//	app: any;

//admin = require('firebase-admin');
//app = this.admin.initializeApp();


	constructor(
		public authService: AuthService,
		public dataSrv: DataService,
		public afAuth: AngularFireAuth,		// Inject Firebase auth service
		public router: Router
		) { }

	ngOnInit()
		{

//		this.app = admin.initializeApp();

//		this.app = admin.initializeApp({
//			credential: admin.credential.applicationDefault(),
//			databaseURL: 'https://titanic-182119.firebaseio.com.firebaseio.com'
//			});
		}

	SignUp(email, firstName, lastName, password)
		{
//		console.log("sign up: " + firstName + " " + lastName + " " + email + " " + token
//			+ " " + password );

    	return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      	.then((result) => {

				console.log("employee created in firestore")  ;
//				console.log( result );
				console.log( result.user )
//				console.log( result.user[0].l );

				const rl = result.user;
//				console.log( rl );
//				console.log( rl.email );
//				console.log( rl.l );
//				console.log( rl['uid']);


				var tempEmp = new Employee;
				tempEmp.gid = -1;
				tempEmp.auth_id = rl['uid']  ;
				tempEmp.email_addr = email;

				tempEmp.First_Name = firstName;
				tempEmp.Last_Name = lastName;
				tempEmp.token = "Unknown";
//				this.authService.SendVerificationMail();

				var D = new Date().toJSON();
				const date_in = D.slice(0, 10) + ' ' + D.slice(11, 19);
				tempEmp.date_in = date_in;
				tempEmp.date_out = '2099-12-31 12:00:00';
//				tempEmp.working = 'W';
				tempEmp.utility_gid = this.authService.currentUtility

//				console.log( tempEmp )
				this.dataSrv.addUser( tempEmp )
					.subscribe(
						(E:  Employee) => {
							console.log( E );
							
	 						this.dataSrv.updateUserUA( tempEmp.auth_id, this.authService.currentUtility + '|' ).subscribe(
 								( retv ) => {
 									console.log( retv );
 									})

							const	perms = "["
								+	`{"t":"mh","g":` + this.authService.currentUtility + `,"v":1},{"t":"ut","g":` + this.authService.currentUtility 
								+ `,"v":1},{"t":"mc","g":` + this.authService.currentUtility + `,"v":1},`
								+	`{"t":"mp","g":` + this.authService.currentUtility + `,"v":1},{"t":"user","g":` + this.authService.currentUtility 
									+ `,"v":1},{"t":"admin","g":` + this.authService.currentUtility + `,"v":1}]`;
							console.log( perms );
 							this.dataSrv.updateUserPerm( tempEmp.auth_id, perms ).subscribe(
 								( retv ) => {
 									console.log( retv );
 									})
 													
//
//	SQL1: "INSERT INTO user (auth_id, email_addr, First_Name, Last_Name,
//		token,
//		date_in, date_out, utility_gid) VALUES (
//		'LuG73Cpu2saNNCJJ3OhgoqIDB073' ,  'chris.c@kjitx.com' ,  'Chris' ,  'Columbus' ,
//		'chris.c@kjitx.com' ,
//		'2021-01-08 19:44:46' ,  '2099-12-31 12:00:00' ,  '-1' );"
//	bary: "|gid:-1|auth_id:LuG73Cpu2saNNCJJ3OhgoqIDB073|email_addr:chris.c@kjitx.com|First_Name:Chris|Last_Name:Columbus|token:chris.c@kjitx.com|date_in:2021-01-08 19:44:46|date_out:2099-12-31 12:00:00|utility_gid:-1"
//	cary: "gid:-1,auth_id:LuG73Cpu2saNNCJJ3OhgoqIDB073,email_addr:chris.c@kjitx.com,First_Name:Chris,Last_Name:Columbus,token:chris.c@kjitx.com,date_in:2021-01-08 19:44:46,date_out:2099-12-31 12:00:00,utility_gid:-1"
//	user: "luvPTelNpGWtJcx5I0szhp57wyy1"
//	__proto__: Object
//
//
//							console.log( postAry.SQL1 );
							this.router.navigate(['login']);
							});
				console.log("Done with sign-up")  ;

			}).catch((error) => {
				window.alert("error on add user: " + error.message)
			})
		console.log("Done with sigin-up")  ;
		}


}