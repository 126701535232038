<app-header>
	</app-header>

	<div class=ctrlPanel >
		<div class=search_title >Search&nbsp;By:		</div>
		<div class=search_nameh >Name				</div>
		<input (keyup)="onName($event)" class=search_name
			type="text" ng-model="nameFilter"  placeholder="Name...">

		<div class=search_CIDh >Utility Acct 		</div>
		<input (keyup)="onCustID($event)" class=search_CID
			type="text" ng-model="cidFilter"  placeholder="Acct Nbr...">

		<div class=search_adrh >Street				</div>
		<input (keyup)="onStNbr($event)" class="search_nbr   input-medium"
			type="text" ng-model="addrNbrFilter" placeholder="St Nbr...">
		<input (keyup)="onStName($event)" class="search_str   input-medium"
			type="text" ng-model="addrStrFilter" placeholder="St Name...">

		<div class=search_phoneh >Phone			</div>
		<input (keyup)="onPhone($event)" class="search_phone input-medium"
			type="text" ng-model="phoneStrFilter" placeholder="Phone...">

		<div class=search_meterh >Meter#			</div>
		<input (keyup)="onMeter($event)" class="search_meter input-medium"
			type="text" ng-model="meterStrFilter" placeholder="Meter #...">
		</div>

<!--
		<div class=order_meterh >Auto Order</div>
		<input (keyup)="onMeter($event)" class="order_meter input-medium"
			type="text" ng-model="flgOrderMeter" >
		</div>
-->
	<div class=list_box   >

		<span 	*ngIf="searchCust">
		<ng2-smart-table
		    [settings]		=	"settingsCust"
		    [source]		=	"customer"
			(userRowSelect)=	"onCustRowSelect($event)"
		    >
		</ng2-smart-table>
		</span>

		<span *ngIf="searchMeter" >
		<ng2-smart-table
			[settings]		=	"settingsMeter"
			[source]			=	"meter"
			(userRowSelect)=	"onMeterRowSelect($event)"
		    >
		</ng2-smart-table>
		</span>

<span *ngIf="flgLoading">
	<div class="loading">Loading&#8230;</div>
</span>

		</div>








