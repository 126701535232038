
import { Injectable }	from '@angular/core';
import { AuthService } 	from '../_services';
import { Observable }	from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

	constructor(
		public authService: AuthService,
		public router: Router
	){ }

	canActivate
		(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
			{
//			console.log("Login state: " + this.authService.isLoggedIn)
//			console.log("Auth Uid: " + this.authService.userAuthUid)
			if(this.authService.isLoggedIn !== true) {
		  		this.router.navigate(['login'])
				}
				return true;
			}



}
