import { Injectable }	from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService }	from "../_services/auth.service";
import { Observable }	from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate
	{

	constructor(
		public authService: AuthService,
		public router: Router
		)
		{
		console.log("constructor")
		}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
			{
			console.log("Check for logged in")
			console.log( RouterStateSnapshot )
			console.log( this.router )
			if(this.authService.isLoggedIn) {
				window.alert("You are not allowed to access this URL!");
				this.router.navigate(['home'])
				}
			console.log("Inner page guard test allows code to continue")
	  		return true;
			}
	}