import { Component, OnInit }			from '@angular/core';
import { DataService }					from '../../_services';
import { Router } 						from '@angular/router';
import { Customer }						from '../../_models';
import { UiModule } 						from '../ui/ui.module';

@Component({
	selector: 	'app-csr',
	templateUrl: './csr.component.html',
	styleUrls: 	['./csr.component.scss'],
})

export class CsrComponent implements OnInit {

	strUserName = '';
	searchCust = false;
	searchMeter = false;
	flgLoading = false;

	valueName = '';
	valueCustID = '';
	valueStNbr = '';
	valueStName = '';
	valuePhone = '';

	settingsCust = {
		pager:	{
			perPage:	14,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			customer_name: {title: 'Name', width: '400px'},
			Addr2: 			{title: 'Address'},
			City:          {title: 'City' },
			State: 	      {title: 'St', width: '10px'},
// 			OM:	 	      {title: 'M', width: '5px'},
			}
		};

	settingsMeter = {
		pager:	{
			perPage:	14,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			customer_name: {title: 'Name', width: '400px'},
			meter_id: 		{title: 'Meter ID', width: '190px'},
			read_date:		{title: 'Last Read Date', width: '215px' },
			last_reading:	{title: 'Reading',
						type: 'html', valuePrepareFunction:
						function(value) {return '<div class="rightAlign"> ' + value + ' </div>'; }
								},
			}
		};

	private customer:  Array<object> = [];
	private meter:  Array<object> = [];

	ListCustomer =	false;

	constructor(
		private dataSrv: DataService,
		private router: Router
		)
		{
		}

	ngOnInit()
		{
		}

	onName(event: any) {
		this.valueName = event.target.value ;
		if ( event.key == 'Enter' ) { this.Cust_search(); }
		}

	onCustID(event: any) {
		if ( event.key == 'Enter' ) {
			if 	((( typeof event.target.value		!== 'undefined' ) && ( event.target.value != ''))) 
				{
				this.searchCust = true;
				this.searchMeter = false;
				this.ListCustomer = true;
				this.flgLoading = true;
				this.dataSrv.getCustomerByCustID( event.target.value ).subscribe(
	   			(dataAry:  Array<object>) => {
	   				this.customer = dataAry;
	   				this.flgLoading = false;
	   				});
				}}

		}

	onStNbr(event: any) {
		this.valueStNbr = event.target.value ;
		if ( event.key == 'Enter' ) { this.Cust_search(); }
		}

	onStName(event: any) {
		this.valueStName = event.target.value ;
		if ( event.key == 'Enter' ) { this.Cust_search(); }
		}

	onPhone(event: any) {
		if ( event.key == 'Enter' )
			{

			this.searchCust = true;
			this.searchMeter = false;
			this.ListCustomer = true;

			this.flgLoading = true;
			this.dataSrv.getCustomerByPhone( event.target.value ).subscribe(
	   		(dataAry:  Array<object>) => {
	   			this.customer = dataAry;
	   			this.flgLoading = false;
	   			});
			}
		}

	onMeter(event: any) {
		if ( event.key == 'Enter' )
			{

			this.searchCust = false;
			this.searchMeter = false;

			this.searchMeter = true;
			this.meter[0] =	{'customer_name':  '', 'meter_id':  '','read_date':  '','last_reading':  ''}
			this.meter[1] =	{'customer_name':  '', 'meter_id':  '','read_date':  '','last_reading':  ''}

		   this.flgLoading = false;
			this.searchCust = false;
			this.searchMeter = true;

			if 	((( typeof event.target.value		!== 'undefined' ) && ( event.target.value != '')))
				{

				this.flgLoading = true;
				this.dataSrv.searchCustomerByMeterID( event.target.value ).subscribe(
		   		(data:  Array<object>) =>
		   			{
		   			this.meter = data;
		   			});
					}
		   		this.flgLoading = false;
					this.searchCust = false;
					this.searchMeter = true;
			}}

	Cust_search()
		{
		if 	((( typeof this.valueName		!== 'undefined' ) && ( this.valueName != ''))				) {
			this.searchCust = false;
			this.searchMeter = false;

			this.ListCustomer = true;

			this.flgLoading = true;
			this.dataSrv.getCustomerByName( this.valueName ).subscribe(
	   		(data:  Array<object>) => {
	   			this.customer = data;
	   			this.flgLoading = false;
			this.searchCust = true;
			this.searchMeter = false;
	   			});
			} else if 	((( typeof this.valueStName		!== 'undefined' ) && ( this.valueStName != ''))				) {
			this.searchCust = true;
			this.searchMeter = false;

			this.ListCustomer = true;


			this.flgLoading = true;
			this.dataSrv.getCustomerByStNumStName ( this.valueStNbr, this.valueStName  ).subscribe(
	   		(data:  Array<object>) =>
	   			{
	   			this.customer = data;
	   			this.flgLoading = false;
					this.searchCust = true;
					this.searchMeter = false;
	   			});
			}
		}

	onCustRowSelect(event: any) {
		this.router.navigate(['/cust/', event.data.gid]);
		}

	onMeterRowSelect(event: any) {
		this.searchMeter = false;
		this.router.navigate(['/meter/', event.data.meter_gid]);
		}


	}

