import { Component, OnInit, Input } from '@angular/core';
import { DataService }					from '../_services';
import { Location }							from '../_models';

@Component({
	selector: 'part-locn-meter-history',
	template: 
		`
		<style>
		:host /deep/ ng2-smart-table { 
    		font-size: 14px; 
			}
		</style>

<div >
	<div class=title_ln >Location History For Meter</div>
	<div class=hist_table>
		<ng2-smart-table
			[settings]		=	"historySettings"
			[source]			=	"historyData"
			(userRowSelect)=	"onUserRowSelect($event)"
			>
			</ng2-smart-table>
		</div>
	</div>`
})

export class LocnMeterHistoryPart implements OnInit {

	_location:Location;
	historyData:		Location[];
	historySettings =
		{
		hideSubHeader:	true,
		pager:	{ perPage:	3, },
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			meter_id:	{title: 'Meter ID',	width: '200px'},
			on_line:		{title: 'On/Line',   width: '80px'},
			date_in:		{title: 'Date In', 	width: '200px'},
			date_out:	{title: 'Date Out', 	width: '200px'},
			}
		};

	@Input()

		set location ( location:Location) {
			this._location = location;
			this.dataSrv.getMetersForLocn( this._location.gid ).subscribe(
				(data ) => {
				this.historyData = data    ;
				});
			}



	constructor(private dataSrv: DataService) {		}

	ngOnInit() {		}

	onUserRowSelect($event) {
		console.log('On Detail Select: ' + $event);
		}


}
