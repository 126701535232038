import { Component, OnInit, Input }	from '@angular/core';
import { AuthService } 					from '../_services';

@Component({
  selector: 	'part-home-buttons',
  template:		`
<style>
	div.btn_box			{position:absolute; top:  60px; left:   120px;text-align: left; width:750px; height:50px; }
	.cl-part-box		{position:absolute; top: 130px; left:   10px; width: 990px; height:570px;}
	div.data_box		{position:absolute; top:  50px; left:   10px; width: 990px; height:570px;}

	div.cb_gra			{position:absolute; top:   4px; left:  10px; font-size:20px;border:1px solid green;width:450px; height:38px}
	button.cb_yearB	{position:absolute; top:   0px; left:  150px; font-size:20px; background-color: green;}
	button.cb_yearN	{position:absolute; top:   0px; left:  150px; font-size:20px;}
	button.cb_monthB	{position:absolute; top:   0px; left:  204px; font-size:20px; background-color: green;}
	button.cb_monthN	{position:absolute; top:   0px; left:  204px; font-size:20px;}
	button.cb_weekB	{position:absolute; top:   0px; left:  276px; font-size:20px; background-color: green;}
	button.cb_weekN	{position:absolute; top:   0px; left:  276px; font-size:20px;}
	button.cb_dayB		{position:absolute; top:   0px; left:  341px; font-size:20px; background-color: green;}
	button.cb_dayN		{position:absolute; top:   0px; left:  341px; font-size:20px;}
	button.cb_aveB		{position:absolute; top:   0px; left:  391px; font-size:20px; background-color: green;}
	button.cb_aveN		{position:absolute; top:   0px; left:  391px; font-size:20px;}
	div.cb_map			{position:absolute; top:   4px; left:  480px; font-size:20px;border:1px solid green;width:250px; height:38px}
	button.cb_mapB 	{position:absolute; top:   0px; left:   95px; font-size:20px; background-color: green;}
	button.cb_mapN 	{position:absolute; top:   0px; left:   95px; font-size:20px;}
	button.cb_statB 	{position:absolute; top:   0px; left:  150px; font-size:20px; background-color: green;}
	button.cb_statN 	{position:absolute; top:   0px; left:  150px; font-size:20px;}
	</style>

<div class=btn_box>
	<div class=cb_gra>Useage History
		<button class=cb_yearB	*ngIf="GraphPeriod=='Y'" type="submit" (click)="graphResolution('Y')">Year</button>
		<button class=cb_yearN	*ngIf="GraphPeriod!='Y'" type="submit" (click)="graphResolution('Y')">Year</button>
		<button class=cb_monthB *ngIf="GraphPeriod=='M'" type="submit" (click)="graphResolution('M')">Month</button>
		<button class=cb_monthN *ngIf="GraphPeriod!='M'" type="submit" (click)="graphResolution('M')">Month</button>
		<button class=cb_weekB	*ngIf="GraphPeriod=='W'" type="submit" (click)="graphResolution('W')">Week</button>
		<button class=cb_weekN	*ngIf="GraphPeriod!='W'" type="submit" (click)="graphResolution('W')">Week</button>
		<button class=cb_dayB	*ngIf="GraphPeriod=='H'" type="submit" (click)="graphResolution('H')">Day</button>
		<button class=cb_dayN	*ngIf="GraphPeriod!='H'" type="submit" (click)="graphResolution('H')">Day</button>
		<button class=cb_aveB	*ngIf="GraphPeriod=='A'" type="submit" (click)="showAveUseBtn()">Avg</button>
		<button class=cb_aveN	*ngIf="GraphPeriod!='A'" type="submit" (click)="showAveUseBtn()">Avg</button>

		</div>
	<div class=cb_map>Show
		<button class=cb_mapB	*ngIf="showMap"  type="submit" (click)="showMapBtn()">Map</button>
		<button class=cb_mapN	*ngIf="!showMap" type="submit" (click)="showMapBtn()">Map</button>
		<button class=cb_statB	*ngIf="showStat"  type="submit" (click)="showStatBtn()">Status</button>
		<button class=cb_statN	*ngIf="!showStat" type="submit" (click)="showStatBtn()">Status</button>
		</div>
	</div>

	<part-home-status	class=cl-part-box *ngIf="showStat" >	</part-home-status>
	<part-home-graph	class=cl-part-box *ngIf="showGraph" [GraphPeriod]='GraphPeriod' >	</part-home-graph>
	<part-home-map		class=cl-part-box *ngIf="showMap" >	</part-home-map>
	<part-home-aveUse	class=cl-part-box *ngIf="showAveUse" >	</part-home-aveUse>


`
})


export class HomeButtonsPart implements OnInit
	{
	showGraph = false;
	GraphPeriod = '';
	showMap = false;
	showStat = true;
	showAveUse = false;

	constructor( public authService: AuthService )
		{
		}

	ngOnInit()
		{
		}

	graphResolution = function (Parm)
		{
		console.log("Use: " + Parm );
		this.GraphPeriod = Parm;
		this.showGraph = true;
		this.showAveUse = false;
		this.showMap = false;
		this.showStat = false;
    	};

	showAveUseBtn = function ()
		{
		console.log("Show Averages");
		this.GraphPeriod = 'A';
		this.showGraph = false;
		this.showAveUse = true;
		this.showMap = false;
		this.showStat = false;
    	};

	showMapBtn()
		{
		console.log("showMapBtn");
		this.GraphPeriod = '';
		this.showGraph = false;
		this.showAveUse = false;
		this.showMap = true;
		this.showStat = false;
		}

	showStatBtn()
		{
		console.log("showMapBtn");
		this.GraphPeriod = '';
		this.showGraph = false;
		this.showAveUse = false;
		this.showMap = false;
		this.showStat = true;
		}

	}

