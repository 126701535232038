import { Component, OnInit, Input  }	from '@angular/core';
import { Observable }			from 'rxjs';
import { DataService }			from '../_services';
import { Meter }					from '../_models';
//import { ViewChild} 				from '@angular/core';

@Component({
  selector: 	'part-meter-24',
  template: 
`  
		<style>
		:host /deep/ ng2-smart-table { 
    		font-size: 24px; 
			}
		</style>
		
		<div *ngIf="selectList">  
			<div *ngIf="showList">		
				<ng2-smart-table   
					[settings]		=	"detailSettings"
					[source]			=	"detailData"
					(userRowSelect)=	"onDetailSelect($event)"
					>
					</ng2-smart-table>          
				</div>    
			<div *ngIf="!showList"> 
				No 24 samples available
				</div>
			</div>

		<div *ngIf="!selectList">  
			<google-chart		
				[type]			="graphType"
				[title]			="graphTitle"
				[data]			="graphData"
				[columnNames]	="graphColumnNames"
				[width] 			="graphWidth"
				[height]			="graphHeight"
				></google-chart>    
			</div>
`,
})

export class MeterGraph24Part implements OnInit {
	graphType = 'ColumnChart';
	graphTitle = '';
   graphColumnNames = ['Date', 'Gallons'];
   graphOptions = {       };
   graphWidth = 990;
   graphHeight = 500;
   graphData = [];
	_meter:		Meter;     

	detailData:		any;
	detailSettings =
		{
		hideSubHeader:	true,
		pager:	{ perPage:	10, },
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			start_DTG:			{title: 'Start at ',		 width: '220px'},  
			last_DTG:			{title: 'End at ',		 width: '220px'},
			},
		};

	
	selectList	= false;
	showList		= false;

	ChartUse = {
		chartType:  'ColumnChart',
		dataTable: [],
		options: {},
		};

	@Input()	
		set meter(meter: Meter)
			{
			this._meter = meter;
			this.selectList	= true;
			this.dataSrv.getMeter24List( this._meter.gid ).subscribe(
				(Records) => {   
					this.showList = (typeof Records[0] === 'undefined') ? false : true  ;  
					this.detailData = Records;
					});
			}

	onDetailSelect($event) 
		{
		console.log(this._meter.gid + " " + $event.data.start_mr_gid + " " +  $event.data.last_mr_gid  );
		this.selectList = false;
		this.graphTitle = "Data for " + $event.data.start_DTG + " to " + $event.data.last_DTG ;
		this.dataSrv.getMeterReports(this._meter.gid, $event.data.start_mr_gid, $event.data.last_mr_gid  ).subscribe(
			(Records) => {    
	        	const DT = [];
   	      for (let i = 0; i < Records.length; i++) 
            	{
      	   	const Record = Records[i];
	         	const dd = new Date( Record['D'] );
   	      	const newV = [ dd, Record['U']/1.0 ];
            	DT.push( newV );
					}

				this.graphData = DT; 
				console.log( DT );
			});


		}
		

	constructor(private dataSrv: DataService)
		{
		}

	ngOnInit()
		{
		}


}
