import { Component, OnInit, Input  }	from '@angular/core';
import { Observable }			from 'rxjs';
import { DataService }			from '../_services';

@Component({
  selector: 	'part-home-graph',
  template: 
	`		<google-chart 
			[type]			="graphType"
			[title]			="graphTitle"
			[data]			="graphData"
			[columnNames]	="graphColumnNames"
			[width] 			="graphWidth"
			[height]			="graphHeight"
			></google-chart>
			`,
})

export class HomeGraphPart implements OnInit {
	graphType = 'ColumnChart';
	graphTitle = '';
   graphColumnNames = ['Date', 'Gallons'];
   graphOptions = {       };
   graphWidth = 990;
   graphHeight = 500;
   graphData = [];

	strMon: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	@Input() 
		set GraphPeriod(GraphPeriod: string)
			{	
			console.log( "Period: " + GraphPeriod )	

		let NbrBins: number;
		let Gtitle: string;
		let baset: number;
		let baseD = new Date();
		const D = new Date();
		const LblS = [];
		const startDates = [];
		const startTocks = [];
		switch   ( GraphPeriod )  {
			case 'Y':
				NbrBins = 13;
				Gtitle = 'Useage by month for last 13 Months';

				let m = D.getMonth();
				let y = D.getFullYear() - 1;
				if ( m < 0 ) { m = 12; y--; }
				baseD = new Date( y, m, 1, 0, 0, 0);
				for ( let i = 0; i < 13 ; i++) {
					LblS[i] = this.strMon[ m ];
					const ND = new Date(y, m, 1, 0, 0, 0);
					startDates[i] = ND;
					startTocks[i] = Math.round(ND.getTime() / 1000 );
					m++;
					if ( m > 11 ) { m = 0; y++; }
					}

				break;
			case 'M':
				{
				NbrBins = 31;
				Gtitle = 'Useage by day for last 31 days';
				baseD.setDate(baseD.getDate() - 31 );

				let incrD = new Date(baseD.getTime());
				incrD.setMilliseconds( 0 );
				incrD.setSeconds(0);
				incrD.setMinutes(0);
				incrD.setHours(0);
// 				incrD.setDate( 1 );
				for ( let i = 0; i < NbrBins ; i++) {
					const xstr = incrD.toString();
					LblS[i] = incrD.toString().slice(4, 10);
					startDates[i] = incrD;
					startTocks[i] = Math.round(incrD.getTime() / 1000 );
					incrD = new Date(incrD.setDate(incrD.getDate() + 1));
					}
				break;
				}
			case 'W':
				{
				NbrBins = 8;
				Gtitle = 'Useage by day for last 8 days';
				baseD.setDate(baseD.getDate() - 7 );
				let incrD = new Date(baseD.getTime());
				incrD.setMilliseconds( 0 );
				incrD.setSeconds(0);
				incrD.setMinutes(0);
				incrD.setHours(0);
				for ( let i = 0; i < NbrBins ; i++) {
					const xstr = incrD.toString();
					const tock = Math.round(incrD.getTime() / 1000 );
					LblS[i] = incrD.toString().slice(4, 10);
					startDates[i] = incrD;
					startTocks[i] = tock;
					incrD = new Date(incrD.setDate(incrD.getDate() + 1));
					}

				break;
				}
			case 'H':
				NbrBins = 48;
				Gtitle = 'Useage by hour for last day';
				baseD.setDate(baseD.getDate() - 1 );
				baseD.setMilliseconds( 0 );
				baseD.setSeconds(0);
				baseD.setMinutes(0);
				baseD.setHours(0);
				const DD = new Date(baseD.getTime());
				const flgTickTock = true;
				for ( let i = 0; i < NbrBins ; i++) {
					if ( flgTickTock ) {
						LblS[i] = DD.getDate() + ':' + DD.getHours();
						LblS[i] = DD.getHours() + ':00' ;
						} else {
						LblS[i] = '';
						}
// 					flgTickTock = ! flgTickTock;
					startDates[i] = new Date(DD.getTime());
					startTocks[i] = Math.round(DD.getTime() / 1000 );
					DD.setHours( DD.getHours() + 1);
					}
				break;
			}

		this.dataSrv.getUtilityUseage( GraphPeriod  ).subscribe(
			(Records) => {

           	const DT = [];
            for (let i = 0; i < Records.length; i++) 
            	{
            	const Record = Records[i];
            	var meaD = '';  
            	switch ( GraphPeriod )
            		{
            		case 'H':
            			meaD = Record['D'] + ' ' + Record['H'] + ":00";
            			break;
            		case 'W':
           		 		meaD = Record['D'] ;
            			break;
            		case 'M':
        		   		meaD = Record['D'] ;
            			break;
            		case 'Y':
            			meaD = Record['D'];
            			break;
            		}
            	const dd = new Date(meaD);
            	const newV = [ dd, Record['U']/1.0 ];
            	DT.push( newV );
					}

				this.graphData = DT;
            this.graphTitle = Gtitle;

			});
		}


	constructor(private dataSrv: DataService)
		{
		}

	ngOnInit()
		{
		}

	onGraphSelect(  )
		{
		console.log( 'clicked on graph');
		}


}
