import { Component, OnInit } 								from '@angular/core';
import { DataService }										from '../../_services';
import { UiModule } 											from '../ui/ui.module';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})

export class InstallComponent implements OnInit {


	editInstall 	= false;
	findLocation	= true;

	showQuestion	= false;
	meter_id: string;
	searchBlock = '';

	valueStNbr:		'';
	valueStName:	'';
	IStNbr: string;
	IStName:	string;
	ICity: string;
	locn_gid:	number;
	searchCust = true;
	searchMeter = false;

	dispScrn	= false;
	dispScrn2	= false;

	screen1List = 
		{
		hideSubHeader:	true,
		pager:	{
			perPage:	12,
			},
		actions: {
			custom: [
			  {
			    name: 'Detail',
			    title: 'Detail'
			  },
			  {
			    name: 'Order',
			    title: 'Order'
			  },
			],
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			StNum: 			{title: 'Block', width: '40px'},
			st_name: 		{title: 'St Name', width: '450px'},
			city: 	      {title: 'City', width: '300px'},
			nbrLoc: 	      {title: 'Loc', width: '10px'},
			nbrMtr: 	      {title: 'Mtr', width: '10px'},
			nbrOnO: 	      {title: 'O/O', width: '10px'},
			}
		};
		
	screen2List = 
		{
		hideSubHeader:	true,
		pager:	{
			perPage:	12,
			},
		actions: {
			add:		false,
			delete:	false,
			edit:		false,
			},
		columns: {
			st_num: 			{title: 'Num', width: '40px'},
			st_name: 		{title: 'St Name', width: '450px'},
			unit: 	      {title: 'Unit', width: '200px'},
			meter_id: 	   {title: 'Meters', width: '200px'},
			}
		};
		
	private blockStreet:  Array<object> = [];
	private locations:  Array<object> = [];


	constructor(private dataSrv: DataService) {
  		console.log('Construct install');
  		}

	ngOnInit() {

		this.editInstall 	= false;
		this.findLocation = true;
		this.dispScrn = false;
		this.dispScrn2 = false;
		this.showQuestion	= true;

		}

	onScreen1Custom(event) {
		console.log('Screen1 custom Clicked');
		console.log( event );
		switch ( event.action )
			{
			case "Detail":
				{
				this.dispScrn = false;
				this.dispScrn2 = true;
				this.IStNbr = event.data.StNum;
				this.searchBlock = event.data.StNum;
				this.IStName = event.data.st_name;
				this.ICity = event.data.city;
		
				console.log('Select: '  + event.data + ' isSelected: ' + event.isSelected );
				console.log( event.data );
				this.dataSrv.getLocationsByBlockStreet ( event.data.StNum, event.data.st_name, event.data.city  ).subscribe(
			   	(data:  Array<object>) => {
			   		console.log( data );
			   		this.locations = data;
			   		});
	   		
				break;
				}
			case "Order":
				{
				console.log( event.data );
				this.dataSrv.orderMetersByBlockStreet ( event.data.StNum, event.data.st_name, event.data.city  ).subscribe(
			   	(data:  Array<object>) => {
			   		console.log( data );
			   		this.locations = data;
			   		});				
				break;
				}
			default:
				{
				alert("Bad selection: " + event.action );
				break;			
				}
			}
//		this.editInstall 	= true;
//		this.findLocation = false;
		}

	clickFindLocation() {
		console.log('findLocation Clicked');
		this.editInstall 	= false;
		this.findLocation = true;
		this.showQuestion	= true;
		}

	onStNbr(event: any) {
		this.valueStNbr = event.target.value ;
		this.searchBlock = event.target.value;
		if ( event.key == 'Enter' ) { this.blockSearch(); }
		}

	onStName(event: any) {
		this.valueStName = event.target.value ;
		if ( event.key == 'Enter' ) { this.blockSearch(); }
		}

	onMeterIDKey(event: any ) {
		this.meter_id = event.target.value ;
// 		if ( event.key == "Enter" ) { this.blockSearch() }
		}

	blockSearch() {
		console.log('block search on: ' + this.valueStNbr + '  ' + this.valueStName);
		this.showQuestion	= false;

		this.searchCust = true;
		this.searchMeter = false;

		this.dispScrn = true;
		this.dataSrv.getLocnByStNumStName ( this.valueStNbr, this.valueStName  ).subscribe(
	   	(data:  Array<object>) => {
	   		console.log( data );
	   		this.blockStreet = data;
	   		});

		}

	onRowSelect1(event) {
		this.dispScrn = false;
		this.dispScrn2 = true;
		this.IStNbr = event.data.StNum;
		this.searchBlock = event.data.StNum;
		this.IStName = event.data.st_name;
		this.ICity = event.data.city;

		console.log('Select: '  + event.data + ' isSelected: ' + event.isSelected );
		console.log( event.data );
		this.dataSrv.getLocationsByBlockStreet ( event.data.StNum, event.data.st_name, event.data.city  ).subscribe(
	   	(data:  Array<object>) => {
	   		console.log( data );
	   		this.locations = data;
	   		});
		}

	onRowSelect2(event) {
		this.dispScrn = false;
		this.dispScrn2 = true;
		this.editInstall = true;
		this.locn_gid = event.data.gid;
		this.meter_id = event.data.meter_id;
		this.IStNbr = event.data.st_num;
		this.IStName = event.data.st_name;
		this.ICity = event.data.city;
		console.log( event.data );
		this.editInstall = true;
		}

	onExit(flag: any) {
	  	if ( flag  == 0 ) 
	  		{
			this.editInstall			= false;
			} 
		else if ( flag == 1 ) 
			{
			this.meter_id = this.meter_id.toUpperCase();
			if ( this.meter_id.match(/^[a-z0-9]+$/i) !== null )			
				{
				this.dataSrv.installMeter( this.meter_id, this.locn_gid )
					.subscribe(
						(postAry:  Array<object>) => {
							console.log('Returned from meter install with: ' + postAry['Result']);
							console.log( postAry );
							this.editInstall 	= false;
							this.dataSrv.getLocationsByBlockStreet ( this.searchBlock, this.IStName, this.ICity  ).subscribe(
						   	(data:  Array<object>) => {
						   		console.log( data );
						   		this.locations = data;
						   		});
							});
				}  
			else { alert("Invalid Meter ID"); }
			}
 	 	}
 	 	    
onCustomAction(event) 
	{
	console.log(event.action);
	console.log(event.data);	
//      switch ( event.action) {
//        case 'viewrecord':
//          this.viewRecord(event.data);
//          break;
//       case 'editrecord':
//          this.editRecord(event.data);
//      }
    }

}
